<div class="wrap-more-artwork">
    <button class="btn btn-goback" (click)="mainService.sideContent = 'about-exhibition'">
        <i class="vi vi-arrow-left"></i>
        Back
    </button>

    <div class="wrap-user">
        <img 
            [src]="mainService.userInfo.avatar" 
            alt="" 
            class="img-avatar">
        <a 
            target="_BLANK"
            [href]="mainService.villumeAdminUrl + '/' + mainService.userInfo.username">
            {{mainService.userInfo.first_name+" "+ mainService.userInfo.last_name}}
        </a>  
    </div>

    <div class="content-more-artwork Current" *ngIf="mainService.otherExhibitions.length">
        <div class="item-exhibition" *ngFor="let item of mainService.otherExhibitions">
            <img 
                [src]="item.cover_image ? item.cover_image : item.thumbnail" 
                alt=""  
                loading="lazy"
                class="thumbnail">
            <div class="wrap-exhibition-name">
                <h5 class="title">{{item.name}}</h5>
                <div class="wrap-date" *ngIf="item.started && item.ended">
                    <span>{{moment(item.started).format("DD.MM.YYYY")}} </span>
                    <i class="vi vi-arrow-right"></i>
                    <span>{{moment(item.ended).format("DD.MM.YYYY")}}</span>
                </div>
            </div>

            <a [href]="'/'+item.share_string" target="_blank" class="link-exhibition"></a>
        </div>
    </div>

</div>