<div class="wrap-help">
    <ng-container *ngIf="!isMobile">
        <div class="d-grid">
            <div class="col">
                 <img src="{{env.staticAssets}}images/navigation/wsad.svg??t={{mainService.appVersion}}" alt="" />
            </div>
            <div class="col">
                 <img src="{{env.staticAssets}}images/navigation/arrow.svg??t={{mainService.appVersion}}" alt="" />
            </div>
            <div class="col">
                 <img src="{{env.staticAssets}}images/navigation/click-floor.svg??t={{mainService.appVersion}}" alt="" />
            </div>
            <div class="col">
                 <img src="{{env.staticAssets}}images/navigation/click-drag.svg??t={{mainService.appVersion}}" alt="" />
            </div>
        </div>

        <div class="wrap-desc">
            <img src="{{env.staticAssets}}images/navigation/click-artwork.svg??t={{mainService.appVersion}}" alt="">
            <div>
                <p class="desc">Click on the artworks to get there directly and unfold detailed information about it</p>
                <p class="desc">Hover on any artwork for 2 seconds and more to get short information about it</p>
            </div>
        </div>

        <div class="wrap-guide">
            <div class="wrap-left">
                <p class="desc desc-center">start a guided tour</p>
                <div class="desc">
                    prev. artwork
                    <img src="{{env.staticAssets}}images/navigation/play-panel.svg??t={{mainService.appVersion}}" alt="">
                    next artwork
                </div>
            </div>
            <div class="wrap-right">
                <p class="desc">Click on the arrow to unfold detailed information about the exhibition.</p>
                <img src="{{env.staticAssets}}images/navigation/arrow-left.svg??t={{mainService.appVersion}}" alt="">
            </div>
        </div>

        <div class="suggest">
            We recommend you to use 
            <img src="{{env.staticAssets}}images/other/chrome.svg??t={{mainService.appVersion}}" alt="">
            Google Chrome Browser for best perfomance
        </div>
    </ng-container>
    
    <ng-container *ngIf="isMobile">
        <!-- <ul>
            <li>Tap and drag left & right or up & down to move and turn in different directions;</li>
            <li>Tap on the floor to move; </li>
            <li>Tap on the artworks to get there directly and unfold detailed information about it.</li>
        </ul> -->
        
        <div class="wrap-navigation">
            <div class="item-navigation">
                <img class="mb10" src="{{env.staticAssets}}images/navigation/help-sm1.svg" alt="Tap on the floor">
                <p>Tap on the floor; <br>Hold and drag to move and turn in different directions.</p>
            </div>
            <div class="item-navigation">
                <img class="mb10" src="{{env.staticAssets}}images/navigation/help-sm2.svg" alt="Tap on the artwork">
                <p>Tap on the artworks to get there directly and unfold detailed information about it.</p>
            </div>
            <div class="item-navigation play-panel">
                <div class="play-panel-top">
                    <span>start a guided tour</span>
                </div>
                <img src="{{env.staticAssets}}images/navigation/play-panel-sm.svg??t={{mainService.appVersion}}" alt="" class="img-play">
                <div class="play-panel-bottom">
                    <span class="left">prev. artwork</span>
                    <span class="right">next artwork</span>
                </div>
            </div>
            <div class="item-navigation">
                <img src="{{env.staticAssets}}/images/navigation/arrow-left-sm.svg?t={{mainService.appVersion}}" alt="" class="img-icon" />
                <p>Tap to unfold detailed information about the exhibition</p>
            </div>
            <div class="item-navigation">
                <img src="{{env.staticAssets}}images/navigation/fullscreen.svg?t={{mainService.appVersion}}" alt="" class="img-icon" />
                <p class="mb0">
                    Full screen mode
                    <span>(Android only)</span>
                </p>
            </div>
            <div class="item-navigation">
                <img src="{{env.staticAssets}}images/navigation/undo.svg?t={{mainService.appVersion}}" alt="" class="img-icon" />
                <p>Return to the previous action or position</p>
            </div>
        </div>
    </ng-container>
</div>