import { Component } from '@angular/core';
import { MainService } from '@services/main.service';
import moment from 'moment';
import { NgIf, NgFor } from '@angular/common';


@Component({
    selector: 'app-more-from-author',
    templateUrl: './more-from-author.component.html',
    styleUrls: ['./more-from-author.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor],
})
export class MoreFromAuthorComponent {
  public moment = moment;

  constructor(public mainService: MainService) {}
}
