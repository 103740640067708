<div
    *ngIf="showNotSubs"
    class="dialog-mask"
    style="z-index: 1015;">
    <div class="notSubsDialog p-dialog">
        <div class="p-dialog-header">
            <span>Subscribe to get full experience</span>
            <button class="btn-close" (click)="closeDialog()"><i class="vi vi-x"></i></button>
        </div>
        <div class="p-dialog-content">
            <p>
                Please note that the Experimental pricing plan allows you to view but not share your gallery.
                To unlock the full potential of Villume, consider subscribing to one of our other pricing plans.
                Activate your subscription to gain access to exclusive features and use the website without restrictions!
            </p>
        </div>
        <div class="p-dialog-footer">
            <button class="button button-cancel" (click)="closeDialog()">Cancel</button>
            <button class="button button-subs" (click)="subscribe()">Subscribe</button>
        </div>
    </div>
</div>