import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TtsService {
  public ttsExisted: boolean = false;
  public ttsAudio: any = null;
  public ttsSpeaking: boolean = false;

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * ANCHOR Text to Speech
   */
  private _checkTextToSpeech(tts_path: string) : Observable<any> {
    const stream = tts_path.split("/").pop() || "";
    return this._http.get(`${environment.tts_host}/speech/check/${stream}`);
  }

  public initTextToSpeech(tts_path:string) : void {
    let ttsPath = tts_path || null;

    if (ttsPath) {
      this._checkTextToSpeech(ttsPath).subscribe({
        next: (response: any) => {
          const exist = response.data.exist;
          if (exist) {
            this.ttsExisted = true;
            this.ttsAudio = new Audio(environment.tts_host + tts_path);
            this.ttsAudio.load();
            this.ttsAudio.addEventListener('ended', this.onTTSEnded.bind(this));
            this.ttsAudio.addEventListener('pause', this.onTTSPaused.bind(this));
          } else {
            //TODO: text to speech not exist
            console.log('Text to speech not exist');
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this.ttsExisted = false;
    }
  }

  public onStopTTSAudio(): void {
    if (this.ttsAudio) {
      this.ttsAudio.removeEventListener('ended', this.onTTSEnded.bind(this));
      this.ttsAudio.removeEventListener('pause', this.onTTSPaused.bind(this));
      this.ttsAudio = null;
    }
  }

  public onTTSEnded(): void {
    this.ttsSpeaking = false;
  }

  public onTTSPaused(): void {
    this.ttsSpeaking = false;
    if (this.ttsAudio) this.ttsAudio.currentTime = 0;
  }

  public toggleTextToSpeech(): void {
    if (!this.ttsSpeaking) this.ttsAudio.play();
    else this.ttsAudio.pause();
    this.ttsSpeaking = !this.ttsSpeaking;
  }

  public stopTextToSpeech(): void {
    if (this.ttsAudio){
      this.ttsAudio.pause();
      this.ttsAudio.currentTime = 0;
    }
    this.ttsSpeaking = false;
    this.onStopTTSAudio();
  }
}
