import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class DataApiInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headerRequest = request.clone({
      setHeaders: {
        'accept': 'application/json',
      },
    });

    if (request.reportProgress) return next.handle(request);
    return next.handle(headerRequest).pipe(
        switchMap(async (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const modifiedBody = { ...event.body };

            if (modifiedBody.data?.cry) {
              const decryptedData = await this._decryptResponse(modifiedBody.data);
              modifiedBody.data = decryptedData;
            }

            const modifiedEvent = event.clone({ body: modifiedBody });
            return modifiedEvent;
          }

          return event;
        }),
    );
  }


  /**
   * ANCHOR GET PASSPHRASE FOR DECRYPT
   * Todo: to get passphrase for decrypt viewer json
   * @param data: response data encrypted
  */
  private _getPassphrase(data:any) {
    return new Promise((resolve, reject) => {
      const pattern = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
      const lastLogin = data.ls;
      const matches = lastLogin.match(pattern);
      const yearnmonth = matches[1] + matches[2];
      const day = matches[3];
      const hours = matches[4];
      const minutes = matches[5];
      const seconds = matches[6];
      const formula = (hours * day ^ 3 / 3) + (minutes * day ^ 2 / 2) + (seconds * day) + parseInt(yearnmonth);
      const encrypt = data.e4;
      const passphrase = encrypt.slice(0, 5) + formula + encrypt.slice(5);
      resolve(passphrase);
    });
  };

  /**
   * ANCHOR DECRYPT RESPONSE
   * Todo: to decrypt response data
   * @param data: response data encrypted
   * @returns decrypted response data
  */
  private _decryptResponse(data:any) {
    return new Promise(async (resolve, reject) => {
      const passphrase:string  = await this._getPassphrase(data) as string;
      const objres = data.cry;

      const objJson = {
        'ciphertext': objres.c1p,
        'iv': objres.f0ur,
        'salt': objres.su1fur,
      };

      const encrypted = objJson.ciphertext;
      const salt = CryptoJS.enc.Hex.parse(objJson.salt);
      const iv = CryptoJS.enc.Hex.parse(objJson.iv);

      const key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });

      const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
      const result = decrypted.toString(CryptoJS.enc.Utf8);
      resolve(JSON.parse(result));
    });
  };
}
