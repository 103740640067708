// Angular built-in modules
import { Injectable } from '@angular/core';

// User-defined interfaces
import {
  ILoadArtworkOptions,
  ISetArtworkLightingOptions,
  ICreateFrameComponentOptions,
  ICreateArtworkComponentOptions,
  ICreateRadiusOptions,
} from '@interfaces/artwork-loader';
import { IArtwork } from '@interfaces/artwork';

// User-defined services
import { UtilsService } from './utils.service';

declare const BABYLON: any;

@Injectable({
  providedIn: 'root',
})
export class ArtworkLoaderService {
  constructor(
    private _utilsService: UtilsService,
  ) { }

  /**
   * ANCHOR Create Object Wrapper For Artwork
   * @description Create a wrapper object for the artwork
   * @param artworkData : Artwork
   * @param scene : BABYLON.Scene
   * @returns : BABYLON.TransformNode
   */
  private _createWrapper(artworkData: IArtwork, scene: any): any {
    const wrapArtwork = new BABYLON.TransformNode('artwork', scene);
    wrapArtwork.id = `artwork-${artworkData.id}`;
    wrapArtwork['artworkType'] = artworkData.file_type;
    wrapArtwork['isMove'] = false;
    wrapArtwork['shadowHasInitialized'] = false;
    wrapArtwork['donutHasInitialized'] = false;
    wrapArtwork['metadata'] = { artworkData };
    return wrapArtwork;
  }

  /**
   * ANCHOR Set Artwork Mesh Lighting
   * @description Set lighting for artwork mesh
   * @param options : ISetArtworkLightingOptions
   */
  public setLighting(options: ISetArtworkLightingOptions): void {
    const { artwork, light, mesh } = options;
    if (artwork.file_type == 'figure-object') {
      light.excludedMeshes.push(mesh);
      mesh.material.environmentIntensity = artwork.light_intensity / 2;
    } else {
      light.includedOnlyMeshes.push(mesh);
    }
  }

  /**
   * ANCHOR Set Artwork Transform
   * @description Set transform such as position, scaling and rotation for artwork
   * @param wrapper : BABYLON.TransformNode
   * @param artwork : Artwork
   */
  public setTransform(wrapper: any, artwork: IArtwork): void {
    wrapper.position = new BABYLON.Vector3(
        artwork.position.position_x,
        artwork.position.position_y,
        artwork.position.position_z,
    );
    wrapper.rotation = new BABYLON.Vector3(
        artwork.rotation.rotation_x,
        artwork.rotation.rotation_y,
        artwork.rotation.rotation_z,
    );
    if (artwork.file_type === 'figure-object') {
      wrapper.scaling = new BABYLON.Vector3(
          artwork.scaling.scaling_x,
          artwork.scaling.scaling_y,
          artwork.scaling.scaling_z,
      );
    }
  }

  // SECTION Create Artwork Object Functions
  //#region

  /**
   * ANCHOR Load Artwork Object
   * @description to load artwork object
   * @param options : ILoadArtworkOptions
   */
  public loadArtworkObject(options: ILoadArtworkOptions): Promise<any> {
    const {
      artwork, scene, light, highlightLayer,
      glowEffect, artworkNodes, loadedArtworks,
    } = options;
    return new Promise((resolve, reject) => {
      BABYLON.SceneLoader.ImportMesh('', '', artwork.model_path, scene, (meshes: any[]) => {
        const wrapper = this._createWrapper(artwork, scene);
        let rootMesh: any;
        meshes.forEach((mesh) => {
          if (mesh.name !== '__root__') {
            this.setLighting({ artwork, light, mesh });
            this._utilsService.excludedMeshHighlightLayer({ highlightLayer, mesh, isAdd: true });
            mesh.computeWorldMatrix(true);
            mesh.setParent(wrapper);
            mesh.isPickable = true;
            if (glowEffect) glowEffect.addIncludedOnlyMesh(mesh);
          } else {
            rootMesh = mesh;
          }
        });
        rootMesh?.dispose();
        this._calculateObjectDimension(wrapper, scene);
        this.setTransform(wrapper, artwork);
        artworkNodes.push(wrapper);
        loadedArtworks.push(artwork.id);
        resolve(wrapper);
      }, null, reject);
    });
  }

  /**
   * ANCHOR Calculate Artwork Object Dimension
   * @description to calculate artwork object dimension
   * @param wrapper : BABYLON.TransformNode
   * @param scene : BABYLON.Scene
   */
  private _calculateObjectDimension(wrapper: any, scene: any): any {
    const tmpWrap = new BABYLON.TransformNode('tmpWrap', scene);
    wrapper.getChildren().forEach((mesh: any) => mesh.setParent(tmpWrap));
    const { width, height, depth } = this._utilsService.getNodeDimension(tmpWrap);
    wrapper.scaling = new BABYLON.Vector3(width, height, depth);
    tmpWrap.getChildren().forEach((mesh: any) => mesh.setParent(wrapper));
    tmpWrap.dispose();
  }

  //#endregion
  //!SECTION


  // SECTION Create Artwork Image/Video Functions
  //#region

  /**
   * ANCHOR Create Artwork Image/Video
   * @description to creating artwork image or video
   * @param options : ILoadArtworkOptions
   * @returns BABYLON.TransformNode
   */
  public createArtworkImageVideo(options: ILoadArtworkOptions): any {
    const {
      artwork, scene, light, highlightLayer, forEditFrame = false,
      glowEffect, artworkNodes, loadedArtworks,
    } = options;
    const materials = this._generateArtworkMaterials(artwork, scene, forEditFrame);
    const wrapArtwork = this._createWrapper(artwork, scene);
    const createOptions: ICreateFrameComponentOptions = {
      scene,
      materials,
      artwork,
    };
    
    const passepartoutMesh = this._createPassepartoutMesh(createOptions);
    const backFrameMesh = this._createBackFrameMesh(createOptions);
    const frameMesh = this._createFrameMesh(createOptions);
    const imageVideoMesh = this._createImageVideoMesh(createOptions);

    this._calculateImageVideoDimension(artwork, wrapArtwork);
    wrapArtwork.position.z += wrapArtwork.scaling.z/2;

    const artworkMeshes = [ backFrameMesh, imageVideoMesh, passepartoutMesh, frameMesh ].filter((mesh) => mesh);
    artworkMeshes.forEach((mesh:any) => {
      mesh.position.z += artwork.frame.back_frame.back_frame_depth/2;
      mesh.setParent(wrapArtwork);
      if (highlightLayer) this._utilsService.excludedMeshHighlightLayer({ highlightLayer, mesh, isAdd: true });
      if (glowEffect) glowEffect.addIncludedOnlyMesh(mesh);
      if (!forEditFrame) this.setLighting({ artwork, light, mesh });
    });

    if (!forEditFrame) {
      artworkNodes.push(wrapArtwork);
      loadedArtworks.push(artwork.id);
    }

    return wrapArtwork;
  };


  /**
   * ANCHOR Calculate Artwork Image/Video Wrapper Dimension
   * @description to calculate artwork image/video wrapper dimension
   * @param artwork : Artwork
   * @param wrapper : BABYLON.TransformNode
   */
  private _calculateImageVideoDimension(artwork: IArtwork, wrapper: any): void {
    const isUseFrame = artwork.frame.frame.frame;
    const isUsePasse = artwork.frame.passepartout.passepartout;
    const passeWidth = artwork.frame.passepartout.passepartout_width_left + artwork.frame.passepartout.passepartout_width_right;
    const frameWidth = artwork.frame.frame.frame_width_left + artwork.frame.frame.frame_width_right;
    const artworkWidth = artwork.real_width;
    const artworkHeight = artwork.real_height;
    const frameThicknes = isUseFrame ? artwork.frame.frame.frame_depth : 0;
    const passeThicknes = isUsePasse ? artwork.frame.passepartout.passepartout_depth : 0;
    const backFrameThickness = artwork.frame.back_frame.back_frame_depth;

    if (isUseFrame) {
      wrapper.scaling.y = artworkHeight + (isUsePasse ? passeWidth : 0) + frameWidth;
      wrapper.scaling.x = artworkWidth + (isUsePasse ? passeWidth : 0) + frameWidth;
    } else if (isUsePasse) {
      wrapper.scaling.y = artworkHeight + passeWidth;
      wrapper.scaling.x = artworkWidth + passeWidth;
    } else {
      wrapper.scaling.y = artworkHeight;
      wrapper.scaling.x = artworkWidth;
    }

    wrapper.scaling.z = backFrameThickness + Math.max(passeThicknes, frameThicknes);
  }

  // SECTION Generate Materials for Artwork Image/Video Functions
  //#region

  /**
   * ANCHOR Get Artwork Materials Names
   * @description Get the names of the materials for the artwork image/video
   * @returns : string[]
   */
  private _getArtworkMaterialsNames(): string[] {
    return [
      'imageVideoMaterial',
      'passepartoutMaterial',
      'frameMaterial',
      'backFrameMaterial',
    ];
  }

  /**
   * ANCHOR Create Artwork Standard Materials
   * @description Create the standard materials for the artwork image/video
   * @param scene : BABYLON.Scene
   * @param forEditFrame : boolean -> if the function is called for edit frame
   * @returns : materials -> { [materialName: string]: BABYLON.StandardMaterial }
   */
  private _createArtworkBaseMaterials(scene: any, forEditFrame: boolean): any {
    const materialNames = this._getArtworkMaterialsNames();
    const intens = forEditFrame ? 0.05 : 0.03;
    const materials: any = {};
    materialNames.forEach((name) => {
      materials[name] = new BABYLON.StandardMaterial(name, scene);
      materials[name].specularColor = new BABYLON.Color3(intens, intens, intens);
    });

    return materials;
  }

  /**
   * ANCHOR Set Artwork Image/Video Material
   * @description Set the imageVideoMaterial
   * @param params : { artwork: Artwork, material: BABYLON.StandardMaterial, scene: BABYLON.Scene }
   */
  private _setArtworkImageVideoMaterial( params: { artwork: IArtwork, material: any, scene: any } ): void {
    const { artwork, material, scene } = params;
    let texture = new BABYLON.Texture(artwork.image, scene);
    material.diffuseTexture = texture;
  }

  /**
   * ANCHOR Create Texture Video
   * @description Create the texture video for the artwork image/video
   * @param videoStream : string -> the video stream
   * @param scene: BABYLON.Scene
   * @returns : BABYLON.VideoTexture
   */
  public createTextureVideo(videoStream: string, scene: any): any {
    const videoTexture = new BABYLON.VideoTexture(
        'video',
        videoStream,
        scene,
        false,
        false,
        BABYLON.Texture.TRILINEAR_SAMPLINGMODE,
        {
          loop: true,
          muted: true,
        },
    );

    return videoTexture;
  }

  /**
   * ANCHOR Set Artwork Passepartout Material
   * @description Set the passepartoutMaterial
   * @param params : { artwork: Artwork, material: BABYLON.StandardMaterial, scene: BABYLON.Scene }
   */
  private _setArtworkPassepartoutMaterial(params: { artwork: IArtwork, material: any, scene: any }): void {
    const { artwork, material, scene } = params;
    // Note: "passe" it's mean the passepartout
    const passeTexture = artwork.frame.passepartout.passepartout_texture_low_quality;
    const passeColor = artwork.frame.passepartout.passepartout_color;
    const isUsePasseColor = artwork.frame.passepartout.passepartout_material_color;
    const isUsePasseTexture = artwork.frame.passepartout.passepartout_material_texture;

    if (isUsePasseTexture && passeTexture) material.diffuseTexture = new BABYLON.Texture(passeTexture, scene);
    if (isUsePasseColor) material.diffuseColor = BABYLON.Color3.FromHexString(passeColor);
  }

  /**
   * ANCHOR Set Artwork Frame Material
   * @description Set the frameMaterial
   * @param params : { artwork: Artwork, material: BABYLON.StandardMaterial, scene: BABYLON.Scene }
   */
  private _setArtworkFrameMaterial(params: { artwork: IArtwork, material: any, scene: any }): void {
    const { artwork, material, scene } = params;
    const frameTexture = artwork.frame.frame.frame_texture_low_quality;
    const frameColor = artwork.frame.frame.frame_color;
    const isUseFrameColor = artwork.frame.frame.frame_material_color;
    const isUseFrameTexture = artwork.frame.frame.frame_material_texture;

    if (isUseFrameTexture && frameTexture) material.diffuseTexture = new BABYLON.Texture(frameTexture, scene);
    if (isUseFrameColor) material.diffuseColor = BABYLON.Color3.FromHexString(frameColor);
  }

  /**
   * ANCHOR Set Artwork Back Frame Material
   * @description Set the backFrameMaterial
   * @param params : { artwork: Artwork, material: BABYLON.StandardMaterial }
   */
  private _setArtworkBackFrameMaterial(params: { artwork: IArtwork, material: any }): void {
    const { artwork, material } = params;
    material.diffuseColor = BABYLON.Color3.FromHexString(
        artwork.frame.back_frame.back_frame_color,
    );
  }

  /**
   * ANCHOR Generate Artwork Materials
   * @description Generate the materials for the artwork image/video
   * @param artwork: Artwork
   * @param scene: BABYLON.Scene
   * @param forEditFrame: boolean -> if the function is called for edit frame
   */
  private _generateArtworkMaterials(artwork: IArtwork, scene: any, forEditFrame: boolean) {
    const materials = this._createArtworkBaseMaterials(scene, forEditFrame);
    this._setArtworkImageVideoMaterial({ artwork, material: materials.imageVideoMaterial, scene });
    this._setArtworkPassepartoutMaterial({ artwork, material: materials.passepartoutMaterial, scene });
    this._setArtworkFrameMaterial({ artwork, material: materials.frameMaterial, scene });
    this._setArtworkBackFrameMaterial({ artwork, material: materials.backFrameMaterial });
    return materials;
  }

  //#endregion
  //!SECTION

  // SECTION Generate Meshes(Components) for Artwork Image/Videos Functiona
  //#region

  /**
   * ANCHOR Create Artwork Component
   * @description to create artwork component such as back frame, image/video, passepartout and frame
   * @param options: ICreateArtworkComponentOptions
   * @returns : BABYLON.Mesh
   */
  private _createArtworkComponent(options: ICreateArtworkComponentOptions): any {
    const {
      useOuterRadius, outerRadius = 0, innerRadius = 0, centerHole, widthTop = 0, widthBottom = 0, widthLeft = 0, widthRight = 0,
      thickness, scene, useInnerRadius, width, height, component,
    } = options;

    let baseMesh = BABYLON.MeshBuilder.CreateBox('box', {}, scene);
    baseMesh.scaling.z = thickness;

    baseMesh.scaling.x = width;
    baseMesh.scaling.y = height;

    let innerMesh = baseMesh.clone();
    if (useOuterRadius) {
      const radiusMesh = this._createRadius({ scene, radius: outerRadius });
      radiusMesh.scaling.z = baseMesh.scaling.z;

      const radiusMeshes = this._setRadiusMeshPositions(radiusMesh, baseMesh, options);
      radiusMeshes.forEach((mesh: any) => {
        baseMesh = this._utilsService.CSGActions({
          disposeSourceMeshes: true,
          action: 'subtract',
          mesh1: baseMesh,
          mesh2: mesh,
          scene,
        });
      });
    }

    if (centerHole) {
      innerMesh.scaling.x -= widthLeft + widthRight;
      innerMesh.scaling.y -= widthTop + widthBottom;
      innerMesh.position.y -= widthTop/2;
      innerMesh.position.y += widthBottom/2;
      innerMesh.position.x -= widthLeft/2;
      innerMesh.position.x += widthRight/2;

      if (useInnerRadius) {
        if (component == 'passepartout') options.part = 'inner_pass';
        if (component == 'frame') options.part = 'inner_frame';
        const radiusMesh = this._createRadius({ scene, radius: innerRadius });
        radiusMesh.scaling.z = innerMesh.scaling.z;
        const radiusMeshes = this._setRadiusMeshPositions(radiusMesh, innerMesh, options);
        radiusMeshes.forEach((mesh: any) => {
          innerMesh = this._utilsService.CSGActions({
            disposeSourceMeshes: true,
            action: 'subtract',
            mesh1: innerMesh,
            mesh2: mesh,
            scene,
          });
        });
      }

      baseMesh = this._utilsService.CSGActions({
        action: 'subtract',
        disposeSourceMeshes: true,
        mesh1: baseMesh,
        mesh2: innerMesh,
        scene,
      });
    }
    innerMesh?.dispose();
    return baseMesh;
  }

  /**
   * ANCHOR Set Radius Mesh Positions
   * @description to set radius mesh positions
   * @param radiusMesh : BABYLON.Mesh
   * @param attachedMesh : BABYLON.Mesh
   * @returns : BABYLON.Mesh[]
   */
  private _setRadiusMeshPositions(radiusMesh: any, attachedMesh: any, options: any): any {
    const {
      widthTop,
      widthBottom,
      widthLeft,
      widthRight,
      component,
      part,
    } = options;
    const lowestDimension = Math.min(attachedMesh.scaling.x, attachedMesh.scaling.y);
    const newRadius = radiusMesh.scaling.x >= lowestDimension/2 ? lowestDimension/2 : radiusMesh.scaling.x;
    radiusMesh.scaling.x = newRadius;
    radiusMesh.scaling.y = newRadius;
    const positions = [ 'bottom-right', 'bottom-left', 'top-left', 'top-right' ];
    const horizontalPos = attachedMesh.scaling.x/2 - radiusMesh.scaling.x/2;
    const verticalPos = attachedMesh.scaling.y/2 - radiusMesh.scaling.y/2;
    const topPos = verticalPos;
    const bottomPos = -verticalPos;
    const leftPos = -horizontalPos;
    const rightPos = horizontalPos;
    const topRot = Math.PI;
    const bottomRot = 0;
    const leftRot = 0;
    const rightRot = Math.PI;

    const radiusMeshes: any[] = [];
    positions.forEach((position) => {
      const radiusMeshClone = radiusMesh.clone();
      radiusMeshClone.position.y = topPos;
      if (position.includes('bottom')) {
        radiusMeshClone.position.y = bottomPos;
        radiusMeshClone.rotation.x = bottomRot;
      } else {
        radiusMeshClone.position.y = topPos;
        radiusMeshClone.rotation.x = topRot;
      }

      if (position.includes('right')) {
        radiusMeshClone.position.x = rightPos;
        radiusMeshClone.rotation.y = rightRot;
      } else {
        radiusMeshClone.position.x = leftPos;
        radiusMeshClone.rotation.y = leftRot;
      }

      if (widthTop && widthBottom && widthLeft && widthRight) {
        if (
          component == 'passepartout' &&
          part == 'inner_pass' ||
          component == 'frame' &&
          part == 'inner_frame'
        ) {
          radiusMeshClone.position.y -= widthTop/2;
          radiusMeshClone.position.x -= widthLeft/2;
          radiusMeshClone.position.y += widthBottom/2;
          radiusMeshClone.position.x += widthRight/2;
        }
      }

      radiusMeshes.push(radiusMeshClone);
    });

    radiusMesh.dispose();
    return radiusMeshes;
  }

  /**
   * ANCHOR Create Radius Mesh
   * @description to create radius on mesh
   * @param options : ICreateRadiusOptions
   * @returns : BABYLON.AbstactMesh
   */
  private _createRadius(options: ICreateRadiusOptions): any {
    const { radius, scene } = options;
    const box = BABYLON.MeshBuilder.CreateBox('box', {}, scene);
    const cylinder = BABYLON.MeshBuilder.CreateCylinder('cylinder', { tessellation: 60 }, scene);
    cylinder.rotation.x = Math.PI/2;
    cylinder.scaling.x = 2;
    cylinder.scaling.z = 2;
    cylinder.position.x = 0.5;
    cylinder.position.y = 0.5;
    const radiusMesh = this._utilsService.CSGActions({
      disposeSourceMeshes: true,
      action: 'subtract',
      mesh2: cylinder,
      mesh1: box,
      scene,
    });
    radiusMesh.scaling.x = radius;
    radiusMesh.scaling.y = radius;
    return radiusMesh;
  }

  /**
   * ANCHOR Create Back Frame Mesh
   * @description Create the back frame mesh for the artwork image/video
   * @param artwork: Artwork
   * @param scene: BABYLON.Scene
   * @param materials: BABYLON.StandardMaterial[]
   * @returns : BABYLON.Mesh
   */
  private _createBackFrameMesh(options: ICreateFrameComponentOptions): any {
    const { artwork, scene, materials } = options;
    const { back_frame_depth } = artwork.frame.back_frame;
    const {
      passepartout,
      passepartout_width_bottom,
      passepartout_width_left,
      passepartout_width_right,
      passepartout_width_top,
    } = artwork.frame.passepartout;
    const { frame } = artwork.frame.frame;
    const backFrame = this._createArtworkComponent({
      width: artwork.real_width + (passepartout ? passepartout_width_left + passepartout_width_right : 0),
      height: artwork.real_height + (passepartout ? passepartout_width_top + passepartout_width_bottom : 0),
      thickness: back_frame_depth,
      outerRadius: this._getBackFrameRadius(artwork),
      useOuterRadius: this._isBackFrameRounded(artwork),
      scene,
    });

    backFrame.name = 'backFrame';
    backFrame.position.y += passepartout ? passepartout_width_top/2 : 0;
    backFrame.position.y -= passepartout ? passepartout_width_bottom/2 : 0;
    backFrame.position.x += passepartout ? passepartout_width_left/2 : 0;
    backFrame.position.x -= passepartout ? passepartout_width_right/2 : 0;

    if (frame) backFrame.material = materials.frameMaterial;
    else if (passepartout) backFrame.material = materials.passepartoutMaterial;
    else backFrame.material = materials.backFrameMaterial;
    return backFrame;
  }

  /**
   * ANCHOR Get Back Frame Radius
   * @description to get the back frame radius
   * @param artwork : Artwork
   * @returns : number
   */
  private _getBackFrameRadius(artwork: IArtwork): number {
    const { frame, frame_radius } = artwork.frame.frame;
    const {
      passepartout,
      passepartout_radius,
      passepartout_width_left,
      passepartout_width_right,
      passepartout_width_top,
      passepartout_width_bottom,
    } = artwork.frame.passepartout;
    let radius = 0;
    if (!frame && passepartout) radius = passepartout_radius + Math.min(passepartout_width_left, passepartout_width_right, passepartout_width_top, passepartout_width_bottom);
    if (frame) radius = frame_radius;
    return radius;
  }

  /**
   * ANCHOR Detect Back Frame/Canvas Rounded
   * @description to detect if the back frame is rounded
   * @param artwork : Artwork
   * @returns : boolean
   */
  private _isBackFrameRounded(artwork: IArtwork): boolean {
    const { frame, frame_radius } = artwork.frame.frame;
    const { passepartout, passepartout_radius } = artwork.frame.passepartout;
    if (!frame && !passepartout) return false;
    if (!frame && passepartout) return passepartout_radius > 0;
    if (frame) return frame_radius > 0;
    return false;
  }

  /**
   * ANCHOR Create Image Video Mesh
   * @description Create the image/video mesh for the artwork image/video
   * @param artwork: Artwork
   * @param scene: BABYLON.Scene
   * @param materials: BABYLON.StandardMaterial[]
   * @returns : BABYLON.Mesh
   */
  private _createImageVideoMesh(options: ICreateFrameComponentOptions): any {
    const { artwork, scene, materials } = options;
    const { back_frame_depth } = artwork.frame.back_frame;
    const {
      passepartout_width_left,
      passepartout_width_right,
      passepartout_width_top,
      passepartout_width_bottom,
    } = artwork.frame.passepartout;
    const { frame_radius } = artwork.frame.frame;
    const { real_height, real_width } = artwork;


    let image = this._createArtworkComponent({
      useOuterRadius: this._isCanvasRounded(artwork),
      outerRadius: this._getCanvasRadius(artwork),
      height: real_height,
      width: real_width,
      thickness: 0.00002,
      widthBottom: passepartout_width_bottom,
      widthLeft: passepartout_width_left,
      widthRight: passepartout_width_right,
      widthTop: passepartout_width_top,
      scene,
    });

    const squareWidthInRadius = passepartout_width_bottom + passepartout_width_top + real_width - (frame_radius * 0.295) * 2;

    if (squareWidthInRadius < real_width) {
      const tmpMesh = this._createArtworkComponent({
        useOuterRadius: true,
        outerRadius: frame_radius,
        height: passepartout_width_top + passepartout_width_bottom + real_height,
        width: passepartout_width_left + passepartout_width_right + real_width,
        thickness: 0.00002,
        scene,
      });

      image = this._utilsService.CSGActions({
        action: 'intersect',
        disposeSourceMeshes: true,
        mesh1: image,
        mesh2: tmpMesh,
        scene,
      });
    }

    const zPosition = back_frame_depth / 2 + 0.009;
    image.scaling.x = real_width;
    image.scaling.y = real_height;
    image.rotation.y = Math.PI;
    image.position.z = zPosition;
    image.material = materials.imageVideoMaterial;
    image.name = 'imageVideo';
    return image;
  }

  /**
   * ANCHOR Is Canvas Rounded
   * @description to detect if the canvas is rounded
   * @param artwork : Artwork
   * @returns : boolean
   */
  private _isCanvasRounded(artwork: IArtwork): boolean {
    const { frame, frame_radius } = artwork.frame.frame;
    const { passepartout, passepartout_radius } = artwork.frame.passepartout;
    if (!frame && !passepartout) return false;
    if (!frame && passepartout) return passepartout_radius > 0;
    if (frame && !passepartout) return frame_radius > 0;
    if (frame && passepartout) return passepartout_radius > 0;
    return false;
  }

  /**
   * ANCHOR Get Canvas Radius
   * @description to get the canvas radius
   * @param artwork : Artwork
   * @returns : number
   */
  private _getCanvasRadius(artwork: IArtwork): number {
    const { frame, frame_radius } = artwork.frame.frame;
    const { passepartout, passepartout_radius } = artwork.frame.passepartout;
    let radius = 0;
    if (passepartout) radius = passepartout_radius;
    if (frame && !passepartout) radius = frame_radius;
    return radius;
  }

  /**
   * ANCHOR Create Passepartout Mesh
   * @description Create the passepartout mesh for the artwork image/video
   * @param options: ICreateFrameComponentOptions
   * @returns : BABYLON.Mesh
   */
  private _createPassepartoutMesh(options: ICreateFrameComponentOptions): any {
    const { artwork, scene, materials } = options;
    const {
      passepartout,
      passepartout_depth,
      passepartout_radius,
      passepartout_width_left,
      passepartout_width_right,
      passepartout_width_top,
      passepartout_width_bottom,
    } = artwork.frame.passepartout;
    const { back_frame_depth } = artwork.frame.back_frame;
    const { real_height, real_width } = artwork;
    if (passepartout) {
      const passepartoutMesh = this._createArtworkComponent({
        height: real_height + passepartout_width_top + passepartout_width_bottom,
        width: real_width + passepartout_width_left + passepartout_width_right,
        widthTop: passepartout_width_top,
        widthBottom: passepartout_width_bottom,
        widthLeft: passepartout_width_left,
        widthRight: passepartout_width_right,
        thickness: passepartout_depth,
        centerHole: true,
        innerRadius: passepartout_radius,
        outerRadius: this._getPasseOuterRadius(artwork),
        useInnerRadius: passepartout_radius > 0,
        useOuterRadius: this._isPasseUseOuterRadius(artwork),
        component: 'passepartout',
        scene,
      });
      passepartoutMesh.name = 'passepartout';
      passepartoutMesh.material = materials.passepartoutMaterial;
      passepartoutMesh.position.z = back_frame_depth/2 + passepartout_depth / 2;
      passepartoutMesh.position.y += passepartout_width_top/2;
      passepartoutMesh.position.y -= passepartout_width_bottom/2;
      passepartoutMesh.position.x -= passepartout_width_right/2;
      passepartoutMesh.position.x += passepartout_width_left/2;
      return passepartoutMesh;
    }
    return null;
  }

  /**
   * ANCHOR Get Passe Outer Radius
   * @description to get the passepartout outer radius
   * @param artwork : Artwork
   * @returns : number
   */
  private _getPasseOuterRadius(artwork: IArtwork): number {
    const { frame, frame_radius } = artwork.frame.frame;
    const {
      passepartout_radius,
      passepartout_width_top,
      passepartout_width_bottom,
      passepartout_width_left,
      passepartout_width_right,
    } = artwork.frame.passepartout;
    if (!frame && passepartout_radius > 0) return passepartout_radius + Math.min(passepartout_width_top, passepartout_width_bottom, passepartout_width_left, passepartout_width_right);
    if (frame && frame_radius > 0) return frame_radius;
    return 0;
  }

  /**
   * ANCHOR Detect Passepartout Use Outer Radius
   * @description to detect if the passepartout use outer radius
   * @param artwork : Artwork
   * @returns : boolean
   */
  private _isPasseUseOuterRadius(artwork: IArtwork): boolean {
    const { frame, frame_radius } = artwork.frame.frame;
    const { passepartout_radius } = artwork.frame.passepartout;
    if (!frame && passepartout_radius > 0) {
      return true;
    }
    if (frame && frame_radius > 0) {
      return true;
    }
    return false;
  }

  /**
   * ANCHOR Create Frame Mesh
   * @description Create the frame mesh for the artwork image/video
   * @param artwork : Artwork
   * @param scene : BABYLON.Scene
   * @param materials : BABYLON.StandardMaterial[]
   * @returns : BABYLON.Mesh
   */
  private _createFrameMesh(options: ICreateFrameComponentOptions): any {
    const { artwork, scene, materials } = options;
    const {
      passepartout,
      passepartout_width_top,
      passepartout_width_bottom,
      passepartout_width_left,
      passepartout_width_right,
    } = artwork.frame.passepartout;
    const {
      frame,
      frame_width_top,
      frame_width_bottom,
      frame_width_left,
      frame_width_right,
      frame_depth,
      frame_radius,
    } = artwork.frame.frame;
    const { back_frame_depth } = artwork.frame.back_frame;
    const { real_width, real_height } = artwork;
    if (frame) {
      const frameMesh = this._createArtworkComponent({
        height: real_height + (passepartout ? (passepartout_width_top + passepartout_width_bottom) : 0) + frame_width_top + frame_width_bottom,
        width: real_width + (passepartout ? (passepartout_width_left + passepartout_width_right) : 0) + frame_width_left + frame_width_right,
        thickness: frame_depth,
        centerHole: true,
        widthTop: frame_width_top,
        widthBottom: frame_width_bottom,
        widthLeft: frame_width_left,
        widthRight: frame_width_right,
        useInnerRadius: frame_radius > 0,
        useOuterRadius: frame_radius > 0,
        innerRadius: frame_radius,
        outerRadius: frame_radius + Math.min(frame_width_top, frame_width_bottom, frame_width_left, frame_width_right),
        component: 'frame',
        scene,
      });
      frameMesh.name = 'frame';
      frameMesh.material = materials.frameMaterial;
      frameMesh.position.z = back_frame_depth/2 + frame_depth / 2;
      frameMesh.position.y += (passepartout ? passepartout_width_top/2 : 0) + frame_width_top/2;
      frameMesh.position.y -= (passepartout ? passepartout_width_bottom/2 : 0) + frame_width_bottom/2;
      frameMesh.position.x -= (passepartout ? passepartout_width_right/2 : 0) + frame_width_right/2;
      frameMesh.position.x += (passepartout ? passepartout_width_left/2 : 0) + frame_width_left/2;
      return frameMesh;
    }
    return null;
  }

  //#endregion
  //!SECTION

  //#endregion
  //!SECTION
}
