import { Injectable } from '@angular/core';
import { IElasticSearch } from '../interfaces/elasticsearch';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@environments';
import { ScriptService } from 'ngx-script-loader';
declare const bowser: any;

@Injectable({
  providedIn: 'root',
})

export class ElasticsearchService {
  private _continentList: any = {
    AF: 'Africa',
    AN: 'Antarctica',
    AS: 'Asia',
    EU: 'Europe',
    NA: 'North America',
    OC: 'Oceania',
    SA: 'South America',
  };
  public ipData: any = null;
  private _session: string = '';

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _scriptService: ScriptService,
  ) { }

  /**
   * ANCHOR Send To Elasticsearch
   * @description send data to elasticsearch
   * @param type: string
   * @param figureId?: string
   */
  public async sendToElasticsearch(type: string, figureId?: string): Promise<void> {
    if (!this._session) this._session = this._generateUUID();
    if (!this.ipData) await this.getClientIPData();
    const browserData = bowser.parse(window.navigator.userAgent)
    const site = this._router.url.split('?')[0].slice(1);
    const referer = document.referrer.includes(environment.baseHost) ? '' : document.referrer;
    const body: IElasticSearch = {
      type: type,
      session: this._session,
      device: browserData.platform.type,
      site: site,
      ip: this.ipData.ip || '',
      referer: referer,
      browser: browserData.browser.name,
      city_name: this.ipData.city || '',
      continent_name: this.getContinentName(this.ipData.continent_code),
      country_iso_code: this.ipData.country_code_iso3 || '',
      region_name: this.ipData.country_name || '',
      figure_id: figureId ||'',
    };

    this._http.post(`${environment.baseURL}/log`, body).subscribe();
  }

  /**
   * ANCHOR Get Client IP Data
   * @description get client ip data
   */
  private getClientIPData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get('https://ipapi.co/json/').subscribe((res: any) => {
        this.ipData = res;
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * ANCHOR Get Comtinent Name
   * @description get continent name from continent code
   * @returns : string
   */
  private getContinentName(continentCode: string): string {
    if (this._continentList[continentCode]) return this._continentList[continentCode];
    else return '';
  }

  /**
   * ANCHOR Generate UUID
   * @description Generate a UUID
   * @returns : string
   */
  private _generateUUID(): string {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
}
