// Common Angular modules/components
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, makeStateKey, OnInit, PLATFORM_ID, TransferState } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

// User-defined services
import { MessageService } from '../components/message/message.service';
import { CookieService } from '@services/cookie.service';
import { MainService } from '@services/main.service';
import { UtilsService } from '@services/utils.service';

// Environment
import { environment } from '@environments';

// Third party plugins NPM
import moment from 'moment';
import { debounce } from 'lodash';
import { Base64 } from 'js-base64';

// Third party plugins CDN
declare const BABYLON: any;
declare const introJs: any;
declare const bowser: any;

// User-defined interfaces
import { IArtwork } from '@interfaces/artwork';
import { Tips, TipsProgress } from './villume-gallery.intefaces';
import { SplashScreen } from '@interfaces/splashscreen';

// Shared
import { lightTheme } from '../shared/data/theme';
import { darkTheme } from '../shared/data/theme';
import { ElasticsearchService } from '../shared/services/elasticsearch.service';
import { TextWall } from '@interfaces/text-wall';
import { TtsService } from '@services/tts.service';
import { MessageComponent } from '../components/message/message.component';
import { NotSubsDialogComponent } from '../components/not-subs-dialog/not-subs-dialog.component';
import { SplashScreenPreviewComponent } from '../components/splash-screen-preview/splash-screen-preview.component';
import { HelpComponent } from '../components/help/help.component';
import { MoreFromAuthorComponent } from './more-from-author/more-from-author.component';
import { ArtworkListComponent } from './artwork-list/artwork-list.component';
import { AboutExhibitionComponent } from './about-exhibition/about-exhibition.component';
import { CommonModule, isPlatformServer } from '@angular/common';

const validateAccess = makeStateKey<any>('');

@Component({
    selector: 'app-villume-gallery',
    templateUrl: './villume-gallery.component.html',
    styleUrls: ['./villume-gallery.component.scss'],
    animations: [
        trigger('easeInOut', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(-200px)',
                }),
                animate(150, style({
                    opacity: 1,
                    transform: 'translateY(0)',
                })),
            ]),
            transition(':leave', [
                style({
                    opacity: 1,
                    transform: 'translateY(0)',
                }),
                animate(150, style({
                    opacity: 0,
                    transform: 'translateY(-200px)',
                })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
        CommonModule,
        AboutExhibitionComponent,
        ArtworkListComponent,
        MoreFromAuthorComponent,
        HelpComponent,
        SplashScreenPreviewComponent,
        NotSubsDialogComponent,
        MessageComponent
    ],
})

export class VillumeGalleryComponent implements OnInit {
  public showSideContent: boolean = false;
  public isActiveExhibition: boolean = false;
  public theme: any;
  public fullscreen: boolean = false;
  public onLoadingInterval:any;
  public showHelp: boolean = false;
  public showMobile: boolean = true;
  public FPS: number = 0;
  public villumeAdminUrl: string = environment.villumeAdminUrl;
  public zeroPrices: string[] = [ '0', 'empty', 'null' ];
  public toggleTruncateMaterial: boolean = true;
  public chooseQuality: boolean = true;
  public env = environment;
  public previewCreate: boolean = false;

  public splashScreens: SplashScreen = {} as SplashScreen;
  public showSplashScreen: boolean = true;

  constructor(
    public mainService: MainService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _titleService:Title,
    private _utilsService: UtilsService,
    private _cookieService: CookieService,
    private _metaService:Meta,
    private _messageService: MessageService,
    private _elasticsearchService: ElasticsearchService,
    public ttsService: TtsService,
    private _transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ){}

  ngOnInit(): void {
    // get exhibition id from route
    this.mainService.exhibitionQuality = this._route.snapshot.paramMap.get('q') as string;
    this.mainService.onTesting = this._route.snapshot.paramMap.get('testing') === 'true';
    this.mainService.reloadTest = this._route.snapshot.paramMap.get('reloadTest') === 'true';
    this.mainService.textWallOri = this._route.snapshot.paramMap.get('textWallOri') === 'true';
    if (!this.mainService.whiteLabel) this.mainService.exhibitionString = this._route.snapshot.paramMap.get('share_string') as string;
    this.previewCreate = this._route.snapshot.paramMap.get('pv') == 'true';

    if (isPlatformServer(this.platformId)) {
      this.mainService.validateAccessibilityGallery().subscribe({
        next: (res) => this._transferState.set(validateAccess, res),
        error: () => {
          this._transferState.set(validateAccess, 'error');
        }
      });
    }

    if (this.mainService.isBrowser && !this.mainService.exhibitionString) {
      this._router.navigate(['/404']);
    }
    this.getCameraPositionInQueryParams();

    // set loading progress
    this.mainService.onLoadingData = true;
    this.mainService.loadingProgress = 0;

    // Validate access gallery
    this._validateAccessGallery().then((res) => {
      this._fetchExhibition(res);
    }).catch(() => {
      this._router.navigate(['/404']);
    })
    this.chooseQuality = false;

    if (this.mainService.isBrowser) {
      this.mainService.isIOS = this.isIOS();

      // Init screen height on load
      document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
      document.documentElement.style.setProperty('--vw', `${window.innerWidth}px`);

      // Update screen height on resize
      window.addEventListener('resize', () => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
        document.documentElement.style.setProperty('--vw', `${window.innerWidth}px`);
      });
    }

    this.deviceOrientationChanges();
  }

  /**
   * * ====================================================================================== *
   * * SECTIONS LIST
   * * ====================================================================================== *
   * - FETCHING DATA FUNCTIONS
   * - UNCATEGORY FUNCTIONS
   * - TIPS FUNCTIONS
   */










  /**
   * * ====================================================================================== *
   *   SECTION Fetching Data Functions
   * * ====================================================================================== *
   */

  //#region

  /**
   * ANCHOR Fetch Exhibition Data From Response
   * @description to get exhibition data from response
   */
  private _fetchExhibition(validate?:{ subscribed: true, published: true }) : void {
    this.mainService.getExhibition(this.mainService.exhibitionString).subscribe(async (res:any)=>{
      this._setSplashScreen(res.data.viewer.exhibition?.splash_screens);

      // Get exhibition data
      this.getExhibitionDataFromResponse(res.data.viewer);
      // Update meta tags for SEO
      this.updateMetaTag();

      if (this.mainService.isBrowser) {
        if (localStorage.getItem('appVersion') !== String(this.mainService.appVersion)) {
          localStorage.setItem('appVersion', String(this.mainService.appVersion));
          this._utilsService.clearIndexBD();
          this._cookieService.clearAllCookies();
        }

        this.mainService.galleryVersion = res.data.viewer.gallery_version || Date.now();

        // Get Other Data
        this.getUserInfoData();
        await this._getArtworksDataFromExhibitionData();
        this.getOrdinaryObjectDataFromExhibitionData();
        this.getPublishTimeAndStatus();

        // Set viewer theme based on data
        this.setViewerTheme();

        // Get Other Exhibitions from author
        this.getOtherExhibition();

        if (this.checkingWebglCompatility()) {
          this.mainService.loadScripts([
            environment.staticAssets+'plugins/babylonjs/babylonjs-viewer.js?t='+this.mainService.appVersion,
            environment.staticAssets+'plugins/introjs/intro.min.js?t='+this.mainService.appVersion,
            environment.staticAssets+'plugins/bowser/bowser.js?t='+this.mainService.appVersion,
            environment.staticAssets+'plugins/webfont/webfont.js?t='+this.mainService.appVersion,
            environment.staticAssets+'plugins/moment/moment.min.js?t='+this.mainService.appVersion,
          ]).then(() => {
            this.mainService.browserData = bowser.parse(window.navigator.userAgent);
            this._getTextsDataFromExhibitionData();

            // Enable caching
            BABYLON.Database.IDBStorageEnabled = true;
            BABYLON.SceneLoader.ShowLoadingScreen = false;
            BABYLON.DracoCompression.Configuration = {
              decoder: {
                wasmUrl: environment.staticAssets+'plugins/draco/draco_wasm_wrapper_gltf.js',
                wasmBinaryUrl: environment.staticAssets+'plugins/draco/draco_decoder_gltf.wasm',
                fallbackUrl: environment.staticAssets+'plugins/draco/draco_decoder_gltf.js',
              },
            };

            this._elasticsearchService.sendToElasticsearch('visit');

            // Init important babylon object
            this.initBabylonCoreObjects();

            // Check exhibition status, it's active or not
            this.checkExhibitionStatus();

            // Render Viewer Scene
            this.renderViewer(validate?.subscribed);
          }).catch(() => {
            this._messageService.add({
              type: 'error',
              title: 'Error',
              detailMessage: 'something went wrong, please try again later.',
            });
          });
        } else {
          this._messageService.add({
            type: 'error',
            title: 'WebGL Error',
            detailMessage: 'Failed to get WebGL context. Your browser or device may not support WebGL',
          });
        }
      }
    }, (err) =>{
      if (err.name == 'TimeoutError') {
        this._fetchExhibition();
        this.mainService.onLoadingData = false;
      } else {
        if (this.mainService.isBrowser) {
          if (this.mainService.whiteLabel) window.location.href = '/';
          else {
            this._router.navigate(['/404']);
          }
        }
      }
    });
  }

  private _validateAccessGallery(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.previewCreate) {
        resolve({ subscribed: true, published: true });
      } else {
        const access = this._transferState.get(validateAccess, null);
        if (access === 'error') return reject(null);

        const subscribed = access.data.subscribed;
        const published = access.data.published;
        resolve({ subscribed, published });
      }
    });
  }

  //#endregion
  // !SECTION



  /**
   * * GET ALL EXHIBITIONS OF AUTHOR *
   * Todo: to geting all exhibition from author
   */
  getOtherExhibition() {
    this.mainService.getOtherExhibitions(this.mainService.exhibitionString).subscribe((res:any)=>{
      const otherExhibitions = res.data.other_exhibition.filter((exhibit: any) => exhibit.share_string != this.mainService.exhibitionString);
      this.mainService.otherExhibitions = otherExhibitions.map((exhibition: any) => {
        // Add prifix assets path for exhibition thumbail
        exhibition.thumbnail = environment.exhibitionAssetsUrl + exhibition.thumbnail;

        // Add prifix assets path for exhibition cover image
        if (exhibition.cover_image && !exhibition.cover_image.includes(environment.imgUrl)) {
          exhibition.cover_image = this.convertPathImage(exhibition.cover_image);
        }

        return exhibition;
      });
    });
  }

  /**
   * * GET EXHIBITION DATA FROM RESPONSE *
   * Todo: to get exhibition data response
   * @param response: Object -> Success response from API
   */
  getExhibitionDataFromResponse(response: any) {
    // Get exhibtion data
    const exhibition = response.exhibition[0] || response.exhibition;
    // Add prefix path assets
    exhibition.model_path = environment.exhibitionAssetsUrl + exhibition.model_path.replace(environment.exhibitionAssetsUrl, '');
    exhibition.light_map = environment.exhibitionAssetsUrl + exhibition.light_map.replace(environment.exhibitionAssetsUrl, '');

    // Add prifix assets path for exhibition thumbail
    exhibition.thumbnail = environment.staticAssets + exhibition.thumbnail + '?t=' + this.mainService.appVersion;

    // Add prifix assets path for exhibition cover image
    if (exhibition.cover_image && !exhibition.cover_image.includes(environment.imgUrl)) {
      // resize cover image for shareable
      const resizeCover = exhibition.cover_image.split('?')[1].split('&')[0];
      exhibition.cover_image = exhibition.cover_image.replace(resizeCover, 'resize=600x600');
      exhibition.cover_image =this.convertPathImage(exhibition.cover_image);
    }


    // Set default show donut value
    if (exhibition.show_donut == undefined) exhibition.show_donut = true;

    this.mainService.exhibition = exhibition;
  }

  /**
   * * GET USER INFO DATA *
   * Todo: to get user info data
   */
  getUserInfoData() {
    this.mainService.getUserInfo(this.mainService.exhibitionString).subscribe((res: any) => {
      const userInfo = res.data.user_data;

      if (!userInfo.avatar) userInfo.avatar = environment.staticAssets+'images/other/default-avatar.png';
      else if (!userInfo.avatar.includes(environment.imgUrl) && !userInfo.avatar.includes('googleusercontent')) {
        userInfo.avatar = this.convertPathImage(userInfo.avatar);
      }

      this.mainService.userInfo = userInfo;
    }, (err) => {
      this._messageService.add({ type: 'error', title: 'Error', detailMessage: 'Server error.' });

      setTimeout(()=>{
        window.location.href = `${environment.villumeAdminUrl}/not-found`;
      }, 1000);
    });
  }

  /**
   * * GET IMAGE WITH CUSTOM RESOLUTION *
   * Todo: to get image with custom resolution
   * @param url: String -> url of image (https://img.villume.com)
   * @param maxDimension: Number -> maximum width/height limit
   * @param targetWidth: Number -> target width
   * @returns String -> new url of image (https://img.villume.com)
   */
  customImgResolution(url: string, maxDimension: number | null = null, targetWidth: number | null = null) {
    if (maxDimension) {
      return this.mainService.customImageResolution({
        url,
        type: 'maxDimension',
        maxDimension,
      });
    }

    if (targetWidth) {
      return this.mainService.customImageResolution({
        url,
        type: 'customWidth',
        width: targetWidth,
      });
    }

    return url;
  }

  /**
   * * GET ORDINARY OBJECT DATA FROM EXHIBTION DATA *
   * Todo: to get ordinary object data exhibition data
   */
  getOrdinaryObjectDataFromExhibitionData() {
    const ordinaryObjects = this.mainService.exhibition.objects.filter((x:any)=>!x.deleted);
    this.mainService.ordinaryObjects = ordinaryObjects.map((object: any) =>{
      if (!object.model_path.includes(environment.assetsUrl)) {
        object.model_path = environment.assetsUrl + object.model_path;
      }

      return object;
    });
  }

  /**
   * * GET PUBLISH TIME AND STATUS *
   * Todo: to getting publish status and publish time
   */
  getPublishTimeAndStatus() {
    this.mainService.published = this.mainService.exhibition.published;
    this.mainService.started = this.mainService.exhibition.started;
    this.mainService.ended = this.mainService.exhibition.ended;
    this.mainService.unlimited_time = this.mainService.exhibition.unlimited_time;
  }










  /**
   * * ====================================================================================== *
   * * TIPS FUNCTIONS
   * * ====================================================================================== *
   * - SHOW TIPS
   * - CRAETE TIPS DATA
   * - GET TIPS PROGRESS
   * - MARK AS VIEWED TIPS
   * - INIT INTOJS
   * /

  /**
   * * SHOW TIPS *
   * Todo: to show tips
   */
  showTips() {
    const tips = this.createTipsData();
    if (tips.length > 0) this.initIntroJS(tips);
  }

  /**
   * * CRAETE TIPS DATA *
   * Todo: to craete tips data
   * @returns Array of Object
   */
  createTipsData() {
    // Desktop steps(tips)
    let tips: Tips[] = [
      {
        featureName: 'Camera Controls',
        position: 'right',
        selector: '#camera-controls-tips',
        intro: 'Click on the floor to move. Alternatively you can use WSAD + mouse to navigate',
      },
      {
        featureName: 'Hover Info',
        position: 'right',
        selector: '#hover-info-tips',
        intro: 'Hover on any artwork for 2 seconds or more to get short information about it',
      },
      {
        featureName: 'Focus on Artwork',
        position: 'right',
        selector: '#focus-on-artwork-tips',
        intro: 'Click on an artwork to get there directly and unfold detailed information about it',
      },
      {
        featureName: 'About Exhibition',
        position: 'left',
        selector: '.btn-side-arrow',
        intro: 'Click on the arrow to unfold detailed information about the exhibition',
      },
      {
        featureName: 'Room Tour',
        position: 'top-middle-aligned',
        selector: '.menu-play',
        intro: 'Start a guided tour and navigate between artworks',
      },
      {
        featureName: 'Fullscreen',
        selector: '.fullscreen-tips',
        intro: 'Full screen mode',
      },
      {
        featureName: 'Bird\'s Eye View',
        selector: '.birds-eye-tips',
        intro: 'To switch from the 1st person view to Bird\'s Eye, simply click this button',
      },
      {
        featureName: 'Help Center',
        selector: '.help-tips',
        intro: 'Click here any time to open the Help Center',
      },
    ];

    // Replace steps(tips) for mobile
    if (this.mainService.isMobile) {
      tips = [
        {
          featureName: 'Camera Controls',
          position: 'right',
          selector: '#camera-controls-tips',
          intro: 'Tap and drag to move and turn in different directions',
        },
        {
          featureName: 'Focus on Artwork',
          position: 'right',
          selector: '#focus-on-artwork-tips',
          intro: 'Tap on the artworks to get there directly and unfold detailed information about it',
        },
        {
          featureName: 'About Exhibition',
          position: 'left',
          selector: '.btn-side-arrow',
          intro: 'Tap on the arrow to unfold detailed information about the exhibition',
        },
        {
          featureName: 'Undo Action',
          position: 'left',
          selector: '.btn-undo-action',
          intro: 'Return to the previous action',
        },
        {
          featureName: 'Room Tour',
          position: 'top-middle-aligned',
          selector: '.menu-play',
          intro: 'Start a guided tour and navigate between artworks',
        },
        {
          featureName: 'Fullscreen',
          selector: '.fullscreen-tips',
          intro: 'Full screen mode (Android only)',
        },
        {
          featureName: 'Bird\'s Eye View',
          selector: '.birds-eye-tips',
          intro: 'To switch from the 1st person view to Bird\'s Eye, simply click this button',
        },
        {
          featureName: 'Help Center',
          selector: '.help-tips',
          intro: 'Tap here any time to open the Help Center',
        },
      ];
    }

    // Remove "Room Tour" tips when gallery have more than one artwork
    if (this.mainService.artworks.length < 2) {
      tips = tips.filter((tip: Tips) => tip.featureName !== 'Room Tour');
    }

    // Remove "Fullscreen" tips on device with IOS OS
    if (this.mainService.isIOS) {
      tips = tips.filter((tip: Tips) => tip.featureName !== 'Fullscreen');
    }

    // Remove "About Exhibition" tips if the gallery is inactive
    if (!this.isActiveExhibition) {
      tips = tips.filter((tip: Tips) => tip.featureName !== 'About Exhibition');
    }

    // Data stored in 'tipsProgress' class property
    this.getTipsProgress();

    // Filter 'tips' data that has not been seen base don tips progress data
    tips = tips.filter((tip: Tips) => {
      const unviewedStep = this._tipsProgress.find((x: TipsProgress) => x.feature === tip.featureName && x.viewed === false);
      if (unviewedStep) return true;
      return false;
    });

    // Add element & title data
    tips = tips.map((tip: Tips, index: number) => {
      tip.element = document.querySelector(tip.selector);
      tip.title = `Tip ${index + 1} of ${tips.length}`;
      return tip;
    });

    return tips;
  }

  /**
   * * GET TIPS PROGRESS *
   * Todo: to getting tips progress from browser storage
   */
  private _tipsProgress: TipsProgress[] = [];
  getTipsProgress() {
    try {
      this._tipsProgress = JSON.parse(localStorage['tipsProgress']);
    } catch (error) {
      this._tipsProgress = [
        { feature: 'Camera Controls', viewed: false },
        { feature: 'Hover Info', viewed: false },
        { feature: 'Focus on Artwork', viewed: false },
        { feature: 'About Exhibition', viewed: false },
        { feature: 'Undo Action', viewed: false },
        { feature: 'Room Tour', viewed: false },
        { feature: 'Fullscreen', viewed: false },
        { feature: 'Bird\'s Eye View', viewed: false },
        { feature: 'Help Center', viewed: false },
      ];
      localStorage['tipsProgress'] = JSON.stringify(this._tipsProgress);
    }
  }

  /**
   * * MARK AS VIEWED TIPS *
   * Todo: to mark the tips as viewed tips and save it into browser storage
   * @param featureName: String -> Name of feature
   */
  maskAsViewedTips(featureName: string) {
    const tipsProgress = this._tipsProgress.find((tip: TipsProgress) => tip.feature == featureName);
    if (tipsProgress) tipsProgress.viewed = true;
    localStorage['tipsProgress'] = JSON.stringify(this._tipsProgress);
  }

  /**
   * * INIT INTOJS *
   * Todo: to init introJS
   * @param tips: Array of Object -> steps options
   */
  initIntroJS(tips: Tips[]) {
    // Setup introJS options
    const options = {
      exitOnOverlayClick: false,
      keyboardNavigation: false,
      disableInteraction: false,
      showBullets: false,
      nextLabel: 'Next<i class="vi vi-arrow-right"></i>',
      prevLabel: 'Skip All',
      doneLabel: 'Okay!',
      overlayOpacity: 0,
      steps: tips,
    };

    // Init introJS
    const introJS = introJs();
    introJS.setOptions(options);

    // Start intro js
    introJS.start();

    // Handle change tips
    introJS.onchange((el: HTMLElement) => {
      const feature = el.dataset['tipsFeature'];
      const idx = tips.findIndex((x: Tips) => x.featureName === feature);
      const prevTips = tips[idx-1];
      if (prevTips) this.maskAsViewedTips(prevTips.featureName);
    });

    // Handle complete tips
    introJS.oncomplete(() => {
      const lastTips = tips[tips.length-1];
      this.maskAsViewedTips(lastTips.featureName);
    });

    // Handle close tips
    introJS.onexit(() => {
      tips.forEach((tips: Tips) => {
        this.maskAsViewedTips(tips.featureName);
      });
    });
  }

  /**
   * ANCHOR Get active tooltip
   * @param string: name tooltip
   * @returns boolean
   */
  onActiveTipsClick(name:string): boolean {
    let tips = this._tipsProgress.filter((x: TipsProgress) => x.viewed === false);

    // Remove "Room Tour" tips when gallery have more than one artwork
    if (this.mainService.artworks.length < 2) tips = tips.filter((tip: TipsProgress) => tip?.feature !== 'Room Tour');
    // Remove "Fullscreen" tips on device with IOS OS
    if (this.mainService.isIOS)tips = tips.filter((tip: TipsProgress) => tip?.feature !== 'Fullscreen');
    // Remove "Hover Info" tips on mobile
    if (this.mainService.isMobile) tips = tips.filter((tip: TipsProgress) => tip?.feature !== 'Hover Info');
    // Remove "About Exhibition" tips if the gallery is inactive
    if (!this.isActiveExhibition) tips = tips.filter((tip: TipsProgress) => tip?.feature !== 'About Exhibition');
    // Remove "Undo Action" tips on Desktop
    if (!this.mainService.isMobile) tips = tips.filter((tip: TipsProgress) => tip?.feature !== 'Undo Action');

    if (tips[0]?.feature == name) {
      const el = document.querySelector(`.introjs-tooltip`);

      if (el) {
        el.classList.remove('shake');
        setTimeout(() => {
          el.classList.add('shake');
        }, 100);

        return true;
      }
    } else {
      return false;
    }
    return false;
  }









  /**
   * * ====================================================================================== *
   * * UNCATEGORY FUNCTIONS
   * * ====================================================================================== *
   */
  private _setSplashScreen(data: any) {
    // check if data is array
    if (Array.isArray(data)) this.splashScreens = data[0];
    else this.splashScreens = data;

    if (this.mainService.isBrowser) {
      this.splashScreens.image = data.image ? this.convertPathImage(data.image) : '';
    }
  }

  /**
   * * OPEN DETAIL ARTWORK *
   * Todo: to open detail artwork and init shadow
   */
  onDetail() {
    this.mainService.showDetail=!this.mainService.showDetail;
    if (this.mainService.isBrowser) {
      this.mainService.detailTooltip();
      this.mainService.hiddenSideContentOnFocus();
      // this.mainService.textToSpeech(this.mainService.activeArtwork.description);
    }
    setTimeout(() => {
      this.mainService.shadowDetail();
    }, 200);
  }

  /**
   * * SET VIEWER THEME *
   * Todo: to set viewer theme
   */
  setTheme(theme:any) {
    if (this.mainService.isBrowser) {
      Object.keys(theme).forEach((prop) => {
        document.documentElement.style.setProperty(`--${prop}`, theme[prop]);
      });
    }
  }

  /**
   * * UPDATE META TAG *
   * Todo: to update meta document meta tag
   */
  updateMetaTag() {
    let title:string;
    if (this.mainService.whiteLabel) title = this.mainService.exhibition.name;
    else title = 'Villume | ' + this.mainService.exhibition.name;
    const image = this.mainService.exhibition.cover_image ? this.mainService.exhibition.cover_image : this.mainService.exhibition.thumbnail;
    const description = this.mainService.exhibition.description || 'Villume Gallery';
    const url = environment.baseHost + '/' + this.mainService.exhibitionString;

    // Primary Meta Tags
    this._titleService.setTitle(title);
    this._metaService.updateTag({ name: 'title', content: title });
    this._metaService.updateTag({ name: 'description', content: description });

    // Open Graph / Facebook
    this._metaService.updateTag({ property: 'og:type', content: 'website' });
    this._metaService.updateTag({ property: 'og:url', content: url });
    this._metaService.updateTag({ property: 'og:title', content: title });
    this._metaService.updateTag({ property: 'og:description', content: description });
    this._metaService.updateTag({ property: 'og:image', content: image });

    // Twitter
    this._metaService.updateTag({ property: 'twitter:card', content: 'summary_large_image' });
    this._metaService.updateTag({ property: 'twitter:url', content: url });
    this._metaService.updateTag({ property: 'twitter:title', content: title });
    this._metaService.updateTag({ property: 'twitter:description', content: description });
    this._metaService.updateTag({ property: 'twitter:image', content: image });
  }

  /**
   * * INITIAL BABYLONJS CORE OBJECTS *
   * Todo: to initial babylon js core object
   */
  initBabylonCoreObjects() {
    this.mainService.canvas = document.querySelector('#viewerScene');
    this.mainService.canvas.focus();

    this.mainService.engine = this.mainService.initEngine(this.mainService.canvas);
    this.mainService.scene = this.mainService.createScene(this.mainService.engine);
  }

  /**
   * * SET VIEWER THEME *
   * Todo: to setup viewer theme based on exhibition data
   */
  setViewerTheme() {
    this.mainService.lightMode = this.mainService.exhibition.viewer_theme == 'light';
    this.setTheme(this.mainService.lightMode ? lightTheme : darkTheme);
  }

  /**
   * * RENDER VIEWER SCENE *
   * Todo: for rendering viewer scene
   */
  renderViewer(subscribed: boolean = false) {
    // Create camera & light
    this.mainService.setupCamera();
    this.mainService.setupBasicLigting();
    this.handleTabShift();

    this.mainService.scene.onReadyObservable.add(() => {
      // Load Exhibition
      this.mainService.loadExhibtion().then(async ()=>{
        this.enableDetectFPSDown();
        this.mainService.onLoadingData = false;
        this.mainService.loadingProgress = 0;
        if (this.previewCreate) this.showSplashScreen = false;
        // Apply gravity and controls
        this.mainService.customGravityForBalcony();
        this.mainService.applyCameraGravityAndControls();

        this.mainService.initActionManager();

        // Load other assets
        await this.loadAllArtwork();
        await this.loadAllText();


        if (this.mainService.ordinaryObjects.length != 0) {
          this.loadAllOrdinaryObject();
        } else {
          this.mainService.allAssetsHasLoaded = true;
        }

        // Create hover GUI
        setTimeout(() => {
          if (!this.mainService.isMobile) this.mainService.createHovers();
        }, 3010);

        // Register user action event
        this.mainService.initMainPointerObs();
        this.mainService.registerKeyboardEvent();


        // Detect camera movement
        this.mainService.camera.onViewMatrixChangedObservable.add(()=>{
          this.mainService.cameraOutOfRoomHandler();
          this.saveCurrentPosition();
          this.mainService.detectCameraAboveStairs();
        });

        // Debug Layer Babylonjs
        // this.mainService.scene.debugLayer.show();

        if (!subscribed) this.mainService.showNotSubsDialog = true;

        window.dispatchEvent(new Event('resize'));
      });
    });

    this.mainService.renderScene();
  }


  /**
   * * HANDLE TAB SHIFT ACTION *
   * Todo: to handle tab shift action
   */
  handleTabShift() {
    document.addEventListener('visibilitychange', () => {
      // If the tab is active
      if (!document.hidden) {
        this.enableDetectFPSDown();
        if (!this.mainService.onLoadingData) {
          if (this.mainService.allDonutArtworkHasInitialized) {
            this.mainService.artworksNodes.map((artworkNode: any)=>{
              if (!artworkNode['donutHasInitialized']) this.mainService.createArtworkDonut(artworkNode);
            });

            let artworkHasNoDonutCount = 0;
            this.mainService.artworksNodes.forEach((artwork:any)=> {
              if (artwork.artwork_type != 'figure-object') {
                if (!artwork.donutHasInitialized) artworkHasNoDonutCount++;
              }
            });

            this.mainService.allDonutArtworkHasInitialized = artworkHasNoDonutCount == 0;
          }
        }
      } else {
        this.enableDetectFPSDown.cancel();
        this.mainService.detectFPSDown = false;
      }
    });
  }

  enableDetectFPSDown = debounce(() => {
    if (!document.hidden) this.mainService.detectFPSDown = true;
  }, 10000);

  /**
   * * CHECKING WEBGL COMPATIBILITY *
   * Todo: to checking webgl compatibility
   */
  checkingWebglCompatility() {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (gl && gl instanceof WebGLRenderingContext) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * * LOAD ALL ARTWORKS *
   * Todo: for loading all artwork
   */
  async loadAllArtwork() {
    this.mainService.createShadowMeshMaster();

    const artworksImageVideo = this._getArtworkImageAndVideos();
    await Promise.all(artworksImageVideo.map(async (artwork:any)=>{
      const artworkNode = await this.mainService.createArtworkImageVideoNew(artwork);
      this.mainService.createArtworkDonut(artworkNode);
      this.mainService.createShadowCast(artworkNode);
      this.mainService.createShadowComponents(artworkNode);
    }));

    const artworksObject = this._getArtworkObjects();
    await Promise.all(artworksObject.map(async (artwork: IArtwork) => {
      const artworkNode = await this.mainService.loadArtworkObject(artwork);
      artworkNode['donutHasInitialized'] = true;
      artworkNode['shadowHasInitialized'] = true;
    }));
  }

  /**
   * * GET ARTWORK OBJECTS *
   * ANCHOR Get Artwork Objects
   * @description to getting artwork objects data
   * @returns : Artwork[]
   */
  private _getArtworkObjects(): IArtwork[] {
    return this.mainService.artworks.filter((artwork: IArtwork) => {
      return artwork.file_type == 'figure-object';
    });
  }

  /**
   * * GET ARTWORK IMAGE AND ARTWORK VIDEO *
   * ANCHOR Get Artwork Image And Artwork Video
   * @description to getting artwork image and artwork video data
   * @returns
   */
  private _getArtworkImageAndVideos(): IArtwork[] {
    return this.mainService.artworks.filter((artwork: IArtwork) => {
      return [ 'figure-image', 'video' ].includes(artwork.file_type);
    });
  }

  public toggleSidebar() {
    if (this.onActiveTipsClick('About Exhibition')) return;
    this.mainService.unselectArtwork();
    this.mainService.openSideContent=!this.mainService.openSideContent;

    if (this.mainService.openSideContent) {
      this.ttsService.initTextToSpeech(this.mainService.exhibition.tts_path)
    } else {
      this.ttsService.stopTextToSpeech();
    }
  }

  /**
   * * LOAD ALL TEXT *
   * Todo: to load all text mesh to scene
   */
  async loadAllText() {
    for (let i=0; i<this.mainService.texts.length; i++) {
      const text = this.mainService.texts[i];
      this.mainService.loadTextImage(text);
    }
  }

  /**
   * * FULLSCREEN MODE *
   * Todo: for enable and disable fullscreen mode
   */
  fullscreenMode() {
    if (this.onActiveTipsClick('Fullscreen')) return;
    // activating fullscreen
    window.removeEventListener('resize', this.screenResize);
    if (!this.fullscreen) {
      const body = document.querySelector('body') as HTMLElement;
      body?.requestFullscreen();
      this.fullscreen = true;
    } else if (document.fullscreenElement != null) {
      document.exitFullscreen();
      this.fullscreen = false;
    }

    window.addEventListener('resize', this.screenResize);
  }

  /**
   * * RESIZE SCREEN *
   * Todo: to resize screen
   */
  screenResize = () => {
    // this.mainService.engine.resize();
    if (document.fullscreenElement != null) {
      this.fullscreen = true;
    } else {
      this.fullscreen = false;
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  };

  /**
   * * RESET CAMERA POSITIION *
   * Todo: to reset camera posision
   */
  resetCamera() {
    if (this.onActiveTipsClick('Undo Action')) return;
    this.mainService.cancelMovingCameraAnimation();
    this.mainService.camera.position = this.mainService.cameraStartPos.clone();
    this.mainService.camera.rotation = this.mainService.cameraStartRot.clone();
  }

  /**
   * * CHECK EXHIBITION STATUS *
   * Todo: to checking status exhibition is active or non active
   */
  checkExhibitionStatus() {
    if (this.mainService.published) {
      if (this.mainService.unlimited_time) {
        this.isActiveExhibition = true;
      } else {
        const dateInRange = moment(moment().format('YYYY-MM-DD')).isBetween(this.mainService.started, this.mainService.ended, undefined, '[]');
        if (dateInRange) {
          this.isActiveExhibition = true;
        } else {
          this.isActiveExhibition = false;
        }
      }
    } else {
      this.isActiveExhibition = false;
    }
  }

  /**
   * * LOAD ALL ORDINARY OBJECT *
   * Todo: to load all ordinary object
   */
  loadAllOrdinaryObject() {
    let index = 0;
    const objects = this.mainService.ordinaryObjects;
    this.mainService.scene.blockMaterialDirtyMechanism = true;

    const loadOrdinaryObject = () => {
      this.mainService.loadOrdinaryObject(objects[index], ()=>{
        objects[index]['inScene'] = true;
        index++;

        if (objects[index]) {
          loadOrdinaryObject();
        } else {
          this.mainService.allAssetsHasLoaded = true;
          this.mainService.scene.blockMaterialDirtyMechanism = false;
          // this.mainService.changeLightmapQuality('low');
          window.dispatchEvent(new Event('resize'));
        }
      });
    };

    loadOrdinaryObject();
  }

  /**
   *  Handle tooltip on button click
   **/
  roomTour(action: 'play' | 'pause') {
    if (this.onActiveTipsClick('Room Tour')) return;
    else this.mainService.roomTour(action);
  }

  /**
   * * HANDLE NEXT PREV ACTION *
   **/
  prevNextArtwork(action:string) {
    if (this.onActiveTipsClick('Room Tour')) return;
    else this.mainService.prevNextArtwork(action);
  }

  /**
   * * HANDLE HELP CENTER *
   **/
  handleShowHelp() {
    if (this.onActiveTipsClick('Help Center')) return;
    else this.showHelp = true;
  }

  handleChangeCameraView() {
    if (this.onActiveTipsClick('Bird\'s Eye View')) return;
    else this.mainService.changeCamera();
  }

  /**
   * * RELOAD PAGE TO BASE MODEL *
   * Todo: to load gallery with base model
   */
  public loading: boolean = false;
  toBaseModel() {
    this.loading = true;
    this._router.navigate(
        [ this.mainService.exhibitionString ],
        { queryParams: { q: 'basic' } },
    );

    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  /**
   * * IOS DEVICE DETECTOR *
   * Todo: to detect IOS device
   */
  isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  htmlDecode(input: string) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  }

  /**
   * * CLOSE HELP DIALOG *
   * Todo: close help dialog
   */
  closeHelp() {
    if (this.mainService.isBrowser) {
      document.cookie = 'showHelp=false';
      this.showHelp = false;
    }
  }


  /**
   * * SAVE CURRENT POSITION *
   * Todo: to save current position
   */
  saveCurrentPosition = debounce(() => {
    const camera = this.mainService.scene.activeCamera.name;
    if (
      camera != 'floatingCamera' &&
      !this.mainService.runRoomTour &&
      !this.mainService.activeArtworkMesh
    ) {
      const currentPosition = [
        this.mainService.camera.position.x.toFixed(4),
        this.mainService.camera.position.y.toFixed(4),
        this.mainService.camera.position.z.toFixed(4),
      ];
      const currentRotation = [
        this.mainService.camera.rotation.x.toFixed(4),
        this.mainService.camera.rotation.y.toFixed(4),
        this.mainService.camera.rotation.z.toFixed(4),
      ];

      this._router.navigate(
          [ this.mainService.exhibitionString ],
          {
            queryParams: {
              p: currentPosition.join(','),
              r: currentRotation.join(','),
            },
            queryParamsHandling: 'merge',
          },
      );
    }
  }, 500);

  /**
   * * GET CAMERA POSITION IN QUERY PARAMS *
   * Todo: to get camera position in query params
   */
  getCameraPositionInQueryParams() {
    const position = (this._route.snapshot.queryParams['p'] || '').split(',');
    const rotation = (this._route.snapshot.queryParams['r'] || '').split(',');
    this.mainService.cameraUrlPos = this.parsePosition(position);
    this.mainService.cameraUrlRot = this.parsePosition(rotation);
  }

  /**
   * * PARSE POSITION *
   * Todo: to parse position
   */
  parsePosition(position: string[]) {
    let positionValid = true;
    if (position.length === 3) {
      position.map((x: string) => {
        if (isNaN(Number(x))) positionValid = false;
      });
    } else positionValid = false;

    if (!positionValid) return null;

    return {
      x: Number(position[0]),
      y: Number(position[1]),
      z: Number(position[2]),
    };
  }

  getFPS() {
    try {
      return this.mainService.engine.getFps().toFixed();
    } catch (error) {
      return 0;
    }
  }

  /**
   * * CONVERT IMAGE URL *
   * Todo: to convert img url
   * @param string : String -> image url
   * @return String -> converted image url
  */
  public convertPathImage(path: string): string {
    let imageUrl = '';
    let supportWebp = '';
    if (!path.includes(environment.imgUrl)) {
      imageUrl = `${environment.imgUrl}${path}`;
    }

    this.mainService.checkSupportWebp() ? supportWebp = '.webp':supportWebp = '';
    if (imageUrl.includes('resize')) {
      const match = path.split('?')[1].match(/resize=\d+x\d+/);
      const resize = match?.[0]?.slice(7)?.split('x');
      const pathImg = Base64.encode('local:///'+ path.split('?')[0]);
      imageUrl = `${environment.imgUrl}rs:fit:${resize?.[1]}:${resize?.[0]}:no:0/cb:${this.mainService.appVersion}-${this.mainService.galleryVersion}/${pathImg}${supportWebp}`;
    } else {
      const pathImg = Base64.encode('local:///'+ path);
      imageUrl = `${environment.imgUrl}cb:${this.mainService.appVersion}-${this.mainService.galleryVersion}/${pathImg}${supportWebp}`;
    }

    return imageUrl;
  }

  /**
   * * DETECT DEVICE ORIENTATION *
   * Todo: detect IOS device orientation
   * @param string : String -> image url
  */
  deviceOrientationChanges() {
    if (this.mainService.isBrowser) {
      const mediaQuery = window.matchMedia('(orientation: landscape)');

      // handle orientation changes
      const handleOrientationChange = (event:any) => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
      };

      // Register the callback function on the change event
      mediaQuery.addListener(handleOrientationChange);

      // Calls the callback function for the initial condition
      handleOrientationChange(mediaQuery);
    }
  }

  public selectQuality(quality: string) {
    this.mainService.quality = quality;
    this._fetchExhibition();
    this.chooseQuality = false;
  }

  // ================================================================================================
  // NOTE - Delimiter of the refactored function (below)
  // ================================================================================================

  /**
  * * ================================================================================================ *
  *   SECTION Get Arworks Data From Exhibition Data
  * * ================================================================================================ *
  */
  //#region

  /**
   * ANCHOR Get Artworks Data From Exhibition Data
   * @description to getting artworks data from exhibition data
   * @returns : Promise<void>
   */
  private async _getArtworksDataFromExhibitionData(): Promise<void> {
    const artworks = this.mainService.exhibition.figures.filter((x:any)=>!x.deleted);
    this.mainService.artworks = await Promise.all(artworks.map(async (artwork: IArtwork) => {
      artwork = this._setArtworkImageQuality(artwork);
      artwork = this._setArtworkFramesTextureQuality(artwork, 'frame');
      artwork = this._setArtworkFramesTextureQuality(artwork, 'passepartout');
      artwork = this._setArtworkMediaSupport(artwork);

      // Note: condition for old exhibition data
      artwork = this._setArtworkFramesWidth(artwork, 'frame');
      artwork = this._setArtworkFramesWidth(artwork, 'passepartout');

      artwork = await this._setArtworkVideoData(artwork);
      artwork.model_path = this._getModelPath(artwork);
      return artwork;
    }));

    this._rearrangeAnimationOrder();
  }

  /**
   * ANCHOR Set Artwork Media Support
   * @description to set artworks media support
   * @param artwork : IArtwork
   * @returns : IArtwork
   */
  private _setArtworkMediaSupport(artwork: IArtwork): IArtwork {
    if (artwork.av?.audio && !artwork.av.audio.includes(environment.assetsUrl)) artwork.av.audio = environment.assetsUrl + artwork.av.audio;
    if (artwork.av?.video && !artwork.av.video.includes(environment.assetsUrl)) artwork.av.video = environment.assetsUrl + artwork.av.video;
    return artwork;
  }

  /**
   * ANCHOR Get Video Stream
   * @description to getting video stream
   * @param artwork : IArtwork
   * @returns : Promise<string>
   */
  private _getVideoStreamAndThumbnail(artwork: IArtwork): Promise<{ stream: string, thumb: string} | null> {
    return new Promise((resolve, reject) => {
      const url = artwork.video;
      if (!url) resolve(null);
      const type = this._getVideoType(url as string) as 'youtube' | 'vimeo';
      this.mainService.getVideoData(url as string, type).subscribe((res: any) => {
        const stream = res.data.videos.find((video: any) => video.fps <= 30).stream;
        let thumb;
        switch (type) {
          case 'youtube':
            thumb = res.data.thumbnails.high.url;
            break;
          case 'vimeo':
            thumb = res.data.thumbnails.filter((x: any) => x.width < 1000).slice(-1)[0];
            break;
        }
        resolve({ stream, thumb });
      }, (err: any) => {
        reject(err);
      });
    });
  }

  /**
   * ANCHOR Set Artworks Image Quality
   * @description to set artworks image quality (low and original)
   * @param artwork : IArtwork
   * @returns : IArtwork
   */
  private _setArtworkImageQuality(artwork: IArtwork): IArtwork {
    artwork.image_low_quality = this._getLowQualityImage(artwork);
    artwork.image_original_quality = this._getOriginalImage(artwork);
    artwork.image = artwork.image_original_quality;
    return artwork;
  }

  private _getLowQualityImage(artwork: IArtwork): string {
    let lowQualityImage = artwork.image;
    if (artwork.file_type != 'video') {
      lowQualityImage = this.customImgResolution(lowQualityImage, 50);
      lowQualityImage = this.convertPathImage(lowQualityImage);
    }
    return lowQualityImage;
  }

  private _getOriginalImage(artwork: IArtwork): string {
    let originalImage = artwork.image;
    if (artwork.file_type != 'video') {
      originalImage = this.convertPathImage(originalImage);
    }
    return originalImage;
  }

  /**
   * ANCHOR Set Artwork Video Data
   * @description to set artworks video data
   * @param artwork : IArtwork
   * @returns : Promise<IArtwork>
   */
  private async _setArtworkVideoData(artwork: IArtwork): Promise<IArtwork> {
    const streamAndThumb = await this._getVideoStreamAndThumbnail(artwork);
    artwork.video_stream = streamAndThumb?.stream as string;
    if (artwork.file_type == 'video')  artwork.image = streamAndThumb?.thumb as string;
    return artwork;
  }

  /**
   * ANCHOR Set Artwork Frames (Frame & Passepartout) Texture Quality
   * @description to set artworks frames (frame & passepartout) texture quality (low and original)
   * @param artwork: IArtwork
   * @param type: 'frame' | 'passepartout'
   */
  private _setArtworkFramesTextureQuality(artwork: IArtwork, type: 'frame' | 'passepartout'): IArtwork {
    const data: any = artwork.frame[type];
    if (
      data[type + '_texture'] &&
      !data[type + '_texture'].includes(environment.imgUrl)
    ) {
      data[type + '_texture_low_quality'] = this.customImgResolution(data[type + '_texture'], 100);
      data[type + '_texture_low_quality'] = this.convertPathImage(data[type + '_texture_low_quality']);
      data[type + '_texture_original_quality'] = this.convertPathImage(data[type + '_texture']);
      data[type + '_texture'] = data[type + '_texture_original_quality'];
    }
    return artwork;
  }

  /**
   * ANCHOR Set Artwork Frames (Frame & Passepartout) Width
   * @description to set artworks frames (frame & passepartout) width
   * @param artwork : IArtwork
   * @param type : 'frame' | 'passepartout'
   */
  private _setArtworkFramesWidth(artwork: IArtwork, type: 'frame' | 'passepartout'): IArtwork {
    const data: any = artwork.frame[type];
    if (data[type + '_width']) {
      data[type + '_width_top'] = data[type + '_width'];
      data[type + '_width_bottom'] = data[type + '_width'];
      data[type + '_width_left'] = data[type + '_width'];
      data[type + '_width_right'] = data[type + '_width'];
    }
    return artwork;
  }

  /**
   * ANCHOR Re-arrange Animation Order
   * @description to re-arrange animation order
   */
  private _rearrangeAnimationOrder(): void {
    const artworks = this.mainService.artworks.sort((a: any, b: any) => {
      const prop = 'sequence';
      return a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1;
    });

    this.mainService.artworks = artworks.map((artwork: IArtwork, idx: number) => {
      artwork.sequence = idx + 1;
      return artwork;
    });
  }

  /**
   * ANCHOR Get Artwork Model Path
   * @description to getting artwork model path
   * @param artwork : Artwork
   * @returns : string
   */
  private _getModelPath(artwork: IArtwork): string {
    const path = artwork.model_path;
    if (path && !path.includes(environment.assetsUrl)) return environment.assetsUrl + path;
    return path || '';
  }

  /**
   * ANCHOR Get Video Type
   * @description to get video type
   * @param url : string -> video url
   * @returns : 'youtube' | 'vimeo'
   */
  private _getVideoType(url: string): 'youtube' | 'vimeo' | null {
    if (url.includes('youtube')) return 'youtube';
    if (url.includes('vimeo')) return 'vimeo';
    return null;
  }

  //#endregion
  //!SECTION

  /**
  * * ================================================================================================ *
  *   SECTION Get Text Data From Exhibition Data
  * * ================================================================================================ *
  */
  //#region

  /**
   * ANCHOR Get Texts Data From Exhibition Data
   * @description to getting texts data from exhibition data
   */
  private _getTextsDataFromExhibitionData() {
    const texts = this.mainService.exhibition.text_walls.filter((x:any)=>!x.deleted);
    this.mainService.texts = texts.map((text: TextWall) => {
      const pictureOri = text.picture;
      text.picture_initial_quality = this.convertPathImage(pictureOri + '?resize=50x50');
      text.picture_very_low_quality = this.convertPathImage(pictureOri + '?resize=500x500');
      text.picture_low_quality = this.convertPathImage(pictureOri + '?resize=700x700');
      text.picture_medium_quality = this.convertPathImage(pictureOri + '?resize=1024x1024');
      text.picture_high_quality = this.convertPathImage(pictureOri + '?resize=1024x1024');
      const deviceType = this.mainService.browserData.platform.type;
      switch (deviceType) {
        case 'mobile': text.picture = text.picture_low_quality; break;
        case 'tablet': text.picture = text.picture_medium_quality; break;
        case 'desktop': text.picture = text.picture_high_quality; break;
        default: text.picture = text.picture_low_quality; break;
      }
      return text;
    });
  }
  //#endregion
  //!SECTION
}
