<div class="wrap-message">
    <div 
        @fadeAnimation
        *ngIf="showMessage" 
        class="action-message" 
        [class.error]="type==='error'"
        [class.warning]="type==='warning'"
        [class.success]="type==='success'"
        [class.info]="type==='info'">
        <a href="javascript:;" class="close-icon" (click)="showMessage=false"><i class="vi vi-x"></i></a>
        <h5 class="title-message">
            {{title}}
        </h5>
        <p class="message">{{message}}</p>
        <a href="javascript:;"></a>
    </div>
</div>