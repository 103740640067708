import { Component, OnInit, PLATFORM_ID, afterNextRender, inject } from '@angular/core';
import { environment } from '@environments';
import { MainService } from './shared/services/main.service';
import { RouterOutlet } from '@angular/router'
import isMobile from 'is-mobile';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  standalone: true,
  imports: [
    RouterOutlet
  ]
})
export class AppComponent implements OnInit {
  public title: string = 'Villume Gallery';
  private _isPlatform = inject(PLATFORM_ID);

  constructor(
    private mainService: MainService
  ) {
    afterNextRender(() => {
      this.mainService.isBrowser = true;
      this.mainService.isMobile = isMobile({ ua: navigator.userAgent, tablet: true });
    });
    this.mainService.getLatestVersion();
  }

  ngOnInit(): void {
    if (this.mainService.isBrowser) {
      this._detectWhiteLabel();
      this._removeFavicon();

      setTimeout(() => {
        if (!this.mainService.exhibitionString) {
          window.location.href = environment.villumeAdminUrl;
        }
      }, 10);
    }
  }

 // detect domain to set white label
  private _detectWhiteLabel(): void {
    if (this.mainService.isBrowser) {
      let domain = window.location.origin.replace(/^https?:\/\//, '');

      if (
        domain === 'dev-gallery.villume.com' ||
        domain === 'qa-gallery.villume.com' ||
        domain === 'gallery.villume.com'||
        domain === 'localhost:4200'
      ) {
        this.mainService.whiteLabel = false;
      } else {
        const exhibitionString = window.location.pathname.split('/')[2];
        this.mainService.exhibitionString = exhibitionString;

        this.mainService.whiteLabel = true;
        this.mainService.validateWhiteLabelGallery(domain, this.mainService.exhibitionString).subscribe({
          next: (res) => {
            this.mainService.whiteLabel = true;
          },
          error: (err) => {
            window.location.href = '/'
          }
        });
      }
    }
  }

  //  change favicon to blank
  private _removeFavicon() {
    const link = document.querySelector('link[rel*=\'icon\'][type=\'image/x-icon\']') || document.createElement('link');
    link.setAttribute('type', 'image/x-icon');
    link.setAttribute('rel', 'icon');
    link.setAttribute('href', this.mainService.whiteLabel ? `${environment.staticAssets}images/other/favicon-blank.png?t=${this.mainService.appVersion}` : `${environment.staticAssets}images/other/favicon.ico?t=${this.mainService.appVersion}`);
    document.head.appendChild(link);
  }
}
