import { Injectable } from '@angular/core';
import { IMessageConfig } from '@interfaces/message-config';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private componentMethodCallSource = new Subject<any>();
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  constructor() {}

  /**
   * * SHOW MESSAGE *
   * Todo: to show message
   */
  add(config: IMessageConfig) {
    this.componentMethodCallSource.next(config);
  }
}
