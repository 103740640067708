import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { environment } from '@environments';
import { MainService } from '@services/main.service';
import { NgIf, NgClass, NgTemplateOutlet, NgStyle } from '@angular/common';
import { SplashScreen } from '@interfaces/splashscreen';

@Component({
    selector: 'app-splash-screen-preview',
    templateUrl: './splash-screen-preview.component.html',
    styleUrls: ['./splash-screen-preview.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, NgTemplateOutlet]
})
export class SplashScreenPreviewComponent implements OnChanges {
	@Input() options: any;
	@Input() lightTheme: boolean = false;
	@Input() preview: boolean = false;
	@Input() loading: boolean = false;
	
	@ViewChild('bgMobile') bgMobile: any = ElementRef; 
	@ViewChild('bgDesktop') bgDesktop: any = ElementRef;
	@ViewChild('desc') desc: any;
	@ViewChild('buttonEnter') buttonEnter: any = ElementRef;
	@ViewChild('loadingAnimation') loadingAnimation: any = ElementRef;
	@ViewChild('elHeaderMobile') elHeaderMobile: any = ElementRef;
	@ViewChild('elHeaderDesktop') elHeader: any = ElementRef;

	@Output() onHide: EventEmitter<boolean> = new EventEmitter();

	public env = environment;
	public colorTone: string = 'dark';
	private _resizeBgHandler: any;

	constructor(public mainService:MainService) {}

	ngOnChanges(changes:SimpleChanges) {
		if (this.mainService.isBrowser) {
			this._changeViewerTheme();
			this._setLoadingPosition();
			this._handleBlinkingText(false);
			
			if (this.options.active) {
				this._setupFontStyleHeader();
				this._setupFontStyleDesc();
				this._handleBlinkingText(true);
				this._setButtonPosition();
				this._setBackgroundImage();
				this._setBackgroundPositionMobile();
			} else if (changes['loading'] && !changes['loading'].currentValue) {
				this.onHide.emit(true);
			}

			this._resizeBgHandler = this.resizeBg.bind(this);
			window.addEventListener('resize', this._resizeBgHandler);
		}
	}

	ngOnDestroy() {
		if (this.mainService.isBrowser) {
			window.removeEventListener('resize', this._resizeBgHandler);
		}
	}

	resizeBg() {
		const zoom:number = this.mainService.isMobile ? this.options.zoom_mobile : this.options.zoom;
		this._setBackgroundSize(zoom);
	}

	/**
	 * ANCHOR Change the theme 
	 */
	private _changeViewerTheme() {
		if (this.options.active) this.colorTone = this.options.overlay_tone;
		else this.colorTone = this.lightTheme ? 'light':'dark';

	}

	/**
	 * ANCHOR SET BACKGROUND IMAGE
	 * Todo: to set background image
	 */
	private _setBackgroundImage() {
		setTimeout(() => {
			const zoom:number = this.mainService.isMobile ? this.options.zoom_mobile : this.options.zoom;
			this._setBackgroundSize(zoom);
			if (this.mainService.isMobile) {
				this.bgMobile.nativeElement.style.backgroundImage = `url(${this.options.image})`;
				this.bgMobile.nativeElement.style.backgroundPosition = this.options.background_position ? this.options.background_position:'auto 100%';
			} else {
				this.bgDesktop.nativeElement.style.backgroundImage = `url(${this.options.image})`;
				this.bgDesktop.nativeElement.style.backgroundPosition = 'center center';
			}
		}, 100);
	}

	/**
	 * ANCHOR SET BACKGROUND SIZE
	 */
	private _setBackgroundSize(value: number) {
		if (this.mainService.isMobile) {
			this.bgMobile.nativeElement.style.backgroundSize = `${((100 + value*2.5) * 3)+7}%`;
		} else if (this.bgDesktop) {
			const height = window.innerHeight;
			const width = window.innerWidth;
			const ratio = width / height;

			if (ratio <= 1.4) {
				this.bgDesktop.nativeElement.style.backgroundSize = `auto ${100 + value*1.5}%`;
			} else {
				this.bgDesktop.nativeElement.style.backgroundSize = `${100 + value*1.5}% auto`;
			}
		}
	}

	/**
	 * ANCHOR SET BACKGROUND POSITION
	 */
	private _setBackgroundPositionMobile() {
		if (this.mainService.isMobile && this.bgMobile) {
			this.bgMobile.nativeElement.style.backgroundPosition = this.options.background_position;
		} else if (this.bgDesktop) {
			this.bgDesktop.nativeElement.style.backgroundPosition = 'center center';
		}
	}

	private _adjustHeaderFontSize() {
    if (this.mainService.isBrowser && this.options.header) {
      let header, textLength;
      setTimeout(() => {
        if (this.mainService.isMobile) {
          header = this.elHeaderMobile.nativeElement;
          textLength = header.textContent.length;
        } else {
          header = this.elHeader.nativeElement;
          textLength = header.textContent.length;
        }

        if (textLength <= 40) {
          header.style.fontSize = this.mainService.isMobile?`${26}px`:`${70}px`;
          header.style.lineHeight = this.mainService.isMobile?`${28}px`:`${74}px`;
        }

        if (textLength > 40 && textLength <= 60) {
          header.style.fontSize = this.mainService.isMobile?`${24}px`:`${54}px`;
          header.style.lineHeight = this.mainService.isMobile?`${26}px`:`${57}px`;
        }

        if (textLength > 60 && textLength <= 80) {
          header.style.fontSize = this.mainService.isMobile?`${22}px`:`${42}px`;
          header.style.lineHeight = this.mainService.isMobile?`${24}px`:`${44}px`;
        }

        if (textLength > 80 && textLength <= 110) {
          header.style.fontSize = this.mainService.isMobile?`${19}px`:`${38}px`;
          header.style.lineHeight = this.mainService.isMobile?`${22}px`:`${40}px`;
        }

        if (textLength > 110) {
          header.style.fontSize = this.mainService.isMobile?`${16}px`:`${33}px`;
          header.style.lineHeight = this.mainService.isMobile?`${18}px`:`${34}px`;
				}

				this._setButtonPosition();
      }, 100);
    }
  }

	/**
	 * ANCHOR set loading and button position
	 */
	private _setLoadingPosition() {
		setTimeout(() => {
			let topDesc:number;
			let topLoading:number;
			let bottomDesc:number;
			let bottomLoading:number;
			
			if (this.desc?.nativeElement) {
				topDesc = bottomDesc = this.desc.nativeElement.offsetHeight + 80;
			} else return

			if (this.loadingAnimation?.nativeElement) {
				const Tloading = window.getComputedStyle(this.loadingAnimation.nativeElement).top;
				const Bloading = window.getComputedStyle(this.loadingAnimation.nativeElement).bottom;
				topLoading = Number(Tloading.slice(0,Tloading.length-2));
				bottomLoading = Number(Bloading.slice(0,Bloading.length-2));

				if (topDesc > topLoading && (this.options.text_placement===1||this.options.text_placement===4)) {
					this.loadingAnimation.nativeElement.style.top = String(topDesc+40)+'px';
					this.loadingAnimation.nativeElement.style.translate = '-50% 0%';
				}

				if (bottomDesc > bottomLoading && (this.options.text_placement===2||this.options.text_placement===5)) {
					this.loadingAnimation.nativeElement.style.top = 'auto';
					this.loadingAnimation.nativeElement.style.bottom = String(bottomDesc+40)+'px';
					this.loadingAnimation.nativeElement.style.translate = '-50% 0%';
				}
			}
		}, 120);
	}

	/**
	 * ANCHOR set button position
	 */
	private _setButtonPosition() {
		setTimeout(() => {
			let topButton:number;
			let topDesc:number;
			let bottomDesc:number;
			let bottomButton:number;
			
			if (this.desc?.nativeElement) {
				topDesc = bottomDesc = this.desc.nativeElement.offsetHeight + 100;
			} else return

			if (this.buttonEnter?.nativeElement) {
				const Tbutton = window.getComputedStyle(this.buttonEnter.nativeElement).top;
				const Bbutton = window.getComputedStyle(this.buttonEnter.nativeElement).bottom;
				topButton = Number(Tbutton.slice(0, Tbutton.length-2));
				bottomButton = Number(Bbutton.slice(0, Bbutton.length-2));

				if (topDesc > topButton && (this.options.text_placement===1|| this.options.text_placement===4)) {
					this.buttonEnter.nativeElement.style.top = String(topDesc + 40)+'px';
					this.buttonEnter.nativeElement.style.translate = '-50% 0%';
				}

				if (bottomDesc > bottomButton && (this.options.text_placement===2||this.options.text_placement===5)) {
					this.buttonEnter.nativeElement.style.top = 'auto';
					this.buttonEnter.nativeElement.style.bottom = String(bottomDesc + 40)+'px';
					this.buttonEnter.nativeElement.style.translate = '-50% 0%';
				}
			}

		},120);
	}

	/**
	 * ANCHOR handle blinking text
	 */
	private _handleBlinkingText(active:boolean = false): void {
		if (this.mainService.isBrowser) {
      setTimeout(() => {
				if (active) {
					this._setupFontStyleHeader();
					this._setupFontStyleDesc();

					setTimeout(() => {
						
						const textBody = document.querySelectorAll('.splash-text') as NodeListOf<HTMLElement>;
						const wrapContent = document.querySelector('.center.pos') as HTMLElement;
						const buttons = document.querySelectorAll('.btn') as NodeListOf<HTMLElement>;
						
						if (wrapContent) wrapContent.style.visibility = 'visible';
						textBody.forEach((el: HTMLElement) => {
							el.style.visibility = 'visible';
						});
						
						buttons.forEach((el: HTMLElement) => {
							el.style.visibility = this.loading ? 'hidden' : 'visible';
						});
					}, 50);
				}

				const loadings = document.querySelectorAll('.loading') as NodeListOf<HTMLElement>;
				loadings.forEach((el: HTMLElement) => {
					el.style.visibility = this.loading ? 'visible' : 'hidden';
				});
      }, 100);
    }
	}

	private _setupFontStyleHeader() {
		if (this.mainService.isBrowser) {
			const textHeader = document.querySelectorAll('.header.splash-text') as NodeListOf<HTMLElement>;
			this._fontStyle(textHeader, 'header');
		}
	}

	private _setupFontStyleDesc() {
		if (this.mainService.isBrowser) {
			const textDesc = document.querySelectorAll('.body.splash-text') as NodeListOf<HTMLElement>;
			this._fontStyle(textDesc, 'body');
		}
	}

	private _fontStyle(element:NodeListOf<HTMLElement>, type:string) {
		element.forEach((el: HTMLElement) => {
			el.style.fontSize = this.options[type + '_font_size'] + 'px';
			el.style.lineHeight = this.options[type + '_line_height'] + 'px';
			el.style.fontFamily = this.options[type + '_font_family'];
			switch (this.options[type + '_font_weight']) {
				case 'Regular':
					el.style.fontWeight = '400';
					el.style.fontStyle = 'normal';
					break;
				case 'Bold':
					el.style.fontWeight = '700';
					el.style.fontStyle = 'normal';
					break;
				case 'Italic':
					el.style.fontWeight = '400';
					el.style.fontStyle = 'italic';
					break;
				case 'Bold Italic':
					el.style.fontWeight = '700';
					el.style.fontStyle = 'italic';
					break;
			}
		})
	}
}