import { Injectable } from '@angular/core';
import { IArtwork } from '@interfaces/artwork';
import { IArtworkTextures, ILoadArtworkOptions, IReplaceArtworkTexturesOptions } from '@interfaces/artwork-loader';
import { ArtworkLoaderService } from './artwork-loader.service';
import { replace, set } from 'lodash';
declare const BABYLON: any;


@Injectable({
  providedIn: 'root'
})
export class ArtworkLoaderViewerService {

  constructor(private _artworkLoaderService: ArtworkLoaderService) {}

   /**
   * ANCHOR Create Artwork Image/Video For Viewer
   * @description to create artwork image/video for viewer
   * @param options : ILoadArtworkOptions
   */
  public async createArtworkImageVideo(options: ILoadArtworkOptions): Promise<any> {
    const artwork = options.artwork;
    this._changeTextureQuality(artwork, 'low');
    const artworkNode = this._artworkLoaderService.createArtworkImageVideo(options);
    this._changeTextureQuality(artwork, 'original');
    const materials = this._getMaterials(artworkNode);
    const mergedComponent = await this._mergeArtworkComponents(artworkNode.getChildren(), 'lowArtworkMerged');
    options.light.includedOnlyMeshes.push(mergedComponent);
    mergedComponent.actionManager = options.actionManager;
    mergedComponent.setParent(artworkNode);
    this._artworkLoaderService.setTransform(artworkNode, options.artwork);

    setTimeout(async () => {
      const textures: any = await this._loadOriginalQualityTextures(artwork, options.scene);
      Object.keys(materials).forEach((key: string) => {
        materials[key].diffuseTexture?.dispose();
        materials[key].diffuseTexture = textures[key];
      })
      mergedComponent.name = 'originalArtworkMerged';
      if(artwork.file_type === 'video') this._playbackControler(mergedComponent, textures['imageVideo'].video);
      // await this._replaceWhenCameraIsClose({ mergedComponent, materials, artwork, scene: options.scene});
    }, 3000)

    return artworkNode;
  }

  /**
   * ANCHOR Replace Low Quality Artwork With Original Quality Artwork
   * @description to replace low quality artwork with original quality artwork when camera is close
   * @param mergedComponent : BABYLON.Mesh -> low quality artwork components (merged)
   * @param materials : any -> low quality artwork materials
   * @param textures : IArtworkTextures -> original quality artwork textures
   */
  private _replaceWhenCameraIsClose(options: IReplaceArtworkTexturesOptions): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const { mergedComponent, materials, artwork, scene } = options;
      try {
        mergedComponent.addLODLevel();
        mergedComponent.onLODLevelSelection = async (distance: number) => {
          if(distance < 10) {
            mergedComponent.removeLODLevel();
            const textures: any = await this._loadOriginalQualityTextures(artwork, scene);
            Object.keys(materials).forEach((key: string) => {
              materials[key].diffuseTexture?.dispose();
              materials[key].diffuseTexture = textures[key];
            })
            mergedComponent.name = 'originalArtworkMerged';
            if(artwork.file_type === 'video') this._playbackControler(mergedComponent, textures['imageVideo'].video);
            resolve();
          }
        }
      } catch (error) {
        reject(error);
      }
    })
  } 

  /**
   * ANCHOR Playback Controler
   * @description to control playback of video
   * @param artworkMesh : BABYLON.Mesh
   */
  private _playbackControler(mergedComponent: any, video: any) {
    const camera = mergedComponent.getScene().activeCamera;
    mergedComponent.addLODLevel();
    mergedComponent.onLODLevelSelection = (distance: number) => {
      if (distance < 10 && camera.isInFrustum(mergedComponent)) {
        if (video.paused) video.play();
      } else {
        if (!video.paused) video.pause();
      }
    };
  }

  /**
   * ANCHOR Change Texture Source Quality
   * @description to change texture source quality
   * @param artwork : IArtwork
   */
  private _changeTextureQuality(artwork: IArtwork, quality: 'original' | 'low') {
    if (quality === 'low') {
      artwork.image = artwork.image_low_quality as string;
      artwork.frame.frame.frame_texture = artwork.frame.frame.frame_texture_low_quality as string;
      artwork.frame.passepartout.passepartout_texture = artwork.frame.passepartout.passepartout_texture_low_quality as string;
    } else {
      artwork.image = artwork.image_original_quality as string;
      artwork.frame.frame.frame_texture = artwork.frame.frame.frame_texture_original_quality as string;
      artwork.frame.passepartout.passepartout_texture = artwork.frame.passepartout.passepartout_texture_original_quality as string;
    }
  }

  /**
   * ANCHOR Merge Artwork Components
   * @description to merge artwork components
   * @param artworkComponents : BABYLON.Mesh[]
   */
  private _mergeArtworkComponents(artworkComponents: any[], name: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const meshes = artworkComponents.filter((mesh: any) => {
          return mesh.name !== 'imageVideo'
        })
        const merged: any = BABYLON.Mesh.MergeMeshes(meshes, true, true, undefined, false, true);
        merged.name = name;
        merged.onMeshReadyObservable.addOnce(() =>{
          resolve(merged)
        });
      } catch (error) {
        reject(error);
      }
    })
  }

  /**
   * ANCHOR Load Original Quality Textures
   * @description to load original quality textures
   * @param artwork: IArtwork
   * @param scene: BABYLON.Scene
   * @returns Promise<IArtworkTextures
   */
  private _loadOriginalQualityTextures(artwork: IArtwork, scene: any): Promise<IArtworkTextures> {
    return new Promise((resolve, reject) => {
      try {
        const { image } = artwork;
        const { frame_texture } = artwork.frame.frame;
        const { passepartout_texture } = artwork.frame.passepartout;
        const textures: any = {};
        if(artwork.file_type !== 'video') textures['imageVideo'] = new BABYLON.Texture(image, scene);
        else textures['imageVideo'] = this._artworkLoaderService.createTextureVideo(artwork.video_stream as string, scene);

        if(frame_texture)  textures['frame'] = new BABYLON.Texture(frame_texture, scene);
        if(passepartout_texture) textures['passepartout'] = new BABYLON.Texture(passepartout_texture, scene);

        const texturesArray = Object.keys(textures).map(key => textures[key]);
        BABYLON.Texture.WhenAllReady(texturesArray, () => { resolve(textures) });
      } catch (error) {
        reject(error);
      }
    })
  }

  /**
   * ANCHOR Get Materials
   * @description to get materials
   * @param artworkNode 
   */
  private _getMaterials(artworkNode: any): any {
    const materials: any = {};
    artworkNode.getChildMeshes().forEach((child: any) => {
      if(child.material) {
        materials[child.name] = child.material;
      }
    })
    return materials
  }
}
