import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';

import { environment } from '@environments';
import { MessageService } from '../../components/message/message.service';
import { ScriptService } from 'ngx-script-loader';

import { Observable, timeout } from 'rxjs';
import { debounce } from 'lodash';
import { IArtwork, IArtworkRequest } from '@interfaces/artwork';
import { ElasticsearchService } from './elasticsearch.service';
import { ArtworkLoaderViewerService } from './artwork-loader-viewer.service';
import { ArtworkShadowsService } from './artwork-shadows.service';
import { Base64 } from 'js-base64';
import { TextWall } from '@interfaces/text-wall';
import { TextLoaderService } from './text-loader.service';
import { TtsService } from './tts.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

declare const BABYLON: any;

@Injectable({
  providedIn: 'root',
})
export class MainService {
  public villumeAdminUrl = environment.villumeAdminUrl;
  public isBrowser: boolean = false;
  public whiteLabel: boolean = false;
  public textWallOri: boolean = false;

  // Artwork
  public artworksDonut:any = [];
  public activeArtworkId: number = 0;
  public artworks: any = [];
  public artworksNodes:any = [];
  public activeArtwork:any;
  public activeArtworkMesh:any;
  public activeArtworkDonut:any;
  public artworkLoadingPercentage: number = 0;
  public showDetail: boolean = false;
  public allShadowArtworkHasInitialized: boolean = false;
  public allDonutArtworkHasInitialized: boolean = false;
  public requestURL: string = '';
  public showDetailArtwork: boolean = false;
  public correctedArtworkPosition: any = [];
  public loadedArtworks: any = [];


  // Exhibition
  public model_size:number = 0;
  public exhibition: any = {};
  public exhibitionString: string = '';
  public exhibitionMesh: any = {};
  public otherExhibitions: any = [];
  public exhibitionLoadingPercentage: number = 0;
  public websiteUrl: string = '';
  private _folderName: string = '';

  // Camera
  public cameraFov:any = null;
  public camera: any = {};
  public cameraStartPos: any = {};
  public cameraStartRot: any = {};
  public isMoveCamera: boolean = false;
  public cameraUrlPos: any = null;
  public cameraUrlRot: any = null;
  public onFloatingCamera: boolean = false;
  private _lastPosition: any = null;


  // Core
  public canvas: any = null;
  public engine: any = {};
  public scene: any = {};

  // Hover
  public hoverGUI:any;
  public hoversArtworkGUI: any = [];

  // Room tour
  public pause: boolean = false;
  public runRoomTour: boolean = false;
  public sequence: number = 0;

  // exhibition time
  public published: boolean = false;
  public ended: string = '';
  public unlimited_time: boolean = false;
  public started: string = '';

  // Ordinary object
  public ordinaryObjectNodes:any = [];
  public ordinaryObjects:any = [];
  public ordinaryObjectLoadingPercentage: number = 0;

  // Text wall
  public texts:any = [];
  public textNodes:any = [];
  public textLoadingPercentage : number = 0;
  public textWallQuality: number = 4;

  // General
  public donutAreaMeshes:any = [];
  public stairsMeshes:any = [];
  public dragAreaMeshes:any = [];
  public colisionInvisibleMeshes:any = [];
  public pointerMesh:any;
  public loadingProgress: number = 0;
  public onLoadingData: boolean = true;
  public animationIsRun: boolean = false;
  public userInfo: any = {};
  public isIOS: boolean = false;
  public actionManager: any;
  public lightMode: boolean = false;
  public isMobile: boolean = false;
  public glowEffect:any;
  public observers:any = {};
  public allAssetsHasLoaded: boolean = false;
  public sideContent: string = 'about-exhibition';
  public openSideContent: boolean = false;
  public hiddenSideContent: boolean = false;
  public onTheStairs: boolean = false;
  public otherExhibitionMeshes: any = [];
  public exhibitionQuality: string = '';
  public onTesting: boolean = false;
  public quality: string = 'low';
  public FPS: number = 0;
  public detectFPSDown: boolean = false;
  public fpsDown: boolean = false;
  public qualityHasChoosed: boolean = false;
  public galleryVersion: number = Date.now();
  public gravity: any;
  public reloadTest: boolean = false;
  public showNotSubsDialog: boolean = false;
  public browserData: any = {};
  public appVersion: string = '-';

  // Lightings
  // public mainLight:any = null;
  public mainLightArtwork:any = null;
  public debuglogs: any = [];

  // media support player
  public audioSupport: any;
  public loadedAudio: boolean = false;
  public durationAudio: string = '';
  private _timelineInterval: any;
  private _progressBarInterval: any;
  public timerAudio: string = '00:00';
  public showVideoDetail: boolean = false;
  public showAudioDetail: boolean = false;
  public videoSupport: string|null = '';

  constructor(
    private _http: HttpClient,
    private _messageService: MessageService,
    private _scriptService: ScriptService,
    private _elasticsearchService: ElasticsearchService,
    private _cookieService: SsrCookieService,
    private _artworkLoaderService: ArtworkLoaderViewerService,
    private _artworkShadowsService: ArtworkShadowsService,
    private _textLoaderService: TextLoaderService,
    private _ttsService: TtsService,
  ) {}

  /**
   * * ================================================================================ *
   * * SECTIONS LIST
   * * ================================================================================ *
   * - SHADOW ARTWORK FUNCTIONS
   * - LOAD EXHIBITION MODEL FUNCTIONS
   * - CREATE SHADOW IN ARTWORK COMPONENTS FUNCTIONS
   * - CREATE ARTWORK IMAGE FUNCTIONS
   * - CORRECTING ARTWORK IMAGE POSITION FUNCTIONS
   * - 'FOCUS ON ARTWORK' ANIMATION FUNCTIONS
   * - ZOOM ON FOCUSED ARTWORK FUNCTIONS
   * - UTILITIES FUNCTIONS
   */










  /**
   * * ================================================================================ *
   * * SHADOW ARTWORK FUNCTIONS
   * * ================================================================================ *
   * - COMPARE SHADOW ROTATION AND ARTWORK ROTATION
   * - SET SIZE AND POSITION SHADOW ARTWORK
   * - GET RAYCAST SHADOW ARTWORK
   * - RESIZE SHADOW ARTWORK
   * - CREATE SHADOW ARTWORK
   * - SET SHADOW POSITION
   * - SET CAMERA DETECTOR POSITION
   * - CREATE SHADOW MATERIAL USING DYNAMIC MATERIAL
   * - CREATE SHADOW MATERIAL USING IMAGE
   */

  /**
   * * CREATE SHADOW ARTWORK *
   * Todo: to generate shadow artwork
   */
  createShadowArtwork(artworkNode:any) {
    if (!document.hidden) {
      let shadow = this.scene.getMeshByName('shadowArtwork');

      if (!shadow) {
        shadow = BABYLON.MeshBuilder.CreatePlane('shadowArtwork', {}, this.scene);
        shadow.material = this.createShadowMaterialUsingImage();
        shadow.id = 'shadow-'+artworkNode.id;
        shadow.isPickable = false;
      } else {
        shadow = shadow.clone('shadowArtwork');
        shadow.id = 'shadow-'+artworkNode.id;
      }

      shadow.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;
      shadow.occlusionQueryAlgorithmType = BABYLON.AbstractMesh.OCCLUSION_ALGORITHM_TYPE_CONSERVATIVE;
      shadow.occlusionType = BABYLON.AbstractMesh.OCCLUSION_TYPE_OPTIMISTIC;
      shadow.visibility = 0.7;
      shadow.setEnabled(false);
      shadow.addLODLevel(7, null);

      artworkNode['shadow'] = shadow;
      this.setShadowSizeAndPosition(artworkNode);

      artworkNode['shadowHasInitialized'] = true;
    }
  }

  /**
   * * SET SIZE AND POSITION SHADOW ARTWORK *
   * Todo: to set size and position shadow artwork
   */
  setShadowSizeAndPosition(artworkNode: any) {
    // Resize shadow artwork based on witdh and height artwork node
    this.resizeShadowArtwork(artworkNode);

    // Get camera raycast
    const ray = this.getRaycastShadowArtwork(artworkNode);

    // this timeout function  is used to wait for data from camra ray to be completely updated
    setTimeout(()=>{
      // Get the first hit got by camera ray, camera ray will only hit the drag area
      const hit = ray.intersectsMeshes(this.dragAreaMeshes)[0];

      // Get shadow artwork form artwork node
      const shadowArtwork = artworkNode.shadow;

      // if the hit that the camera ray gets is exists
      if (hit) {
        this.setShadowPosition(artworkNode, hit);

        // if the shadow artwork rotation is equal with artwork node rotation ,
        if (this.shadowRotationAndArtworkRotationIsSame(artworkNode)) {
          // Show shadow artwork
          shadowArtwork.setEnabled(true);
        } else {
          // Hide shadow artwork
          shadowArtwork.setEnabled(false);
        }
      } else {
        // if the hit that the camera ray gets is empty, the cause is camera doesn't hit the object drag area
        // Hide shadow artwork
        shadowArtwork.setEnabled(false);
      }
    }, 500);
  }

  /**
   * * COMPARE SHADOW ROTATION AND ARTWORK ROTATION *
   * Todo: to compare shadow rotation and artwork rotation
   */
  shadowRotationAndArtworkRotationIsSame(artworkNode: any) {
    // Get shadow artwork form artwork node
    const shadowArtwork = artworkNode.shadow;

    return shadowArtwork.rotation.x.toFixed(3)==artworkNode.rotation.x.toFixed(3)&&
          shadowArtwork.rotation.y.toFixed(3)==artworkNode.rotation.y.toFixed(3)&&
          shadowArtwork.rotation.z.toFixed(3)==artworkNode.rotation.z.toFixed(3);
  }

  /**
   * * SET SHADOW POSITION *
   * Todo: to set shadow position
   */
  setShadowPosition(artworkNode: any, hit: any) {
    // Get shadow artwork form artwork node
    const shadowArtwork = artworkNode.shadow;

    // Set the position of the shadow artwork parallel (a little lower) with the artwork node, but sticking to the wall (drag area)
    shadowArtwork.position = hit.pickedPoint.clone();
    shadowArtwork.position.y -= shadowArtwork.scaling.y*0.005;

    // Set the rotation of the shadow artwork the same as the rotation of the wall (drag area),
    shadowArtwork.setDirection(hit.getNormal(true, true));

    // recalculates the resulting rotation value of the 'setDirection' function so that the value is always between -Math Phi and Math Phi
    shadowArtwork.rotation.x = this.recalulateRotationNode(shadowArtwork.rotation.x);
    shadowArtwork.rotation.y = this.recalulateRotationNode(shadowArtwork.rotation.y);
    shadowArtwork.rotation.z = this.recalulateRotationNode(shadowArtwork.rotation.z);

    // Set the shadow artwork slightly forward from the wall (drag area)
    shadowArtwork.translate(new BABYLON.Vector3(0, 0, 0.005), 1, BABYLON.Space.LOCAL);
  }

  /**
   * * GET RAYCAST SHADOW ARTWORK *
   * Todo: to getting raycast for get position shadow artwork on the wall
   */
  getRaycastShadowArtwork(artworkNode: any) {
    // Get camera detector object
    const cameraDetector = this.scene.getCameraByID('cameraDetector');

    // Set camera detector
    this.setCameraDetectorPosition(cameraDetector, artworkNode);

    // Set camera rotation opposite to image object(mesh) in artwork node, this is to point camera raycast at the wall
    cameraDetector.rotation = new BABYLON.Vector3(
        artworkNode.rotation.x,
        artworkNode.rotation.y + Math.PI,
        artworkNode.rotation.z,
    );

    // Get camera raycast with a length of one point
    const cameraRay = cameraDetector.getForwardRay(0.02);

    return cameraRay;
  }

  /**
   * * SET CAMERA DETECTOR POSITION *
   * Todo: to set camera detector positio
   */
  setCameraDetectorPosition(cameraDetector: any, artworkNode: any) {
    // Set the camera detector position same as image object(mesh) in artwork node
    const marker = BABYLON.MeshBuilder.CreateSphere('sphere', { diameter: 0.2 }, this.scene);
    marker.position = artworkNode.position.clone();
    marker.rotation = artworkNode.rotation.clone();
    marker.translate(
        new BABYLON.Vector3(0, 0, -(artworkNode.scaling.z/2-0.01)),
        1/marker.scaling.z,
        BABYLON.Space.LOCAL,
    );

    cameraDetector.position = marker.position;
    marker.dispose();
  }

  /**
   * * RESIZE SHADOW ARTWORK *
   * Todo: to resize shadow artwork
   */
  resizeShadowArtwork(artworkNode: any) {
    const shadowArtwork = artworkNode.shadow;
    shadowArtwork.scaling.x = artworkNode.scaling.x+(artworkNode.scaling.x*0.32);
    shadowArtwork.scaling.y = artworkNode.scaling.y+(artworkNode.scaling.y*0.32);
  }

  /**
   * * CREATE SHADOW MATERIAL USING IMAGE *
   * Todo: to create shadow material using image
   */
  createShadowMaterialUsingImage() {
    const shadowMaterial = new BABYLON.StandardMaterial('shadowMat', this.scene);
    const shadowTexture = new BABYLON.Texture(environment.staticAssets+'images/other/shadow.png?t='+this.appVersion, this.scene);
    this._enableMipMap(shadowTexture);
    shadowMaterial.emissiveTexture = shadowTexture;
    shadowMaterial.emissiveTexture.hasAlpha = true;
    shadowMaterial.opacityTexture = shadowTexture;
    shadowMaterial.useAlphaFromDiffuseTexture = true;
    shadowMaterial.disableLighting = true;
    shadowMaterial.backFaceCulling = false;
    return shadowMaterial;
  }





  /**
  * * ================================================================================ *
  * * CREATE SHADOW IN ARTWORK COMPONENTS FUNCTIONS
  * * ================================================================================ *
  * - CREATE SHADOW IN ARTWORK COMPONENTS
  * - CREATE SHADOW MESH
  * - CREATE SHADOWS DATA FOR PASSEPARTOUT COMPONENT
  * - CREATE SHADOWS DATA FOR FRAME COMPONENT
  * - CREATE SHADOWS  DATA FOR BACK FRAME COMPONENT
  */

  /**
  * * CREATE SHADOW IN ARTWORK COMPONENTS *
  * Todo: to creating shadow in artwork components (such as frame, passepartout and back frame)
  */
  private _shadowArtworkComponentMeshMaster: any = null;
  createShadowForArtworkComponents(artworkData: IArtwork, artworkNode: any) {
    if (!this._shadowArtworkComponentMeshMaster) {
      this._shadowArtworkComponentMeshMaster = BABYLON.MeshBuilder.CreatePlane('shadowArtworkComponentMeshMaster', {
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      }, this.scene);
      const shadowArtworkComponentMaterial = new BABYLON.StandardMaterial('shadow', this.scene);
      shadowArtworkComponentMaterial.emissiveColor = new BABYLON.Color3(0, 0, 0);
      shadowArtworkComponentMaterial.disableLighting = true;
      this._shadowArtworkComponentMeshMaster.material = shadowArtworkComponentMaterial;
    }

    const artworkShadowsData: any = [];
    if (artworkData.frame.passepartout.passepartout) {
      const shadowDataPassepartout = this.createShadowsDataPassepartout(artworkData);
      artworkShadowsData.push(...shadowDataPassepartout);
    }

    if (artworkData.frame.frame.frame) {
      const shadowDataFrame = this.createShadowsDataFrame(artworkData);
      artworkShadowsData.push(...shadowDataFrame);
    }

    const backFrameShadow = this.createShadowsDataBackFrame(artworkData);
    artworkShadowsData.push(...backFrameShadow);

    const shadowMeshes: any = [];
    const artworkShadowsMaterials: any = [];
    artworkShadowsData.map((shadowData: any) => {
      const shadowMesh = this.createShadowMesh(shadowData);
      artworkShadowsMaterials.push(shadowMesh.material);
      shadowMeshes.push(shadowMesh);
    });

    const mergedShadows = this.mergedMeshes(shadowMeshes, 'artworkShadows');
    artworkShadowsMaterials.map((material: any) => material.dispose());
    mergedShadows.setParent(artworkNode);
    return mergedShadows;
  }

  /**
  * * CREATE SHADOW MESH *
  * Todo: create shadow mesh of each side in artwork component
  */
  createShadowMesh(data: any) {
    const shadow = this._shadowArtworkComponentMeshMaster.clone(data.name);
    shadow.rotation = new BABYLON.Vector3(...data.rotation);
    shadow.scaling = new BABYLON.Vector3(...data.scaling);
    shadow.position = new BABYLON.Vector3(...data.position);
    shadow.material = shadow.material.clone();
    shadow.material.alpha = data.alpha;
    return shadow;
  }

  /**
  * * CREATE SHADOWS DATA FOR PASSEPARTOUT COMPONENT *
  * Todo: to create shadow in passepartout component
  */
  createShadowsDataPassepartout(artworkData: any) {
    const passepartoutThickness = artworkData.frame.passepartout.passepartout_depth;
    const passepartoutWidth = artworkData.frame.passepartout.passepartout_width;
    const realHeight = artworkData.real_height;
    const realWidth = artworkData.real_width;
    const frameThickness = artworkData.frame.frame.frame_depth;
    const backFrameThickness = artworkData.frame.back_frame.back_frame_depth;
    const innerShadowWidth2 = frameThickness * 0.1 > passepartoutWidth ? frameThickness * 0.1 - passepartoutWidth : passepartoutThickness * 0.1;

    const shadowsData = [
      {
        name: 'innerLeft',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          passepartoutThickness,
          realHeight,
          1,
        ],
        alpha: 0.18,
        position: [
          realWidth / 2 - 0.0008,
          0,
          passepartoutThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'innerLeft2',
        rotation: [ 0, 0, 0 ],
        scaling: [
          innerShadowWidth2,
          realHeight - innerShadowWidth2,
          1,
        ],
        alpha: 0.2,
        position: [
          realWidth / 2 - innerShadowWidth2 / 2,
          -(innerShadowWidth2 / 2),
          backFrameThickness + 0.01,
        ],
      },
      {
        name: 'innerTop',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          passepartoutThickness,
          realWidth,
          1,
        ],
        alpha: 0.25,
        position: [
          0,
          realHeight / 2 - 0.0008,
          passepartoutThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'innerTop2',
        rotation: [ 0, 0, Math.PI /2 ],
        scaling: [
          innerShadowWidth2,
          realWidth,
          1,
        ],
        alpha: 0.2,
        position: [
          0,
          realHeight / 2 - innerShadowWidth2 / 2,
          backFrameThickness + 0.01,
        ],
      },
      {
        name: 'innerBottom',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          passepartoutThickness,
          realWidth,
          1,
        ],
        alpha: 0.1,
        position: [
          0,
          -(realHeight / 2 - 0.0008),
          passepartoutThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'innerRight',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          passepartoutThickness,
          realHeight,
          1,
        ],
        alpha: 0.09,
        position: [
          -(realWidth / 2 - 0.0008),
          0,
          passepartoutThickness / 2 + backFrameThickness ],
      },
      {
        name: 'outterRight',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          passepartoutThickness,
          realHeight + passepartoutWidth * 2,
          1,
        ],
        alpha: 0.18,
        position: [
          -(realWidth / 2 + passepartoutWidth + 0.0008),
          0,
          passepartoutThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'outterBottom',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          passepartoutThickness,
          realWidth + passepartoutWidth * 2,
          1,
        ],
        alpha: 0.25,
        position: [
          0,
          -(realHeight / 2 + passepartoutWidth + 0.0008),
          passepartoutThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'outterLeft',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          passepartoutThickness,
          realHeight + passepartoutWidth * 2,
          1,
        ],
        alpha: 0.05,
        position: [
          realWidth / 2 + passepartoutWidth + 0.0008,
          0,
          passepartoutThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'outterTop',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          passepartoutThickness,
          realWidth + passepartoutWidth * 2,
          1,
        ],
        alpha: 0.03,
        position: [
          0,
          realHeight / 2 + passepartoutWidth + 0.0008,
          passepartoutThickness / 2 + backFrameThickness,
        ],
      },
    ];

    return shadowsData;
  }

  /**
  * * CREATE SHADOWS DATA FOR FRAME COMPONENT *
  * Todo: to create shadow in frame component
  */
  createShadowsDataFrame(artworkData: any) {
    const frameThickness = artworkData.frame.frame.frame_depth;
    const frameWidth = artworkData.frame.frame.frame_width;
    const passepartoutWidth = artworkData.frame.passepartout.passepartout ? artworkData.frame.passepartout.passepartout_width : 0;
    const passepartoutThickness = artworkData.frame.passepartout.passepartout ? artworkData.frame.passepartout.passepartout_depth : 0;
    const realHeight = artworkData.real_height;
    const realWidth = artworkData.real_width;
    const backFrameThickness = artworkData.frame.back_frame.back_frame_depth;
    const innerShadowWidth2 = frameThickness * 0.1 > passepartoutWidth && passepartoutWidth > 0 ? passepartoutWidth : frameThickness * 0.1;
    const shadowsData = [
      {
        name: 'innerLeft2',
        rotation: [ 0, 0, 0 ],
        scaling: [
          innerShadowWidth2,
          realHeight + passepartoutWidth * 2 - innerShadowWidth2,
          1,
        ],
        alpha: 0.2,
        position: [
          realWidth / 2 + passepartoutWidth - innerShadowWidth2 / 2,
          -(innerShadowWidth2 / 2),
          passepartoutThickness + backFrameThickness + (passepartoutWidth > 0 ? 0.001 : 0.009),
        ],
      },
      {
        name: 'innerTop2',
        rotation: [ 0, 0, Math.PI /2 ],
        scaling: [
          innerShadowWidth2,
          realWidth + passepartoutWidth * 2,
          1,
        ],
        alpha: 0.2,
        position: [
          0,
          realHeight / 2 + passepartoutWidth - (innerShadowWidth2 / 2),
          passepartoutThickness + backFrameThickness + (passepartoutWidth > 0 ? 0.001 : 0.009),
        ],
      },
      {
        name: 'innerLeft',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          frameThickness,
          realHeight + passepartoutWidth * 2,
          1,
        ],
        alpha: 0.18,
        position: [
          realWidth / 2 + passepartoutWidth - 0.0008,
          0,
          frameThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'innerTop',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          frameThickness,
          realWidth + passepartoutWidth * 2,
          1,
        ],
        alpha: 0.25,
        position: [
          0,
          realHeight / 2 + passepartoutWidth - 0.0008,
          frameThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'innerBottom',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          frameThickness,
          realWidth + passepartoutWidth * 2,
          1,
        ],
        alpha: 0.1,
        position: [
          0,
          -(realHeight / 2 + passepartoutWidth - 0.0008),
          frameThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'innerRight',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          frameThickness,
          realHeight +
          passepartoutWidth * 2,
          1,
        ],
        alpha: 0.09,
        position: [
          -(realWidth / 2 + passepartoutWidth - 0.0008),
          0,
          frameThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'outterRight',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          frameThickness,
          realHeight + ( passepartoutWidth + frameWidth ) * 2,
          1,
        ],
        alpha: 0.18,
        position: [
          -(realWidth / 2 + passepartoutWidth + frameWidth + 0.0008),
          0,
          frameThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'outterBottom',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          frameThickness,
          realWidth + ( passepartoutWidth + frameWidth ) * 2,
          1,
        ],
        alpha: 0.25,
        position: [
          0,
          -(realHeight / 2 + passepartoutWidth + frameWidth + 0.0008),
          frameThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'outterLeft',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          frameThickness,
          realHeight + ( passepartoutWidth + frameWidth ) * 2,
          1,
        ],
        alpha: 0.05,
        position: [
          realWidth / 2 + passepartoutWidth + frameWidth + 0.0008,
          0,
          frameThickness / 2 + backFrameThickness,
        ],
      },
      {
        name: 'outterTop',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          frameThickness,
          realWidth + ( passepartoutWidth + frameWidth ) * 2,
          1,
        ],
        alpha: 0.03,
        position: [
          0,
          realHeight / 2 + passepartoutWidth + frameWidth + 0.0008,
          frameThickness / 2 + backFrameThickness,
        ],
      },
    ];

    if (passepartoutThickness >= frameThickness) {
      shadowsData.splice(0, 2);
    }
    return shadowsData;
  }

  /**
  * * CREATE SHADOWS  DATA FOR BACK FRAME COMPONENT *
  * Todo: to create shadow in back frame component
  */
  createShadowsDataBackFrame(artworkData: any) {
    const realHeight = artworkData.real_height;
    const realWidth = artworkData.real_width;
    const backFrameThickness = artworkData.frame.back_frame.back_frame_depth;
    const backFrameWidth = artworkData.frame.passepartout.passepartout ? artworkData.frame.passepartout.passepartout_width : 0;

    const shadowsData = [
      {
        name: 'outterRight',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          backFrameThickness,
          realHeight + backFrameWidth * 2,
          1,
        ],
        alpha: 0.18,
        position: [
          -(realWidth / 2 + backFrameWidth + 0.0008),
          0,
          backFrameThickness / 2,
        ],
      },
      {
        name: 'outterBottom',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          backFrameThickness,
          realWidth + backFrameWidth * 2,
          1,
        ],
        alpha: 0.25,
        position: [
          0,
          -(realHeight / 2 + backFrameWidth + 0.0008),
          backFrameThickness / 2,
        ],
      },
      {
        name: 'outterLeft',
        rotation: [ 0, Math.PI /2, 0 ],
        scaling: [
          backFrameThickness,
          realHeight + backFrameWidth * 2,
          1,
        ],
        alpha: 0.05,
        position: [
          realWidth / 2 + backFrameWidth + 0.0008,
          0,
          backFrameThickness / 2,
        ],
      },
      {
        name: 'outterTop',
        rotation: [ Math.PI /2, 0, Math.PI /2 ],
        scaling: [
          backFrameThickness,
          realWidth + backFrameWidth * 2,
          1,
        ],
        alpha: 0.03,
        position: [
          0,
          realHeight / 2 + backFrameWidth + 0.0008,
          backFrameThickness / 2,
        ],
      },
    ];

    return shadowsData;
  }








  /**
  * * ================================================================================ *
  * * CORRECTING ARTWORK IMAGE POSITION FUNCTIONS
  * * ================================================================================ *
  * - GET ARTWORK THICKNESS
  * - CORRECTING ARTWORK POSITION
  */

  /**
  * * GET ARTWORK THICKNESS *
  * Todo: to get artwork thickness
  */
  getArtworkThickness(frame: any) {
    const passeThickness = frame.passepartout.passepartout ? frame.passepartout.passepartout_depth : 0;
    const frameThickness = frame.frame.frame ? frame.frame.frame_depth : 0;
    const thickness = Math.max(passeThickness, frameThickness) + frame.back_frame.back_frame_depth;
    return thickness;
  }

  /**
  * * CORRECTING ARTWORK POSITION *
  * Todo: to correcting artwork position
  */
  correctingArtworkPosition(artworkNode: any, oldArtworkFrame: any) {
    const oldArtworkThickness = this.getArtworkThickness(oldArtworkFrame);
    const artworkThickness = artworkNode.scaling.z;
    const positionZ = artworkThickness > oldArtworkThickness ? (artworkThickness/2) - (oldArtworkThickness/2) : -((oldArtworkThickness/2) - (artworkThickness/2));
    artworkNode.translate(
        new BABYLON.Vector3(0, 0, positionZ), 1/artworkThickness, BABYLON.Space.LOCAL,
    );
  }

















  /**
  * * ================================================================================ *
  * * UTILITIES FUNCTIONS
  * * ================================================================================ *
  * - LOAD MUTLI SCRIPT
  * - LOAD TEXTURE
  * - UPPER FIST LETTER
  * - MERGED MESHES
  * - GET IMAGE WITH CUSTOM QUALTY
  * - GET NODE DIMENSION
  */

  /**
  * * LOAD MUTLI SCRIPT *
  * Todo: to load 3rd party scripts programmatically
  */
  async loadScripts(scriptPaths: string[]) {
    return new Promise((resolve, reject)=>{
      let itemIndex: number = 0;
      const loadScript = () => {
        if (scriptPaths[itemIndex]) {
          this._scriptService.loadScript(scriptPaths[itemIndex]).subscribe({
            next: (e:any) => {
              itemIndex++;
              loadScript();
            },
            error: (err:any) => reject(err)
          });
        } else {
          resolve('Scripts has loaded!');
        }
      };

      loadScript();
    });
  }

  /**
  * * LOAD TEXTURE *
  * Todo: to load babylon texture as promise
  */
  loadTexture(textureSource:any, forSceneEnv:boolean = false, scene:any = null) {
    return new Promise((resolve, reject)=>{
      scene = scene || this.scene;

      // handler function if the texture is already loaded into the scene
      const texureHasLoaded = (e:any) => resolve(texture);
      // handler function if failed to load texture
      const failedToLoadTexture = (err:any) => reject(err);

      // Initialize texture
      let texture:any = null;

      this.fetchTextureFile(textureSource).then((fileSource:any) => {
        if (forSceneEnv) {
          texture = new BABYLON.CubeTexture(fileSource, scene, null, false, null, texureHasLoaded, failedToLoadTexture, undefined, false, '.env');
        } else {
          texture = new BABYLON.Texture(fileSource, scene, false, true, 3, texureHasLoaded, failedToLoadTexture);
        }
        this._enableMipMap(texture);
      });
    });
  }

  /**
  * * FETCH TEXTURE FILE *
  * Todo: fetch texture file before render for babylon
  */
  private _texturePercent = 0;
  fetchTextureFile(url: string) {
    return new Promise((resolve, reject)=>{
      this._http.get(url, {
        responseType: 'arraybuffer',
        reportProgress: true,
        observe: 'events',
      }).subscribe({
        next: (event) => {
          if (event.type === HttpEventType.DownloadProgress) {
            const loaded = event.loaded;
            const total = event.total;

            if (total) {
              const percent =  Math.round(Math.round(loaded/total * 100) / 10);
              if (this._texturePercent !== percent) {
                this._texturePercent = percent;
                if (this.loadingProgress >= 90) this.loadingProgress = 90 + this._texturePercent;
                else if (this.loadingProgress >= 80) this.loadingProgress = 80 + this._texturePercent;
              }
            }
          } else if (event instanceof HttpResponse) {
            const arrayBuffer: ArrayBuffer | null = event.body;
            if (arrayBuffer) {
              const blob = new Blob([ arrayBuffer ]);
              const blobUrl = URL.createObjectURL(blob);
              resolve(blobUrl);
            }
          }
        },
        error: (err:any) => reject(err),
      });
    });
  }

  /**
  * * UPPER FIST LETTER *
  * Todo: Transform first letter from each word to upper case
  */
  toUpperFirstLetter(text:any) {
    text = text.toLowerCase().split(' ');
    text = text.map((s:any) => s.charAt(0).toUpperCase() + s.substring(1));
    return text.join(' ');
  }

  /**
  * * MERGED MESHES *
  * Todo: merged meshes
  */
  mergedMeshes(meshes: any, name: string = '') {
    const mergedMeshes: any = BABYLON.Mesh.MergeMeshes(meshes, true, true, undefined, false, true);
    mergedMeshes.name = name;
    return mergedMeshes;
  }

  /**
   * * GET IMAGE WITH CUSTOM RESOLUTION *
   * Todo: to get image with custom resolution
   * @param url: String -> url of the image (https://img.villume.com/xxxx)
   * @param type: 'customWidth' | 'customHeight' | 'maxDimension -> type of dimension
   * @param maxDimension: Number -> Maximum width/height limit
   * @param width: Number -> Target width
   * @param height: Number -> Target height
   */
  customImageResolution(
      params: {
        url: string,
        type: 'customWidth' | 'customHeight' | 'maxDimension',
        maxDimension?: number,
        width?: number,
        height?: number,
      },
  ) {
    const { url, type, width, height, maxDimension } = params;
    const oldResizeParam: string = url.split('?')[1].split('&')[0];
    const dimension = oldResizeParam.split('=')[1].split('x');
    const oldWidth = Number(dimension[1]);
    const oldHeight = Number(dimension[0]);
    const ratioYX = oldHeight / oldWidth;
    const ratioXY = oldWidth / oldHeight;
    let newWidth: number = 0;
    let newHeight: number = 0;

    switch (type) {
      // Resize image based on custom height
      case 'customHeight':
        if (!height) throw new Error('"height" is null, please insert the "height" value');
        newHeight = height;
        newWidth = newHeight * ratioXY;
        break;

      // Resize image based on custom width
      case 'customWidth':
        if (!width) throw new Error('"width" is null, please insert the "width" value');
        newWidth = width;
        newHeight = newWidth * ratioYX;
        break;

      // Resize image based on maximum width/height limit
      case 'maxDimension':
        if (!maxDimension) throw new Error('"maxDimension" is null, please insert the "maxDimension" value');
        if (oldHeight >= oldWidth && oldHeight > maxDimension) {
          newHeight = maxDimension;
          newWidth = newHeight * ratioXY;
          break;
        }
        if (oldWidth >= oldHeight && oldWidth > maxDimension) {
          newWidth = maxDimension;
          newHeight = newWidth * ratioYX;
          break;
        }

        newHeight = oldHeight;
        newWidth = oldWidth;
        break;
    }

    return url.replace(oldResizeParam, `resize=${Math.round(newHeight)}x${Math.round(newWidth)}`);
  }

  /**
   * * GET FOOTING POSITION *
   * Todo: to getting footing position using raycast
   * @param position: BABYLON.Vector3 -> The position of the place where you want to know the footing position.
   * @return BABYLON.Vector3 -> Footing position
   */
  getFootingPosition(position: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const predicate = (mesh: any) => {
          if ( mesh.name.toLowerCase().includes('collision')||
              mesh.name.toLowerCase().includes('floor') ||
              mesh.name.toLowerCase().includes('stairs') ||
              mesh.name.toLowerCase().includes('wall')) {
            return true;
          } else {
            return false;
          }
        };

        const nodeDetector = this.scene.getTransformNodeByName('nodeDetector');
        nodeDetector.position = position.clone();
        nodeDetector.rotation = BABYLON.Vector3.Zero();
        const ray = this.createRaycast({ node: nodeDetector, direction: 'bottom' });
        setTimeout(() => {
          const hit = this.scene.pickWithRay(ray, predicate);
          resolve(hit.pickedPoint);
        }, 10);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * * GET NODE DIMENSION *
   * Todo: to calculate the dimensions of the node such as height, width and depth of the model
   * @param node : BABYLON.TransformNode
   * @return { height: number, width: number, depth: number }
   */
  getNodeDimension(node: any) {
    const sizes = node.getHierarchyBoundingVectors();
    const size = {
      x: sizes.max.x - sizes.min.x,
      y: sizes.max.y - sizes.min.y,
      z: sizes.max.z - sizes.min.z,
    };

    return {
      height: size.y,
      width: size.x,
      depth: size.z,
    };
  }

















  /** ======================================================== //
  * * THE FUNCTIONS BELOW HAVE NOT BEEN GROUPED               *
  * ========================================================= //
  */


  /**
  * * APPLY CAMERA GRAVITY AND CONTROLS *
  * Todo: to apply camera gravity and camera controls
  */
  applyCameraGravityAndControls() {
    if (this.scene.activeCamera.name == 'floatingCamera') return;

    this.camera.attachControl(this.canvas, true);
    this.detectCameraAboveStairs();
    this.camera.applyGravity = true;

    this.canvas.onblur = () => {
      this.camera.detachControl(this.canvas);
    };
    this.canvas.onfocus = () => {
      if (this.activeArtworkId==0) this.camera.attachControl(this.canvas, false);
    };
  }

  /**
  * * CREATE GRAVITY FOR STAIRS IN BALCONY *
  */
  private _gravityStairs:any;
  customGravityForBalcony() {
    if (this._folderName == 'BALCONY-GALLERY') {
      this._gravityStairs = new BABYLON.Vector3(
          -0.0106,
          -0.05,
          0,
      );
    } else {
      this._gravityStairs = new BABYLON.Vector3(0, -0.05, 0);
    }
  }

  private _getDonutArtworkTexture() {
    let donutTexture = this.scene.getTextureByName('donutTexture');
    if (!donutTexture) {
      donutTexture = new BABYLON.Texture(environment.staticAssets+'images/other/rounded.png?t='+this.appVersion, this.scene);
      donutTexture.name = 'donutTexture';
      this._enableMipMap(donutTexture);
    }
    return donutTexture;
  }

  /**
  * * CREATE ARTWORK DONUT *
  * Todo: create artwork donut mesh
  */
  createArtworkDonut(artwork:any) {
    if (!document.hidden) {
      if (this.exhibition.show_donut) {
        let donut:any = this.scene.getMeshByName('artworkDonut');
        if (!donut) {
          donut = BABYLON.MeshBuilder.CreatePlane('artworkDonut', { sideOrientation: BABYLON.Mesh.DEFAULTSIDE }, this.scene);
          donut.scaling.x = this.exhibition.placeholder_size;
          donut.scaling.y = donut.scaling.x;
        } else {
          donut = donut.clone('artworkDonut');
        }

        const donutMat = new BABYLON.StandardMaterial('donutMat', this.scene);
        const donutTexture = this._getDonutArtworkTexture();
        donutMat.disableLighting = true;
        donutMat.backFaceCulling = false;
        donutMat.emissiveTexture = donutTexture;
        donutMat.opacityTexture = donutTexture;
        donutMat.emissiveTexture.hasAlpha = true;
        donutMat.useAlphaFromDiffuseTexture = true;
        donutMat.alphaMode = BABYLON.Engine.ALPHA_ADD;
        donutMat.alpha = 0.5;
        donut.material = donutMat;

        donut.id = `donut-${artwork.id}`;
        donut.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;
        donut.occlusionQueryAlgorithmType = BABYLON.AbstractMesh.OCCLUSION_ALGORITHM_TYPE_CONSERVATIVE;
        donut.occlusionType = BABYLON.AbstractMesh.OCCLUSION_TYPE_OPTIMISTIC;
        donut['artworkId'] = artwork.id;
        donut.position = artwork.position.clone();
        donut.rotation = artwork.rotation.clone();
        donut.translate(new BABYLON.Vector3(0, 0, 1.5), 1, BABYLON.Space.LOCAL);
        donut.setEnabled(false);

        this.artworksDonut.push(donut);

        setTimeout(()=>{
          const hit:any = new BABYLON.Ray(
              donut.position.clone(),
              new BABYLON.Vector3(0, -100, 0),
              10000,
          ).intersectsMeshes(this.donutAreaMeshes, true);

          if (hit.length) {
            donut.position.y = hit[0].pickedPoint.y+0.1;
            const target = hit[0].getNormal(true, true);
            target.x += hit[0].pickedPoint.x;
            target.y += hit[0].pickedPoint.y;
            target.z += hit[0].pickedPoint.z;
            donut.setEnabled(true);
            donut.lookAt(target);
          } else {
            donut.dispose();
          }
        }, 500);
      }

      artwork['donutHasInitialized'] = true;
    }
  }

  /**
  * * SET HORIZONTAL CAMERA MOVEMENT OBSERVERS *
  * Todo: to create/remove horizontal camera movement observers
  */
  setHorizontalCameraMovementObs(enable:any) {
    if (enable) {
      const rotation = 0;
      if (!this.observers['blockXRotation']) {
        this.observers['blockXRotation'] = this.scene.onBeforeRenderObservable.add(() => {
          this.camera.rotation.x = rotation;
        });
      }

      let prevScreenY:any = null;
      if (!this.observers['moveBasedOnPointerMovement']) {
        this.observers['moveBasedOnPointerMovement'] = this.scene.onPointerObservable.add((eventData:any) => {
          if (eventData.event.buttons === 1) {
            const panningSensibility = 2000;
            const evt = eventData.event;

            let offsetY = 0;
            if (this.isIOS) {
              if (!prevScreenY) prevScreenY = evt.screenY;
              offsetY = evt.screenY - prevScreenY;
              prevScreenY = evt.screenY;
            } else {
              offsetY = evt.movementY || evt.mozMovementY || evt.webkitMovementY || evt.msMovementY || 0;
            }

            this.camera.cameraDirection.addInPlace(this.camera.getDirection(BABYLON.Vector3.Forward()).scale(-(offsetY / panningSensibility)));
          } else {
            prevScreenY = null;
          }
        }, BABYLON.PointerEventTypes.POINTERMOVE);
      }

      if (!this.observers['moveBasedOnPointerUp']) {
        this.observers['moveBasedOnPointerUp'] = this.scene.onPointerObservable.add((eventData:any) => {
          prevScreenY = null;
        }, BABYLON.PointerEventTypes.POINTERUP);
      }
    } else {
      this.scene.onBeforeRenderObservable.remove(this.observers['blockXRotation']);
      this.scene.onPointerObservable.remove(this.observers['moveBasedOnPointerMovement']);
      this.scene.onPointerObservable.remove(this.observers['moveBasedOnPointerUp']);
      this.observers['moveBasedOnPointerMovement'] = null;
      this.observers['moveBasedOnPointerUp'] = null;
      this.observers['blockXRotation'] =  null;
    }
  }

  /**
  * * RENDER SCENE *
  * Todo: to render the active scene, but if previously there was an active scene, the scene will be stopped and replaced by a new scene
  */
  renderScene() {
    const renderer = () =>{
      // this._detectPerformanceDrop(); // temporary disabled
      this.scene.render();
    };

    this._registerDetectingFPSDrop();
    this.engine.runRenderLoop(renderer);
    window.addEventListener('resize', () => {
      this.engine.resize();
    });

    screen.orientation.addEventListener('change', (e) => {
      setTimeout(()=>{
        this.engine.resize();
      }, 10);
    });
  }

  /**
  * * GET EXHIBITION DATA *
  * Todo: for retrive a exhibition data
  * @param id :Number -> ID of Exhibition
  */
  getExhibition(id:string) {
    const options = {
      headers: new HttpHeaders({
        'accept': 'application/json',
      }),
    };

    return this._http.get(`${environment.baseHost}/api/get-viewer-json/${id}`, options ).pipe(timeout(20000));
  }


  /**
  * * INIT SHADOW DETAIL ARTWORK *
  * Todo: to initialize detail artwork
  */
  shadowDetail() {
    setTimeout(() => {
    const onDetail = document.getElementById('on-detail');
    const detail = document.getElementById('detail');
    const overlay = document.getElementById('overlay-details');
    const detailArtwork = document.getElementById('detail-artwork');
      
      if (onDetail&&detail&&overlay&&detailArtwork) {
        if (detail.offsetHeight > onDetail.offsetHeight) {
          detailArtwork.classList.add('on-shadow');
          overlay.classList.add('on-shadow');
          onDetail.addEventListener('scroll', () => {
            if (onDetail.offsetHeight + onDetail.scrollTop >= onDetail.scrollHeight) {
              detailArtwork.classList.remove('on-shadow');
              overlay.classList.remove('on-shadow');
            } else {
              detailArtwork.classList.add('on-shadow');
              overlay.classList.add('on-shadow');
            }
          });
        } else {
          detailArtwork.classList.remove('on-shadow');
          overlay.classList.remove('on-shadow');
        }
      }
    }, 200);
  }

  /**
  * * INIT ENGINE *
  * Todo: to initialize the babylon engine
  */
  initEngine(canvas:any) {
    return new BABYLON.Engine(canvas, true, {
      preserveDrawingBuffer: true,
      stencil: true,
      disableWebGL2Support: false,
      adaptToDeviceRatio: this.isMobile,
    });
  }


  /**
  * * SET REQUEST URL *
  * Todo: for set request via url
  */
  public showRequestButton: boolean = true;
  setRequestUrl(reqArtwork: IArtworkRequest) {
    let url: any = '';
    if (reqArtwork.request_via_link&&reqArtwork.request_link_value) {
      const link = reqArtwork.request_link_value;
      url = (link.includes('https://'||link.includes('http://'))) ? link : 'https://'+link;
    } else if (reqArtwork.request_via_email&&reqArtwork.request_email_value) {
      url = `mailto:${reqArtwork.request_email_value}`;
    } else url = null;

    this.showRequestButton = url && reqArtwork.request_button ? true : false;
    return url;
  }

  /**
  * * REQUEST ARTWORK *
  * Todo: to request artwork
  */
  requestArtwork() {
    window.open(this.requestURL);
    if (this.isBrowser) this._elasticsearchService.sendToElasticsearch('req_button_figure', this.activeArtwork.id);
  }

  /**
   * * SELECT ARTWORK *
   * Todo: to select artwork
   */
  selectArtwork(id:any) {
    if (this.allAssetsHasLoaded) {
      if (this.onFloatingCamera) {
        this.changeCamera().then(() => {
          this.selectArtwork(id);
        });
        return;
      }

      if (id != this.activeArtwork?.id) {
        this.resetCameraZoomArtwork();
        this.showAudioDetail = false;
        this.showVideoDetail = false;
        this.videoSupport = null;
        this._stopAudio();
        this._ttsService.stopTextToSpeech();
      }
      setTimeout(() => {
        if (!this.runRoomTour && !this.animationIsRun && !this.unselectAnimationIsRunning) {
          // Get active artwork (node & data)
          this.activeArtwork = this.artworks.find((x:any)=>x.id==id);
          this.activeArtworkMesh = this.scene.getTransformNodeByID(`artwork-${id}`);

          // Set artwork sequence & request url
          this.sequence = this.activeArtwork.sequence;
          this.requestURL = this.setRequestUrl(this.activeArtwork.request_artwork);

          // Handle artwork info section
          if (!this.openSideContent) {
            this.showDetail = true;
            this.hiddenSideContentOnFocus();

            if (this.activeArtwork.av?.audio && !this.loadedAudio) {
              setTimeout(() => {
                this.showAudioDetail = true;
                this._initAudioPlayer(this.activeArtwork.av?.audio);
              }, 50);
            }

            if (this.activeArtwork.av?.video && !this.videoSupport) {
              setTimeout(() => {
                this.videoSupport = this.activeArtwork.av?.video;
                this.showVideoDetail = true;
              }, 50);
            }

            if (this.isBrowser) this.detailTooltip();
            if (this.isMobile) {
              this.showDetail = false;
              this.hiddenSideContent = true;
              if (this.audioSupport) this.audioSupport.pause();
            }
          }

          this._ttsService.initTextToSpeech(this.activeArtwork.tts_path);

          // Set shadow on artwork info section & hide hover artwork GUI
          this.shadowDetail();
          this.hideHoverArtwork();

          if (this.activeArtworkId != this.activeArtworkMesh.id) {
            // Block user events (pointer & keyboard)
            this.clearSceneObservables();

            // Run animation focus on artwork
            this._focusOnArtworkAnimation(this.activeArtworkMesh);
          }
        }
      }, 50);
    } else {
      this._messageService.add({ type: 'warning', title: 'Warning', detailMessage: 'Please wait until all assets have been loaded' });
    }
  }


  /**
  * * HIDE HOVER ARTWORK *
  * Todo: to hide hover artwork
  */
  hideHoverArtwork() {
    setTimeout(() => {
      this.hoversArtworkGUI.map((x:any)=>{
        if (x.alpha>0) {
          this.scene.beginAnimation(x, 3, 0, false);
        }
      });
    }, 100);
  }

  /**
  * * ANIMATION FOR ROOM TOUR *
  * Todo: to run auto animation room tour
  */
  private _animationRoomTour() {
    if (!this.runRoomTour && this.isBrowser) {
      this._elasticsearchService.sendToElasticsearch('autoplay');
    }
    this.camera.detachControl(this.canvas);
    this.activeArtwork = this.artworks.find((x:any)=>x.sequence==this.sequence);
    this.activeArtworkMesh = this.scene.getTransformNodeByID(`artwork-${this.activeArtwork.id}`);

    this.showDetailArtwork = true;
    this.runRoomTour = true;

    if (window.innerWidth > 425) {
      this.showDetail = true;
      this.hiddenSideContentOnFocus();
    }
    this.openSideContent = false;
    this.shadowDetail();

    this.clearSceneObservables();
    this._focusOnArtworkAnimation(this.activeArtworkMesh).then(() => {
      this._roomTourTimeout = setTimeout(() => {
        this.sequence++;
        this.roomTour('play');
      }, 2000);
    });
  }

  /**
  * * ROOM TOUR *
  * Todo: to run auto animation focus on artwork
  */
  private _roomTourTimeout: any = null;
  roomTour(action: 'play' | 'pause') {
    if (this.allAssetsHasLoaded) {
      if (this.artworks.length) {
        switch (action) {
          case 'play':
            this.cancelMovingCameraAnimation();
            this.resetCameraZoomArtwork();

            if (this.sequence == 0) this.sequence++;
            if (this.sequence > this.artworks.length) {
              this.sequence = 1;
              this.roomTour('play');
              return;
            }

            if (this.onFloatingCamera) {
              this.changeCamera().then(() => {
                this._animationRoomTour();
              });
            } else {
              this._animationRoomTour();
            }
            break;

          case 'pause':
            clearTimeout(this._roomTourTimeout);
            this.unselectArtwork();
            break;

          default: break;
        }
      }
    } else {
      this._messageService.add({ type: 'warning', title: 'Warning', detailMessage: 'Please wait until all assets have been loaded' });
    }
  }

  /**
  * * REGISTER KEYBOARD EVENT *
  * Todo: to register keyboard event
  */
  private _onMovingCamera = false;
  registerKeyboardEvent() {
    const keys = [
      65, // Key : 'A'
      83, // key : 'S'
      68, // Key : 'D'
      87, // Key : 'W'
      37, // Key : ArrowLeft
      40, // Key : ArrowBottom
      38, // Key : ArrowUP
      39, // Key : ArrowRight
      27,  // Key : Escape
    ];

    document.addEventListener('keydown', (e:any) =>{
      if (keys.includes(e.keyCode)) {
        this._onMovingCamera = true;
        clearTimeout(this._roomTourTimeout);
        if (this.activeArtworkMesh || this.animationIsRun) {
          setTimeout(() => {
            this.unselectArtwork();
          }, 100);
        }

        this.cancelMovingCameraAnimation();

        if (e.keyCode != 27) {
          this.canvas.focus();
          this.hideHoverArtwork();
        }
      }
    });

    document.addEventListener('keyup', (e:any) =>{
      if (keys.includes(e.keyCode)) {
        this._onMovingCamera = false;
      }
    });
  }

  /**
   * * MAKE CLIMBUP STAIRS MORE SMOOTH *
   * Todo: to make climbup stairs more smooth
   */
  makeClimbupStairsMoreSmooth(onStairs: boolean) {
    if (
      this.animationIsRun ||
      this.unselectAnimationIsRunning ||
      this.activeArtworkMesh ||
      this._movingAnimation
    ) {
      return;
    }

    if (this.camera.name == 'mainCamera') {
      if (onStairs) {
        this.scene.gravity = this._gravityStairs;
      } else {
        this.scene.gravity = this.gravity;
      }
    }
  }

  /**
  * * CREATE GUI FOR HOVER *
  * Todo: to creating gui for hover
  */
  createHoverFigureGUI(data:any, hoverNode:any) {
    if (!this.hoverGUI) this.hoverGUI = BABYLON.GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');
    this.hoverGUI.useInvalidateRectOptimization = false;

    const wrapHover:any = new BABYLON.GUI.StackPanel('wrap-hover-'+data.id);
    this.hoverGUI.addControl(wrapHover);
    wrapHover.alpha = 0;
    wrapHover.linkWithMesh(hoverNode);

    const animate = new BABYLON.Animation('hoverAnimation', 'alpha', 30, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

    const keys = [];
    keys.push({
      frame: 0,
      value: 0,
    });
    keys.push({
      frame: 10,
      value: 1,
    });

    animate.setKeys(keys);

    wrapHover['animations'] = [];
    wrapHover['animations'].push(animate);
    wrapHover['show'] = false;

    const configWrapGUI = (wraper:any) => {
      wraper.background = this.lightMode ? '#FBFBFB' : '#171717';
      wraper.alpha = 0.7;
      wraper.thickness = 0.5;
      wraper.width = '250px';
      wraper.adaptHeightToChildren = true;
      wrapHover.addControl(wraper);
    };

    const configTextGUI = (text:any, opts:any) => {
      opts.vAlign = opts.vAlign || 'TOP';
      opts.hAlign = opts.hAlign || 'LEFT';
      text.textWrapping = BABYLON.GUI.TextWrapping.WordWrap;
      text.resizeToFit = true;
      text.textVerticalAlignment = BABYLON.GUI.Control.VERTICAL_ALIGNMENT_TOP;
      if (opts.hAlign=='right') {
        text.textHorizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
      } else {
        text.textHorizontalAlignment = BABYLON.GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
      }
      text.paddingLeft = opts.pLeft || '12px';
      text.paddingRight = opts.pRight || '12px';
      text.paddingTop = opts.pTop || '12px';
      text.paddingBottom = opts.pBottom || '12px';
      text.fontSize = opts.fSize || 18;
      text.color = opts.fColor || '#ffffff';
      text.text = opts.content || '';
      opts.controler.addControl(text);

      if (!data.pricing && opts.content.includes(data.author)) {
        text.paddingBottom = '12px';
      }
    };

    // Artwork Name
    const wrapName = new BABYLON.GUI.Container('wrap-name');
    configWrapGUI(wrapName);

    const name = new BABYLON.GUI.TextBlock('name');
    configTextGUI(name, {
      controler: wrapName,
      fColor: this.lightMode ? '#000000' : '#ffffff',
      content: `${data.name}${data.year?', '+data.year: ''}`,
      pBottom: '0px',
    });

    // Artwork Author
    const wrapAuthor = new BABYLON.GUI.Container('wrap-author');
    configWrapGUI(wrapAuthor);

    const author = new BABYLON.GUI.TextBlock('author');
    configTextGUI(author, {
      controler: wrapAuthor,
      content: `by ${data.author}`,
      hAlign: 'right',
      fColor: this.lightMode ? '#292929' : '#e7e7e7',
      fSize: '14',
      pTop: '4px',
      pBottom: '0px',
    });

    // Artwork Pricing
    if (data.pricing) {
      const wrapPricing = new BABYLON.GUI.Container('wrap-pricing');
      configWrapGUI(wrapPricing);

      const pricing = new BABYLON.GUI.TextBlock('author');
      configTextGUI(pricing, {
        controler: wrapPricing,
        content: `${data.pricing_amount}`,
        fColor: this.lightMode ? '#292929' : '#e7e7e7',
        fSize: '14',
        pTop: '0px',
      });
    }


    this.hoversArtworkGUI.push(wrapHover);
    return wrapHover;
  }


  /**
  * * GET MESH DIMENSION *
  * Todo: to calculate the dimensions of the meshes such as height, width and depth of the meshes
  * @param mesh : BABYLON.TransformNode
  * @returns dimession of exhibition
  */
  getMeshDimension(mesh:any) {
    const sizes = mesh.getHierarchyBoundingVectors();
    const size = {
      x: sizes.max.x - sizes.min.x,
      y: sizes.max.y - sizes.min.y,
      z: sizes.max.z - sizes.min.z,
    };

    return {
      height: size.y,
      width: size.x,
      depth: size.z,
    };
  }

  public initActionManager() {
    this.actionManager = new BABYLON.ActionManager(this.scene);
  }


  /**
  * * CREATE HOVER ARTWORK *
  * Todo: to creating hover artwork GUI
  */
  createHovers() {
    this.artworks.map((x:any)=>{
      if (x.file_type!='ordinary-object') {
        const artworkMesh = this.scene.getTransformNodeByID('artwork-'+x.id);
        const hoverNode  = new BABYLON.TransformNode('hoverNode');
        hoverNode.id = 'hoverNode-'+x.id;
        hoverNode.position = artworkMesh.position.clone();
        hoverNode.position.y -= artworkMesh.scaling.y/2;

        const hoverdata = {
          id: x.id,
          name: x.name,
          year: x.year || '',
          author: this.userInfo.first_name+' '+this.userInfo.last_name,
          pricing_amount: x.pricing_amount,
          pricing: x.pricing,
        };
        artworkMesh['hoverGUI'] = this.createHoverFigureGUI(hoverdata, hoverNode);
      }
    });
    this.registerActionManagerHover();

    this.canvas.ontouchend = () => {
      this.hideHoverArtwork;
    };
  }

  /**
  * * REGISTER ACTION MANAGER HOVER *
  * Todo: to regist action manager for hover GUI
  */
  registerActionManagerHover() {
    this.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, (ev:any)=>{
          if (!this.activeArtworkMesh) {
            const artworkMesh = ev.meshUnderPointer.parent;
            artworkMesh['hoverGUI']['show'] = true;
            artworkMesh['hoverGUI'].linkOffsetXInPixels = 135;
            artworkMesh['hoverGUI'].linkOffsetYInPixels = (artworkMesh['hoverGUI'].heightInPixels/2)+10;
            this.animateHover();
          }
        }));
    this.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, (ev:any)=>{
          const artworkMesh = ev.meshUnderPointer.parent;
          artworkMesh['hoverGUI']['show'] = false;
          this.hideHoverArtwork();
        }));
  }

  /**
  * * ANIMATE HOVER *
  * Todo: to animating hover when show or hide
  */
  animateHover = debounce(()=>{
    this.hoversArtworkGUI.map((x:any)=>{
      if (!this.activeArtworkMesh && !this._onMovingCamera) {
        if (x.show) {
          if (x.alpha==0) {
            this.scene.beginAnimation(x, 0, 10, false);
          }
        } else {
          if (x.alpha==1) {
            this.scene.beginAnimation(x, 3, 0, false);
          }
        }
      } else {
        if (x.alpha==1) {
          this.scene.beginAnimation(x, 3, 0, false);
        }
      }
    });
  }, 1000);

  /**
  * * PREV NEXT ARTWORK *
  * Todo: to prev next artwork
  */
  prevNextArtwork = debounce((action: any)=>{
    if (this.allAssetsHasLoaded) {
      if (!this.runRoomTour) {
        let figure: any;
        this.showDetailArtwork = true;
        this.openSideContent = false;
        this.hiddenSideContent = false;
        if (this._movingAnimation) {
          this._movingAnimation.pause();
          this._movingAnimation = null;
        }
        switch (action) {
          case 'prev':
            this.sequence--;
            figure = this.artworks.find((x:any)=>x.sequence==this.sequence);
            if (!figure) {
              this.sequence = this.artworks.filter((x:any)=>x.file_type!='ordinary-object').length;
              figure = this.artworks.find((x:any)=>x.sequence==this.sequence);
            };
            break;

          case 'next':
            this.sequence++;
            figure = this.artworks.find((x:any)=>x.sequence==this.sequence);
            if (!figure) {
              this.sequence = 1;
              figure = this.artworks.find((x:any)=>x.sequence==this.sequence);
            };
            break;
        }

        this.selectArtwork(figure.id);
        if (this.isBrowser) this._elasticsearchService.sendToElasticsearch('next_prev_button');
        this._elasticsearchService.sendToElasticsearch('control_bar');
      }
    } else {
      this._messageService.add({ type: 'warning', title: 'Warning', detailMessage: 'Please wait until all assets have been loaded' });
    }
  }, 500);


  /**
  * * MOVE CAMERA BY POINTER CLICK *
  * Todo: moving the camera to the point clicked by the pointer only works for floors and stairs objects
  */
  private _movingAnimation: any;
  moveCamera(endPosition:any) {
    if (this.allAssetsHasLoaded) {
      if (!this.pointerIntesectWithWall && !this.activeArtworkMesh) {
        this.cancelMovingCameraAnimation();

        // Set Y position of end position to be same with camera height
        endPosition.y += this.camera.ellipsoid.y / (49.75124378109452/100);

        // Set total frame & frame per second moving camera animation
        const totalFrame = BABYLON.Vector3.Distance(this.camera.position, endPosition);
        const framePerSecond = 5;

        // Create handler when animation end
        const onEndAnimationHandler = () => {
          this.scene.gravity = this.gravity;
          this._movingAnimation = null;
        };

        // run animation
        this.scene.gravity = BABYLON.Vector3.Zero();
        this._movingAnimation = BABYLON.Animation.CreateAndStartAnimation(
            'moveCameraAnimation',
            this.camera,
            'position',
            framePerSecond,
            totalFrame,
            this.camera.position,
            endPosition,
            BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE,
            undefined,
            onEndAnimationHandler,
        );
      }
    } else {
      this._messageService.add({ type: 'warning', title: 'Warning', detailMessage: 'Please wait until all assets have been loaded' });
    }
  };

  /**
  * * CANCEL ANIMATION 'MOVING CAMERA BY CLICKING FLOOR' *
  * Todo: to cancel animation 'Moving Camera By Clickin Floor'
  */
  cancelMovingCameraAnimation() {
    if (this._movingAnimation) {
      this._movingAnimation.pause();
      this._movingAnimation = null;
      this.scene.gravity = this.gravity;
    }
  }

  /**
   * * CLEAR SCENE OBSERVABLES *
   * Todo: to clear scene observables
   */
  clearSceneObservables() {
    this.scene.onPointerObservable.clear();
    this.scene.onBeforeRenderObservable.clear();
    this.observers = {};
  }


  /**
   * * INIT MAIN POINER OBS *
   * Todo : for initialize main pointer observable
   */
  initMainPointerObs() {
    if (!this.observers['main']) {
      this.observers['main'] = this.scene.onPointerObservable.add((pointerInfo:any)=>{
        if (!this.onLoadingData) {
          switch (pointerInfo.type) {
            case BABYLON.PointerEventTypes.POINTERTAP:
              const mesh = pointerInfo?.pickInfo?.pickedMesh;

              if (
                this.scene.activeCamera.name === 'floatingCamera' &&
                (mesh?.name.toLowerCase().includes('donut_area') ||
                mesh?.name.toLowerCase().includes('floor') ||
                mesh?.name.toLowerCase().includes('stairs_invisible'))
              ) {
                this._switchToFreeCamera(pointerInfo.pickInfo.pickedPoint);
                return;
              }

              if (
                mesh?.name.toLowerCase().includes('donut_area') ||
                mesh?.name.toLowerCase().includes('floor') ||
                mesh?.name.toLowerCase().includes('stairs_invisible')
              ) {
                const hitsDonutStairsArea = pointerInfo.pickInfo.ray.intersectsMeshes([ ...this.donutAreaMeshes, ...this.stairsMeshes ], true);
                if (hitsDonutStairsArea.length > 0) {
                  this.pointerMesh.setEnabled(true);
                  this.pointerEffect(hitsDonutStairsArea[0]);
                  this.moveCamera(pointerInfo.pickInfo.pickedPoint);
                }
              }

              if (mesh?.name=='artworkDonut' && !this.isMoveCamera) {
                if (this.allAssetsHasLoaded) {
                  const artworkId = mesh?.artworkId.replace('artwork-', '');
                  this.openSideContent = false;
                  this.showDetailArtwork = true;
                  this.selectArtwork(artworkId);
                } else {
                  this._messageService.add({ type: 'warning', title: 'Warning', detailMessage: 'Please wait until all assets have been loaded' });
                }
              } else if (mesh?._parentNode && mesh?._parentNode.name=='artwork' && !this.isMoveCamera) {
                if (this.allAssetsHasLoaded) {
                  const artworkId = mesh?._parentNode.id.replace('artwork-', '');
                  this.showDetailArtwork = true;
                  this.openSideContent = false;
                  this.selectArtwork(artworkId);
                } else {
                  this._messageService.add({ type: 'warning', title: 'Warning', detailMessage: 'Please wait until all assets have been loaded' });
                }
              } else {
                this.unselectArtwork(); 
              }
              break;

            case BABYLON.PointerEventTypes.POINTERMOVE:
              const hitsArtworkDonut = pointerInfo.pickInfo.ray.intersectsMeshes(this.artworksDonut, true);
              if (!hitsArtworkDonut[0]) {
                if (this.activeArtworkDonut) {
                  this.activeArtworkDonut.material.alpha = 0.5;
                  this.activeArtworkDonut = null;
                }
                this._displayPointerOnDonutArea(pointerInfo.pickInfo.ray);
              } else {
                this.pointerMesh.setEnabled(false);
                this.activeArtworkDonut = hitsArtworkDonut[0].pickedMesh;
                this.activeArtworkDonut.material.alpha = 1;
              }

              if (this.scene.activeCamera.name == 'floatingCamera') {
                this.pointerMesh.setEnabled(false);
                this.pointerMesh.material.alpha = 0;
              }
              break;
          }
        }
      });
    }
  }

  /**
	 * ANCHOR Display Pointer On Donut Area
	 * @description to display pointer on donut area
	 * @param ray: BABYLON.Ray
	 */
	private _displayPointerOnDonutArea(ray: any) : void {
		this.pointerMesh.setEnabled(true);
		const hits = this.scene.multiPickWithRay(ray);
		hits.sort((a:any,b:any) => a.distance - b.distance)
		if(hits[0]) {
			const name = hits[0].pickedMesh.name.toLowerCase();
			if(name.includes("donut_area") || name.includes("stairs") || name.includes("floor")) this.pointerEffect(hits[0]);
			else this.pointerIntesectWithWall = true;
		}
	}


  private _switchToFreeCamera(position:any, fromButton:any = false): any {
    return new Promise((resolve, reject) => {
      this.animationIsRun = true;
      this.canvas.style.pointerEvents = 'none';
      this.scene.activeCamera.detachControl(this.canvas);
      this._swapCamera();

      const arcCamera = this.scene.getCameraByName('floatingCamera');
      const freeCamera = this.scene.getCameraByName('mainCamera');
      const ease = this._setEaseMode();

      if (!fromButton) position.y += this.exhibition.camera.position['position_y'];
      BABYLON.Animation.CreateAndStartAnimation('landingCameraAnimation_1', freeCamera, 'rotation.x', 15, 30, this.camera.rotation.x, 0, 0, ease);
      BABYLON.Animation.CreateAndStartAnimation('landingCameraAnimation_0', freeCamera, 'position', 15, 30, arcCamera.position, position, 0, ease, () => {
        this._setupCeilingExhibition();
        arcCamera.dispose();
        this.camera.attachControl(this.canvas);
        this.onFloatingCamera = false;
        this.animationIsRun = false;
        this.camera.checkCollisions = true;
        this.setHorizontalCameraMovementObs(this.exhibition.horizontal_view_movement);
        if (this.isMobile) this.setMobileFloatingCameraMovements(null, false);
        this.canvas.style.pointerEvents = 'auto';
        this._handleArtworkBlinkingBehindWall(this.onFloatingCamera);
        resolve(null);
      });
    });
  }

  /**
  * * POINTER EFFECT *
  * Todo: to display the pointer effect (white circle object) when the pointer moves
  */
  pointerEffect(pickInfo:any) {
    if (this.allAssetsHasLoaded) {
      this.setPointerEffectPoisition(pickInfo);
      this.setPointerEffectSize(pickInfo);
      this.detectIntesectPointerMesh();
      this.animatingPointerEffect();
    }
  }

  /**
  * * DETECT INTESECT POINTER MESH *
  * Todo: to detect intesect pointer mesh
  */
  public pointerIntesectWithWall: boolean = false;
  detectIntesectPointerMesh() {
    let intersectCount = 0;
    this.pointerMesh['rays'].map((ray: any) => {
      ray.origin = this.pointerMesh.position;
      ray.length = this.pointerMesh.scaling.x/2;
      const hit = ray.intersectsMeshes(this.colisionInvisibleMeshes, true);
      if (hit.length > 0) intersectCount++;
    });

    if (intersectCount > 0) {
      this.pointerMesh.material.alpha = 0.2;
      this.pointerIntesectWithWall = true;
    } else {
      this.pointerIntesectWithWall = false;
      this.pointerMesh.material.alpha = 1;
    }
  }

  /**
  * * ANIMATING POINTER EFFECT *
  * Todo: to animating pointer effect
  */
  private _pointerEffectAnimation: any = null;
  animatingPointerEffect() {
    this.scene.stopAnimation(this.pointerMesh, 'pointerEffectAnimation');
    this._pointerEffectAnimation = BABYLON.Animation.CreateAndStartAnimation('pointerEffectAnimation', this.pointerMesh.material, 'alpha', 7, 10, this.pointerMesh.material.alpha, 0, 0, undefined, () => {
      this.pointerMesh.material.alpha = 0;
      this._pointerEffectAnimation = null;
    });
  }

  /**
  * * SET POINTER EFFECT POSITION *
  * Todo: to set pointer effect position
  */
  setPointerEffectPoisition(pickInfo: any) {
    this.pointerMesh.position = pickInfo.pickedPoint;
    this.pointerMesh.position.y += 0.05;
    this.pointerMesh.setDirection(pickInfo.getNormal(true, true));
  }

  /**
  * * SET POINTER EFFECT SIZE *
  * Todo: to set pointer effect size
  */
  setPointerEffectSize(pickInfo: any) {
    if ((pickInfo.distance/30)<0.4) {
      this.pointerMesh.scaling.x = 0.4;
      this.pointerMesh.scaling.y = 0.4;
    } else {
      this.pointerMesh.scaling.x = pickInfo.distance/30;
      this.pointerMesh.scaling.y = pickInfo.distance/30;
    }
  }

  /**
  * * UNSELECT ARTWORK *
  * Todo: to unselect artwork
  */
  async unselectArtwork() {
    if (this.activeArtworkMesh && !this.unselectAnimationIsRunning) {
      // Force stop animation focus on artwork
      if (this.animationIsRun) this._cancelFocusOnArtworkAnimation();

      await this.unselectAnimation();


      this.activeArtworkId = 0;
      this.scene.gravity = this.gravity;
      this.scene.activeCamera.attachControl(this.canvas, true);
      this.showDetailArtwork = false;
      this.showDetail = false;
      this.hiddenSideContent = false;
      this.activeArtwork = {};
      this.activeArtworkMesh = null;
      this.setHorizontalCameraMovementObs(this.exhibition.horizontal_view_movement);
      if (this.audioSupport) this._stopAudio();
      this._ttsService.stopTextToSpeech();
      this.showVideoDetail = false;
      this.videoSupport = null;

      if (this.runRoomTour) {
        this.runRoomTour = false;
        clearTimeout(this._roomTourTimeout);
      }

      this.canvas.blur();
      this.canvas.focus();
    }
  }

  /**
   * * UNSELECT ANIMATION *
   */
  public unselectAnimationIsRunning: boolean = false;
  public unselectAnimation(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.activeArtworkMesh['focus']) {
        this.resetCameraZoomArtwork();
        this.unselectAnimationIsRunning = true;

        const tmpNode = BABYLON.MeshBuilder.CreateBox('tmpNode', {}, this.scene);
        tmpNode.position = this.camera.position.clone();
        tmpNode.rotation.y = this.camera.rotation.y;
        tmpNode.visibility = 0;
        tmpNode.isPickable = false;

        setTimeout(async () => {
          const ray = this.createRaycast({ node: tmpNode, direction: 'backward' });
          this._blockerAnimation.forEach((x: any) => x.isPickable = true);
          const { distance } = this.scene.pickWithRay(ray);
          this._blockerAnimation.forEach((x: any) => x.isPickable = false);

          let zTranslate = 0;
          if (distance - 0.4 >= 2) zTranslate = -2;
          else zTranslate = -(distance - 0.4);
          tmpNode.translate(
              new BABYLON.Vector3(0, 0, zTranslate ),
              1,
              BABYLON.Space.GLOBAL,
          );

          const endPosition = tmpNode.position.clone();
          endPosition.y = await this._getCameraYPosition(endPosition);
          tmpNode.dispose();

          this.activeArtworkMesh['focus'] = false;

          // Effect animation
          const totalFrame = zTranslate * -1;
          BABYLON.Animation.CreateAndStartAnimation('cameraFov', this.camera, 'fov', 100, 10, this.camera.fov, this.cameraFov, 0);
          BABYLON.Animation.CreateAndStartAnimation('cameraRotateZ', this.camera, 'rotation.x', 6, totalFrame, this.camera.rotation.x, 0, 0);
          BABYLON.Animation.CreateAndStartAnimation('cameraPosition', this.camera, 'position', 6, totalFrame, this.camera.position, endPosition, 0, null, () => {
            this.unselectAnimationIsRunning = false;
            this.canvas.blur();
            this.canvas.focus();
            resolve();
          });
        }, 100);
      } else {
        this.activeArtworkMesh['focus'] = false;
        resolve();
      }
    });
  }

  /**
   * * CREATE RAYCAST  *
   * Todo: to create raycast with direction following orientation mesh
   * @param params {
   *   mesh: BABYLON.Mesh | BABYLON.TransformNode => Mesh as a benchmark for the direction of the ray.
   *   direction: 'forward' | 'backward' | 'left' | 'right' | 'top' | 'bottom' | Vector3 => ray direction
   *   showRay?: boolean => for debuging
   *   length?: number => length of raycast
   * }
   */
  createRaycast(
      params: {
        node: any,
        direction: 'forward' | 'backward' | 'left' | 'right' | 'top' | 'bottom'| any,
        showRay?: boolean,
        length?: number
      },
  ) {
    const { node, direction, showRay, length } = params;
    const origin = node.position.clone();

    const orginMarker = BABYLON.MeshBuilder.CreateBox('originMarker', { size: 0.1 }, this.scene);
    orginMarker.position = origin;
    orginMarker.visibility = 0;

    let directionVec;
    switch (direction) {
      case 'forward': directionVec = new BABYLON.Vector3(0, 0, 100); break;
      case 'backward': directionVec = new BABYLON.Vector3(0, 0, -100); break;
      case 'left': directionVec = new BABYLON.Vector3(-100, 0, 0); break;
      case 'right': directionVec = new BABYLON.Vector3(100, 0, 0); break;
      case 'top': directionVec = new BABYLON.Vector3(0, 100, 0); break;
      case 'bottom': directionVec = new BABYLON.Vector3(0, -100, 0); break;
      default: directionVec = direction; break;
    }

    directionVec = this.vecToLocal(directionVec, typeof direction != 'string' ? orginMarker : node);
    directionVec = directionVec.subtract(origin);
    directionVec = BABYLON.Vector3.Normalize(directionVec);

    orginMarker.dispose();

    const ray = new BABYLON.Ray(origin, directionVec, length ? length : 100 );
    if (showRay) {
      const rayHelper = new BABYLON.RayHelper(ray);
      rayHelper.show(this.scene);
    }

    return ray;
  }


  /**
  * * CAMERA OUT OF ROOM HANDLER *
  * Todo: to initialize handler camer out of the room
  */
  cameraOutOfRoomHandler() {
    if (this.camera.position.y < -10) {
      this.camera.position = this.cameraStartPos.clone();
      this.camera.rotation = this.cameraStartRot.clone();
      this._messageService.add({ type: 'warning', title: 'Warning', detailMessage: 'Camera out of the room' });
    }
  }

  /**
  * * SETUP BASIC LIGHTING *
  * Todo: to setup basic lighting
  */
  setupBasicLigting() {
    this.mainLightArtwork = new BABYLON.HemisphericLight('mainLightArtwork', new BABYLON.Vector3(0, 0, 0), this.scene);
    this.mainLightArtwork.intensity = 1.85;
  }


  /**
  * * SETUP MAIN CAMERA *
  * Todo: create the main camera object and adjust its position based on the camera data in the database
  */
  setupCamera() {
    this.createCameraNode();
    this.setInitialCameraPosition();
    this.setupCollisionGravity();
    if (!this.isMobile) this.addCameraControl();
    this.saveInitialCameraPosition();
    this.setHorizontalCameraMovementObs(this.exhibition.horizontal_view_movement);
    this.setCameraFov();
    this.createCameraBottomRaycast();
  }

  /**
   * * CREATE CAMERA BOTTOM RAYCAST *
   * Todo: to create camera bottom raycast
   */
  createCameraBottomRaycast(): void {
    const nodeDetector = this.scene.getTransformNodeByName('nodeDetector');
    const cameraHeight = this.exhibition.camera.height_camera / (49.75124378109452/100);
    this.camera['bottomRay'] = this.createRaycast({ node: nodeDetector, direction: 'bottom', length: cameraHeight + 0.1 });
  }

  /**
  * * SET CAMERA FOV *
  * Todo: to setup camera fov for tablet device
  */
  setCameraFov() {
    if (window.innerWidth <= 767) {
      this.cameraFov = this.exhibition.mobile_fov;
    } else if (window.innerWidth <= 992) {
      this.cameraFov = this.exhibition.tablet_fov;
    } else if (window.innerWidth >= 993) {
      this.cameraFov = this.exhibition.desktop_fov;
    }
    this.camera.fov = this.cameraFov;

    window.addEventListener('resize', ()=>{
      if (window.innerWidth <= 767) {
        this.cameraFov = this.exhibition.mobile_fov;
      } else if (window.innerWidth <= 992) {
        this.cameraFov = this.exhibition.tablet_fov;
      } else if (window.innerWidth >= 993) {
        this.cameraFov = this.exhibition.desktop_fov;
      }
      this.camera.fov = this.cameraFov;
    });
  }

  /**
  * * SAVE INITAL CAMERA POSITION *
  * Todo: to save initial camera position
  */
  saveInitialCameraPosition() {
    this.cameraStartPos = this.camera.position.clone();
    this.cameraStartRot = this.camera.rotation.clone();
  }

  /**
  * * CREATE CAMERA NODE  *
  * Todo: to create camera node
  */
  createCameraNode() {
    this.camera = new BABYLON.FreeCamera('mainCamera', new BABYLON.Vector3(0, 0, -10), this.scene);
    this.camera.id = this.exhibition.id;
    this.camera.speed = this.exhibition.camera.movement_speed;
    this.camera.minZ = 0;
    this.camera.far = 500000;
    this.camera.angularSensibility = 3000;
    this.camera._needMoveForGravity = true;
    this.scene.activeCamera = this.camera;
  }

  /**
  * * SET INITIAL CAMERA POSITION *
  * Todo: to set initial camera position
  */
  setInitialCameraPosition() {
    if (this.cameraUrlPos && this.cameraUrlPos) {
      this.camera.position = new BABYLON.Vector3(
          this.cameraUrlPos.x,
          this.cameraUrlPos.y,
          this.cameraUrlPos.z,
      );
      this.camera.rotation = new BABYLON.Vector3(
          this.cameraUrlRot.x,
          this.cameraUrlRot.y,
          this.cameraUrlRot.z,
      );
    } else {
      this.camera.position = new BABYLON.Vector3(
          this.exhibition.camera.position.position_x,
          this.exhibition.camera.position.position_y,
          this.exhibition.camera.position.position_z,
      );
      this.camera.rotation = new BABYLON.Vector3(
          this.exhibition.camera.target.target_x,
          this.exhibition.camera.target.target_y,
          0,
      );
    }
  }

  /**
  * * ADD CUSTOM CAMERA CONTROLS *
  * Todo : removes built-in camera controls and creates new camera controls
  */
  addCameraControl() {
    // Remove default keyboard:
    this.camera = this.scene.activeCamera;
    this.camera.inputs.remove(this.camera.inputs.attached.keyboard);
    const cameraData = this.exhibition.camera;

    // Create Controls:
    const FreeCameraKeyboardRotateInput:any = function(this: any) {
      this._keys = [];
      this.keysUp = [ 87, 38 ];
      this.keysDown = [ 83, 40 ];
      this.keysLeft = [ 37, 65 ];
      this.keysRight = [ 39, 68 ];
      this.sensibility = cameraData.rotate_speed;
    };

    // Hooking keyboard events
    FreeCameraKeyboardRotateInput.prototype.attachControl = function(noPreventDefault:any) {
      const THIS = this;
      const engine = this.camera.getEngine();
      const element = engine.getInputElement();

      if (!this._onKeyDown) {
        element.tabIndex = 1;
        this._onKeyDown = function(evt:any) {
          if (THIS.keysLeft.indexOf(evt.keyCode) !== -1 ||
            THIS.keysRight.indexOf(evt.keyCode) !== -1 ||
            THIS.keysUp.indexOf(evt.keyCode) !== -1 ||
            THIS.keysDown.indexOf(evt.keyCode) !== -1) {
            const index = THIS._keys.indexOf(evt.keyCode);
            if (index === -1) {
              THIS._keys.push(evt.keyCode);
            }
            if (!noPreventDefault) {
              evt.preventDefault();
            }
          }
        };
        this._onKeyUp = function(evt:any) {
          if (THIS.keysLeft.indexOf(evt.keyCode) !== -1 ||
            THIS.keysRight.indexOf(evt.keyCode) !== -1 ||
            THIS.keysUp.indexOf(evt.keyCode) !== -1 ||
            THIS.keysDown.indexOf(evt.keyCode) !== -1) {
            const index = THIS._keys.indexOf(evt.keyCode);
            if (index >= 0) {
              this.executeUpstairsFunction = true;
              THIS._keys.splice(index, 1);
            }
            if (!noPreventDefault) {
              evt.preventDefault();
            }
          }
        };

        element.addEventListener('keydown', this._onKeyDown, false);
        element.addEventListener('keyup', this._onKeyUp, false);
      }
    };

    // Unhook
    FreeCameraKeyboardRotateInput.prototype.detachControl = function() {
      if (this._onKeyDown) {
        const engine = this.camera.getEngine();
        const element = engine.getInputElement();
        element.removeEventListener('keydown', this._onKeyDown);
        element.removeEventListener('keyup', this._onKeyUp);

        this._keys = [];
        this._onKeyDown = null;
        this._onKeyUp = null;
      }
    };

    // This function is called by the system on every frame
    FreeCameraKeyboardRotateInput.prototype.checkInputs = function() {
      if (this._onKeyDown) {
        const cam = this.camera;
        // Keyboard
        for (let index = 0; index < this._keys.length; index++) {
          const keyCode = this._keys[index];
          if (this.keysLeft.indexOf(keyCode) !== -1 && keyCode == 37) {
            cam.cameraRotation.y -= this.sensibility;
          } else if (this.keysRight.indexOf(keyCode) !== -1 && keyCode == 39) {
            cam.cameraRotation.y += this.sensibility;
          }
          if (this.keysLeft.indexOf(keyCode) !== -1 && keyCode == 65) {
            cam.cameraDirection.addInPlace(cam.getDirection(BABYLON.Vector3.Left()).scale(cam.speed / 25));
          }
          if (this.keysRight.indexOf(keyCode) !== -1 && keyCode == 68) {
            cam.cameraDirection.addInPlace(cam.getDirection(BABYLON.Vector3.Right()).scale(cam.speed / 25));
          } else if (this.keysUp.indexOf(keyCode) !== -1) {
            cam.cameraDirection.addInPlace(
                new BABYLON.Vector3(
                    Math.sin(cam.rotation.y),
                    0,
                    Math.cos(cam.rotation.y),
                ).scale(cam.speed/25),
            );
          } else if (this.keysDown.indexOf(keyCode) !== -1) {
            cam.cameraDirection.addInPlace(
                new BABYLON.Vector3(
                    -Math.sin(cam.rotation.y),
                    0,
                    -Math.cos(cam.rotation.y),
                ).scale(cam.speed/25),
            );
          }
        }
      }
    };

    FreeCameraKeyboardRotateInput.prototype.getTypeName = function() {
      return 'FreeCameraKeyboardRotateInput';
    };
    FreeCameraKeyboardRotateInput.prototype.getSimpleName = function() {
      return 'keyboardRotate';
    };

    // Add controls to camera
    this.camera.inputs.add(new FreeCameraKeyboardRotateInput());
  }

  /**
  * * SETUP CAMERA COLLISION AND GRAFITY *
  * Todo: Adding a gravity effect and collision effect to the camera and adjusting the camera height based on the data in the database
  */
  setupCollisionGravity() {
    this.camera.checkCollisions = true;
    this.camera.ellipsoid = new BABYLON.Vector3( 0.4, this.exhibition.camera.height_camera, 0.4);
  }

  /**
  * * CREATE SCENE *
  * Todo: create a scene of the exhibit and add creating additional object
  * @param engine : BABYLON.Engine
  */
  createScene(engine: any) {
    // Init Scene
    const scene =  new BABYLON.Scene(engine);
    this.gravity = new BABYLON.Vector3(0, -0.05, 0);
    scene.gravity = this.gravity;
    scene.collisionsEnabled = true;
    scene.clearColor = BABYLON.Color4.FromHexString('#ffffff');
    scene.ambientColor = new BABYLON.Color3(1, 1, 1);

    // Create Pointer Object
    this.createPointerMesh(scene);

    // Create node helper
    new BABYLON.TransformNode('nodeDetector', scene);
    new BABYLON.TransformNode('wrapCamera', scene);
    new BABYLON.FreeCamera('cameraDetector', BABYLON.Vector3.Zero(), scene);

    return scene;
  }

  createPointerMesh(scene: any) {
    // Create Pointer Object
    this.pointerMesh = BABYLON.MeshBuilder.CreatePlane('pointerMesh', { size: 1, sideOrientation: BABYLON.Mesh.DOUBLESIDE }, scene);
    const pointerMeshMaterial = new BABYLON.StandardMaterial('pointerMat', scene);
    const pointerMeshTexture = new BABYLON.Texture(environment.staticAssets+'images/other/rounded.png?t='+this.appVersion, scene);
    this._enableMipMap(pointerMeshTexture);
    pointerMeshMaterial.emissiveTexture = pointerMeshTexture;
    pointerMeshMaterial.emissiveTexture.hasAlpha = true;
    pointerMeshMaterial.opacityTexture = pointerMeshTexture;
    pointerMeshMaterial.useAlphaFromDiffuseTexture = true;
    pointerMeshMaterial.disableLighting = true;
    pointerMeshMaterial.backFaceCulling = false;
    pointerMeshMaterial.alphaMode = BABYLON.Engine.ALPHA_ADD;
    this.pointerMesh.material = pointerMeshMaterial;
    this.pointerMesh.position.y = -100;
    this.pointerMesh.isPickable = false;

    const directions = [
      new BABYLON.Vector3(1, 0, 0),
      new BABYLON.Vector3(-1, 0, 0),
      new BABYLON.Vector3(0, 0, -1),
      new BABYLON.Vector3(0, 0, 1),
      new BABYLON.Vector3(1, 0, 1),
      new BABYLON.Vector3(-1, 0, 1),
      new BABYLON.Vector3(1, 0, -1),
      new BABYLON.Vector3(-1, 0, -1),
    ];

    this.pointerMesh['rays'] = directions.map((direction:any) => {
      return new BABYLON.Ray(BABYLON.Vector3.Zero(), direction, 1);
    });
  }



  /**
  * * LOAD ORDINARY OBJECT *
  * Todo: to load ordinary object
  * @param objectData
  */
  loadOrdinaryObject(objectData:any, onLoaded:any = () => {}, onLoad:any = () => {}, onError:any = () => {}) {
    BABYLON.SceneLoader.ImportMeshAsync('', '', objectData.model_path, this.scene, onLoad).then((res: any) => {
      // craete wrap of figure
      const ordinaryObject:any = new BABYLON.TransformNode('ordinaryObject', this.scene);
      ordinaryObject.id = `ordinaryObject-${objectData.id}`;
      ordinaryObject['isMove'] = false;

      let rootMesh: any = null;
      res.meshes.map((mesh:any)=>{
        if (mesh.name!='__root__') {
          mesh.setParent(ordinaryObject);
          this._setLighting(objectData, mesh);
        } else {
          rootMesh = mesh;
        }
      });
      rootMesh?.dispose();

      ordinaryObject.position = new BABYLON.Vector3(
          objectData.position.position_x,
          objectData.position.position_y,
          objectData.position.position_z,
      );
      ordinaryObject.rotation = new BABYLON.Vector3(
          objectData.rotation.rotation_x,
          objectData.rotation.rotation_y,
          objectData.rotation.rotation_z,
      );
      ordinaryObject.scaling = new BABYLON.Vector3(
          objectData.scaling.scaling_x,
          objectData.scaling.scaling_y,
          objectData.scaling.scaling_z,
      );

      this.ordinaryObjectNodes.push(ordinaryObject);
      onLoaded(ordinaryObject);
    }).catch((err: any)=>{
      onError(err);
    });
  }

  /**
   * Set Lighting
   * @description : to set lighting for ordinary object
   * @param ordinaryObject : OrdinaryObject
   * @param mesh : BABYLON.Mesh
   */
  private _setLighting(ordinaryObject: any, mesh: any): void {
    this.mainLightArtwork.excludedMeshes.push(mesh);
    mesh.material.environmentIntensity = ordinaryObject.light_intensity / 2;
  }


  /**
  * * GET OTHER EXHIBITION *
  * Todo: to getting other exhibition
  * @param id
  * @returns
  */
  getOtherExhibitions(id:string) {decodeURI
    return this._http.get(`${environment.baseURL}/viewer-exhibition/${id}`).pipe(timeout(20000));
  }

  /**
  * * RECALCULATE ROTATION NODE *
  * Todo: recalculates the resulting rotation value of the 'setDirection' function so that the value is always between -Math Phi and Math Phi
  * @param rotationValue : Number
  * @returns Number
  */
  recalulateRotationNode(rotationValue:any) {
    while (!(rotationValue <= Math.PI && rotationValue >= -Math.PI)) {
      if (rotationValue > Math.PI) {
        rotationValue -= Math.PI*2;
      }
      if (rotationValue < -Math.PI) {
        rotationValue -= Math.PI*2;
      }
    }
    return rotationValue;
  }

  /**
  * * REDUCE RESOLUTION PARAM *
  * Todo: to reduce resolution param
  */
  reduceResolutionParam(resolutionParam: string) {
    const resolutionObject = resolutionParam.split('=')[1].split('x');
    const width = Number(resolutionObject[1]);
    const height = Number(resolutionObject[0]);

    const ratioXY = height / width;
    const newWidth = 100;
    const newHeight = newWidth * ratioXY;

    return `resize=${Math.round(newHeight)}x${Math.round(newWidth)}`;
  }


  /**
  * * GET USER INFO *
  * Todo: to get user info
  */
  getUserInfo(shareString: string) {
    return this._http.get(`${ environment.baseURL}/users-profile/${shareString}`).pipe(timeout(20000));
  }

  vecToLocal(vector: any, mesh: any) {
    const m = mesh.getWorldMatrix();
    const v = BABYLON.Vector3.TransformCoordinates(vector, m);
    return v;
  }

  /**
   * * DETECT CAMERA ABOVE STAIRS *
   * Todo: to detect the camera above the stairs
   */
  detectCameraAboveStairs() {
    const activeCamera = this.scene.activeCamera.name;
    if (activeCamera == 'floatingCamera') return;

    this.camera['bottomRay'].origin = this.camera.position.clone();
    const stairsMesh = this.stairsMeshes.find((x:any) => x.name == 'stairs_invisible');
    if (stairsMesh) {
      const hit = this.camera['bottomRay'].intersectsMeshes([ stairsMesh ]);
      if (hit.length > 0) {
        this.makeClimbupStairsMoreSmooth(true);
      } else {
        this.makeClimbupStairsMoreSmooth(false);
      }
    }
  }

  /**
   * * ENBALE MIP MAP *
   * @param texture : Texture
   */
  private _enableMipMap(texture: any): void {
    texture.useMipMaps = true;
    texture.updateSamplingMode(BABYLON.Texture.LINEAR_LINEAR_MIPLINEAR);
  }

  public texturePacker(meshes: any, name: string): void {
    const texturePacker =  new BABYLON.TexturePacker(name, meshes, {
      frameSize: 256,
      layout: BABYLON.TexturePacker.LAYOUT_POWER2,
      paddingMode: BABYLON.TexturePacker.SUBUV_EXTEND,
    }, this.scene);

    texturePacker.processAsync();
  }

  private _swapCamera() {
    const mainCamera = this.scene.getCameraByName('mainCamera');
    mainCamera.position = this.camera['_position'];
    mainCamera.setTarget(this.camera.getFrontPosition(this.camera.radius));
    this.scene.activeCamera = this.camera = mainCamera;
  }

  /**
   * * CHANGE VIEW MODE EXHIBITION *
   * * Todo: change camera mode to floating camera
   */
  public changeCamera() {
    return new Promise((resolve, reject) => {
      try {
        this.unselectArtwork().then(() => {
          this.animationIsRun = true;
          if (!this.onFloatingCamera) {
            const cameraPosition = this.camera.position.clone();
            const centerPosition = new BABYLON.Vector3(
                this.exhibition.config.centerPosition.x,
                this.exhibition.config.centerPosition.y,
                this.exhibition.config.centerPosition.z,
            );

            this._lastPosition = cameraPosition.clone();
            this.canvas.style.pointerEvents = 'none';
            this.camera.detachControl(this.canvas);

            this._setupCeilingExhibition(false);
            this.setHorizontalCameraMovementObs(false);

            let arcCamera = this.scene.getCameraByName('floatingCamera');
            if (!arcCamera) arcCamera = this._setupArcRotateCamera(centerPosition);
            if (this.isMobile) this.setMobileFloatingCameraMovements(cameraPosition, true);
            arcCamera.fov = this.cameraFov;
            arcCamera.mapPanning = true;
            this.camera.checkCollisions = false;
            if (this._folderName !== 'FOUR-WALL') this.scene.clearColor = BABYLON.Color4.FromHexString('#515151');

            this.onFloatingCamera = true;
            this._handleArtworkBlinkingBehindWall(this.onFloatingCamera);

            const ease = this._setEaseMode();
            BABYLON.Animation.CreateAndStartAnimation('flyingCameraAnimation', this.camera, 'position', 20, 60, cameraPosition, arcCamera['_newPosition'], 0, ease );
            BABYLON.Animation.CreateAndStartAnimation('flyingRotationAnimation', this.camera, 'target', 20, 60, this.camera.target, centerPosition, 0, ease, () => {
              this.scene.activeCamera = this.camera = arcCamera;
              this.animationIsRun = false;
              this._addControlArcCamera(centerPosition);
              this.canvas.style.pointerEvents = 'auto';
              this.canvas.blur();
              this.canvas.focus();
              resolve(null);
            });

            if (this.isBrowser) this._elasticsearchService.sendToElasticsearch('dollhouse');
          } else {
            this._switchToFreeCamera(this._lastPosition, true).then(() => {
              resolve(null);
            });
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * * SETUP FLOATING CAMERA *
   * * Todo: create floating camera view mode
   */
  private _setupArcRotateCamera(target:any): any {
    const position = this.exhibition.config.floatingCameraPosition;
    const floatingCamera = new BABYLON.ArcRotateCamera(
        'floatingCamera',
        position.alpha,
        position.beta,
        position.radius,
        target,
        this.scene,
    );

    if (this._folderName == 'FOUR-WALL') floatingCamera.upperRadiusLimit = 40
    floatingCamera.lowerRadiusLimit = 1;
    floatingCamera.upperBetaLimit = Math.PI / 2;
    floatingCamera.panningSensibility = 800;
    floatingCamera.angularSensibilityX = 3000;
    floatingCamera.angularSensibilityY = 3000;
    return floatingCamera;
  }

  /** CUSTOM CONTROL FOR FLOATING CAMERA */
  private _addControlArcCamera(target:any): void {
    this.camera = this.scene.activeCamera;
    this.camera.inputs.remove(this.camera.inputs.attached.keyboard);

    let cameraForward;
    let cameraBackward;

    // Create Controls:
    const KeyboardInput:any = function(this: any) {
      this._keys = [];
      this.keysUp = [ 87, 38 ];
      this.keysDown = [ 83, 40 ];
      this.keysLeft = [ 37, 65 ];
      this.keysRight = [ 39, 68 ];
    };

    // Hooking keyboard events
    KeyboardInput.prototype.attachControl = function(noPreventDefault:any) {
      const THIS = this;
      const engine = this.camera.getEngine();
      const element = engine.getInputElement();

      if (!this._onKeyDown) {
        element.tabIndex = 1;
        this._onKeyDown = function(evt:any) {
          if (THIS.keysLeft.indexOf(evt.keyCode) !== -1 ||
            THIS.keysRight.indexOf(evt.keyCode) !== -1 ||
            THIS.keysUp.indexOf(evt.keyCode) !== -1 ||
            THIS.keysDown.indexOf(evt.keyCode) !== -1) {
            const index = THIS._keys.indexOf(evt.keyCode);
            if (index === -1) {
              THIS._keys.push(evt.keyCode);
            }
            if (!noPreventDefault) {
              evt.preventDefault();
            }
          }
        };
        this._onKeyUp = function(evt:any) {
          if (THIS.keysLeft.indexOf(evt.keyCode) !== -1 ||
            THIS.keysRight.indexOf(evt.keyCode) !== -1 ||
            THIS.keysUp.indexOf(evt.keyCode) !== -1 ||
            THIS.keysDown.indexOf(evt.keyCode) !== -1) {
            const index = THIS._keys.indexOf(evt.keyCode);
            if (index >= 0) {
              THIS._keys.splice(index, 1);
            }
            if (!noPreventDefault) {
              evt.preventDefault();
            }
          }
        };

        element.addEventListener('keydown', this._onKeyDown, false);
        element.addEventListener('keyup', this._onKeyUp, false);
      }
    };

    // Unhook
    KeyboardInput.prototype.detachControl = function() {
      if (this._onKeyDown) {
        const engine = this.camera.getEngine();
        const element = engine.getInputElement();
        element.removeEventListener('keydown', this._onKeyDown);
        element.removeEventListener('keyup', this._onKeyUp);

        this._keys = [];
        this._onKeyDown = null;
        this._onKeyUp = null;
      }
    };

    // This function is called by the system on every frame
    KeyboardInput.prototype.checkInputs = function() {
      if (this._onKeyDown) {
        // Keyboard
        for (let index = 0; index < this._keys.length; index++) {
          const keyCode = this._keys[index];
          if (this.keysLeft.indexOf(keyCode) !== -1 && keyCode == 65) {
            this.camera.inertialPanningX -= 0.01;
          } else if (this.keysRight.indexOf(keyCode) !== -1 && keyCode == 68) {
            this.camera.inertialPanningX += 0.01;
          } else if (this.keysUp.indexOf(keyCode) !== -1 && keyCode == 87) {
            this.camera.inertialPanningY += 0.005;
            cameraForward = target.subtract(this.camera.position).normalize();
            cameraForward.scaleInPlace(0.05);
            target.addInPlace(
                new BABYLON.Vector3(
                    cameraForward.x,
                    0,
                    cameraForward.z,
                ),
            );
            this.camera.setTarget(target);
          } else if (this.keysDown.indexOf(keyCode) !== -1 && keyCode == 83) {
            this.camera.inertialPanningY -= 0.005;
            cameraBackward = target.subtract(this.camera.position).normalize();
            cameraBackward.scaleInPlace(0.05);
            target.subtractInPlace(
                new BABYLON.Vector3(
                    cameraBackward.x,
                    0,
                    cameraBackward.z,
                ),
            );
            this.camera.setTarget(target);
          } else if (this.keysDown.indexOf(keyCode) !== -1 && keyCode == 40) {
            this.camera.beta -= 0.015;
          } else if (this.keysUp.indexOf(keyCode) !== -1 && keyCode == 38) {
            this.camera.beta += 0.015;
          } else if (this.keysLeft.indexOf(keyCode) !== -1 && keyCode == 37) {
            this.camera.alpha -= 0.015;
          } else if (this.keysRight.indexOf(keyCode) !== -1 && keyCode == 39) {
            this.camera.alpha += 0.015;
          }
        }
      }
    };

    KeyboardInput.prototype.getTypeName = function() {
      return 'KeyboardInput';
    };
    KeyboardInput.prototype.getSimpleName = function() {
      return 'keyboard';
    };

    // Add controls to camera
    this.camera.inputs.add(new KeyboardInput());
  }


  showPricing() {
    const zeroPrices: string[] = [ '0', 'empty', 'null' ];
    const pricingAmount = this.activeArtwork.pricing_amount;
    const enablePricing = this.activeArtwork.pricing;
    return enablePricing && pricingAmount && !zeroPrices.includes(pricingAmount.toLowerCase());
  }

  public multiTouch:any;
  public touchDown:any =[];
  setMobileFloatingCameraMovements(target:any, enable:any) {
    if (enable) {
      if (!this.observers['multiTouchBeforeFloating']) {
        this.observers['multiTouchBeforeFloating'] = this.scene.onBeforeRenderObservable.add(() => {
          this.scene.activeCamera.panningSensibility = 300;
          this.scene.activeCamera.pinchPrecision = 70;
          this.scene.activeCamera.mapPanning = true;
        });
      }

      if (!this.observers['multiTouchFloating']) {
        this.observers['multiTouchFloating'] = this.scene.onPointerObservable.add((eventData:any) => {
          switch (eventData.type) {
            case BABYLON.PointerEventTypes.POINTERDOWN: {
              this.touchDown.push(eventData.event);
              this.multiTouch = this.touchDown.length;
              break;
            }

            case BABYLON.PointerEventTypes.POINTERMOVE: {
              if (this.multiTouch > 1) {
                const cameraMoveDistance = 0.3;

                if (this.touchDown[0]?.screenY > eventData.event.screenY && this.touchDown[1]?.screenY > eventData.event.screenY) {
                  const cameraForward = target.subtract(this.camera.position).normalize();
                  cameraForward.scaleInPlace(cameraMoveDistance);
                  target.subtractInPlace(
                      new BABYLON.Vector3(
                          cameraForward.x,
                          0,
                          cameraForward.z,
                      ),
                  );
                  this.camera.setTarget(target);
                } else if (this.touchDown[0]?.screenY < eventData.event.screenY && this.touchDown[1]?.screenY < eventData.event.screenY) {
                  const cameraForward = target.subtract(this.camera.position).normalize();
                  cameraForward.scaleInPlace(cameraMoveDistance);
                  target.addInPlace(
                      new BABYLON.Vector3(
                          cameraForward.x,
                          0,
                          cameraForward.z,
                      ),
                  );
                  this.camera.setTarget(target);
                }
              }
              this.touchDown.push(eventData.event);
              this.touchDown.shift();

              break;
            }
            case BABYLON.PointerEventTypes.POINTERUP: {
              if (this.touchDown.length > 1) this.touchDown.shift();
              else this.touchDown = [];
              break;
            }
          }
        });
      }
    } else {
      this.scene.onBeforeRenderObservable.remove(this.observers['multiTouchBeforeFloating']);
      this.scene.onPointerObservable.remove(this.observers['multiTouchFloating']);
      this.observers['multiTouchBeforeFloating'] = null;
      this.observers['multiTouchFloating'] = null;
    }
  }





  // ================================================================================================
  // NOTE - Delimiter of the refactored function (below)
  // ================================================================================================


  /**
  * * ================================================================================================ *
  *   SECTION Get Position in Front of Artwork Functions
  * * ================================================================================================ *
  */
  //#region

  /**
   * ANCHOR Get Position in Front of Artwork (Main Function)
   * @description to get position in front of artwork like a visitor in real life
   * @param artworkNode: BABYLON.TransformNode -> Container of artwork
   * @return BABYLON.Vector3 | null -> Position in front of the artwork.
   *         if return is null, it mean the gap too narrow
  */
  private async _positionInFrontArtwork(artworkNode: any) {
    const artworkContaier = this._cloneArtworkContainer(artworkNode);
    const translateVector = this._getTranslateVector(artworkContaier, artworkNode['artworkType']);
    const nodeDetector = this.scene.getTransformNodeByName('nodeDetector');
    this._setNodeDetectorPostion(artworkContaier, nodeDetector);

    nodeDetector.translate(translateVector, 1, BABYLON.Space.LOCAL);

    const yPosition = await this._getCameraYPosition(nodeDetector.position.clone());
    if (!yPosition) {
      artworkContaier.dispose();
      nodeDetector.position = -1000;
      return null;
    }
    nodeDetector.position.y = yPosition;

    const distanceToObject = await this._getDistanceToFrontObject(artworkContaier, nodeDetector.position.clone());
    const distanceToWatchPosition = BABYLON.Vector3.Distance(artworkContaier.position, nodeDetector.position.clone());

    const objectDistanceMoreClose = distanceToObject < distanceToWatchPosition;
    if (objectDistanceMoreClose) {
      translateVector.z = distanceToObject - 0.4;
      this._setNodeDetectorPostion(artworkContaier, nodeDetector);
      nodeDetector.translate(translateVector, 1, BABYLON.Space.LOCAL);
      const yPosition = await this._getCameraYPosition(nodeDetector.position.clone());
      nodeDetector.position.y = yPosition;

      if (this._isGapTooNarrow(artworkContaier, nodeDetector)) {
        artworkContaier.dispose();
        nodeDetector.position = -1000;
        return null;
      };
    }

    artworkContaier.dispose();
    const watchPosition = nodeDetector.position.clone();
    nodeDetector.position = -1000;

    return watchPosition;
  }

  /**
   * ANCHOR Get Translate Z
   * @description to get translate Z
   * @param artworkContainer: BABYLON.Mesh -> Clone of artwork container
   * @returns Number -> Translate Z
   */
  private _getTranslateZ(artworkContainer: any): number {
    const artworkWidth = artworkContainer.scaling.x;
    const artworkHeight = artworkContainer.scaling.y;
    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;

    const rasioArtwork = artworkHeight / artworkWidth;
    const rasioCanvas = canvasHeight / canvasWidth;

    let translateZ = rasioCanvas > rasioArtwork ? artworkWidth * (rasioCanvas + 0.6) : artworkHeight * 1.3;
    if (translateZ < 1) translateZ = 1;

    const artworkThickness = artworkContainer.scaling.clone().z;
    translateZ += artworkThickness;

    return artworkThickness + translateZ;
  }

  /**
   * ANCHOR Gap Too Narrow Detector
   * @description to detect if the gap between artwork and node detector too narrow
   * @param artworkContaier : BABYLON.Mesh -> Clone of artwork container
   * @param nodeDetector : BABYLON.TransformNode -> Node detector
   */
  private _isGapTooNarrow(artworkContaier: any, nodeDetector: any) {
    const artworkPosition = artworkContaier.position.clone();
    const nodeDetectorPosition = nodeDetector.position.clone();
    artworkPosition.y = nodeDetectorPosition.y;

    const distance = BABYLON.Vector3.Distance(artworkPosition, nodeDetectorPosition);
    return distance < 0.8;
  }

  /**
   * ANCHOR Get Translate Vector3
   * @description to get translate vector3
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @returns : BABYLON.Vector3
   */
  private _getTranslateVector(artworkContainer: any, artworkType: string): any {
    const translateZ = this._getTranslateZ(artworkContainer);
    const translateVector = BABYLON.Vector3.Zero();
    translateVector.z = translateZ;
    if (artworkType == 'figure-object') translateVector.z -= 0.8;
    else translateVector.z += 0.8;
    return translateVector;
  }

  /**
   * ANCHOR Set Node Detector Position Same As Artwork Container
   * @description to set node detector position same as artwork container
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @param nodeDetector : BABYLON.TransformNode -> Node detector
   * @returns : BABYLON.TransformNode
   */
  private _setNodeDetectorPostion(artworkContainer: any, nodeDetector: any): any {
    nodeDetector.position = artworkContainer.position.clone();
    nodeDetector.rotation = artworkContainer.rotation.clone();
  }

  //#endregion
  //!SECTION

  /**
  * * ================================================================================================ *
  *   SECTION 'Focus On Artwork Animation' Functions
  * * ================================================================================================ *
  */
  //#region
  private _focusOnArtworkAnimationObjects: any = [];

  /**
   * ANCHOR Focus On Artwork Animation (Main Function)
   * @description to run 'Focus on Artwork' animation
   * @param artworkNode : BABYLON.TransformNode -> Container of artwork
   * @returns : Promise<void>
   */
  private  _focusOnArtworkAnimation(artworkNode:any): Promise<void> {
    return new Promise(async (resolve, reject)=>{
      try {
        this.animationIsRun = true;
        this.scene.gravity = BABYLON.Vector3.Zero();
        this.cancelMovingCameraAnimation();

        this.camera.detachControl(this.canvas);

        const wrapCamera = this.scene.getTransformNodeByID('wrapCamera');
        const watchPosition = await this._positionInFrontArtwork(artworkNode);

        if (watchPosition) {
          const endRotation = this._targetRotationAnimation({
            artwork: artworkNode,
            wrapCamera: wrapCamera,
            endPosition: watchPosition,
          });

          // Run animations
          const ease = this._setEaseMode();
          const fovAnimation = BABYLON.Animation.CreateAndStartAnimation('fovAnimation', this.camera, 'fov', 40, 100, this.camera.fov, 0.8, 0, ease);
          const rotationAnimation = BABYLON.Animation.CreateAndStartAnimation('rotationAnimation', this.camera, 'rotation', 40, 100, this.camera.rotation, endRotation, 0, ease);
          const positionAnimation = BABYLON.Animation.CreateAndStartAnimation('positionAnimation', wrapCamera, 'position', 40, 100, wrapCamera.position, watchPosition, 0, ease, () => {
            this._handleWhenAnimationStops();

            const artworkContainer = this._cloneArtworkContainer(artworkNode);
            this.camera.setTarget(artworkContainer.position);
            artworkContainer.dispose();

            artworkNode['focus'] = true;
            if (!this.runRoomTour) this.setupCameraZoomArtwork(artworkNode);

            // play text to speech
            if (this._ttsService.ttsAudio) {
              this._ttsService.ttsAudio.play();
              this._ttsService.ttsSpeaking = true;
            }
            resolve();
          });

          // Assign all animations
          this._focusOnArtworkAnimationObjects = [
            rotationAnimation,
            fovAnimation,
            positionAnimation,
          ];

          if (this.isBrowser) this._elasticsearchService.sendToElasticsearch('click_figure', this.activeArtwork.id);
        } else {
          artworkNode['focus'] = false;
          this.animationIsRun = false;
          this.scene.gravity = this.gravity;
          this.initMainPointerObs();

          this._messageService.add({ type: 'warning', title: 'Warning', detailMessage: 'Warning Unable to focus on artwork. The angle is too steep!' });
          resolve();
        }
      } catch (error) {
        reject(error);
      }
    });
  }


  /**
  * ANCHOR Target Rotation Animation
  * @description to get target rotation animation
  * @param params: { artwork: BABYLON.TransformNode, wrapCamera: BABYLON.TransformNode, endPosition: BABYLON.Vector3 }
  * @return BABYLON.Vector3 -> Result of calcuation (Target Rotation)
  */
  private _targetRotationAnimation(params : { artwork: any, wrapCamera: any, endPosition: any }) {
    const { artwork, wrapCamera, endPosition } = params;

    const artworkContaier = this._cloneArtworkContainer(artwork);
    const cameraRotation = this.camera.rotation.clone();

    wrapCamera.rotation = cameraRotation.clone();
    wrapCamera.rotation.z = 0;
    wrapCamera.rotation.x = 0;
    wrapCamera.position = this.camera.position.clone();
    this.camera.parent = wrapCamera;
    this.camera.position = BABYLON.Vector3.Zero();
    this.camera.rotation.y = 0;
    this.camera.rotation.z = 0;

    const meshDetector = new BABYLON.Mesh('meshDetector', this.scene);
    const camera = this.scene.activeCamera;

    meshDetector.position = endPosition.clone();
    meshDetector.lookAt(artworkContaier.position);
    meshDetector.setParent(wrapCamera);
    const rotation = meshDetector.rotation.clone();
    rotation.x -= cameraRotation.x;

    const calcRotation = (axis: number) => {
      const posisiB = BABYLON.Angle.FromRadians(axis).degrees();
      const di360 = posisiB - (Math.floor(posisiB/360) * 360);
      const ratio = Math.floor(di360/180);
      const di180 = di360 - (ratio * 180);
      const degreeToRadian = BABYLON.Angle.FromDegrees(di180).radians();
      return ratio == 0 ? degreeToRadian : degreeToRadian - Math.PI;
    };

    const rotationTargetArtwork = camera.rotation.clone();
    rotationTargetArtwork.y += calcRotation(rotation.y);
    rotationTargetArtwork.x += calcRotation(rotation.x);

    meshDetector.parent = null;
    meshDetector.dispose();
    artworkContaier.dispose();

    return rotationTargetArtwork;
  }

  /**
   * ANCHOR Handle When Animation Stops
   * @description to handle when animation stops
   */
  private _handleWhenAnimationStops() {
    // Get camera and artwork node
    const nodeCamera = this.scene.getTransformNodeByName('wrapCamera');
    const artworkNode = this.scene.getTransformNodeByID(`artwork-${this.activeArtwork?.id}`);

    // Re-position camera
    this.camera.parent = null;
    this.camera.position = nodeCamera.position.clone();
    this.camera.rotation = nodeCamera.rotation.clone();
    this.camera.rotation.z = 0;
    this.camera.rotation.x = 0;

    // Register main observables
    this.initMainPointerObs();

    this.animationIsRun = false;
    this.activeArtworkId = artworkNode?.id || null;
    this._focusOnArtworkAnimationObjects = [];

    this.canvas.blur();
    this.canvas.focus();
  }

  /**
   * ANCHOR Cancel Focus On Artwork Animation
   * @description to cancel focus on artwork animation
   */
  private _cancelFocusOnArtworkAnimation() {
    this._focusOnArtworkAnimationObjects.map((animation: any) => animation.pause());
    this._handleWhenAnimationStops();
  }

  //#endregion
  //!SECTION

  /**
  * * ================================================================================================ *
  *   SECTION Create/Load Artwork Functions
  * * ================================================================================================ *
  */
  //#region

  /**
   * ANCHOR Create Artwork Container
   * @description to create artwork container (TransformNode)
   * @param artwork : Artwork
   * @returns BABYLON.TransformNode
   */
  private _createArtworkContainer(artwork: IArtwork): any {
    const wrapArtwork: any = new BABYLON.TransformNode('artwork', this.scene);
    wrapArtwork.id = `artwork-${artwork.id}`;
    wrapArtwork['artworkType'] = artwork.file_type;
    wrapArtwork['isMove'] = false;
    wrapArtwork['shadowHasInitialized'] = false;
    wrapArtwork['donutHasInitialized'] = false;
    return wrapArtwork;
  }

  /**
   * * SET ARTWORK MESH LIGHTING *
   * ANCHOR Set Artwork Mesh Lighting
   * @description Set lighting for artwork mesh
   * @param mesh : BABYLON.Mesh
   */
  private _setArtworkMeshLighting(mesh: any, artwork: IArtwork): void {
    if (artwork.file_type == 'figure-object') {
      this.mainLightArtwork.excludedMeshes.push(mesh);
      mesh.material.environmentIntensity = artwork.light_intensity / 2;
    } else {
      this.mainLightArtwork.includedOnlyMeshes.push(mesh);
    }
  }

  /**
   * * CREATE SHADOW MESH MASTER *
   * ANCHOR Create Shadow Mesh Master
   * @description Create a master mesh for shadow
   */
  public createShadowMeshMaster() {
    this._shadowArtworkComponentMeshMaster = BABYLON.MeshBuilder.CreatePlane('shadowsMaster', {
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    }, this.scene);
    const material = new BABYLON.StandardMaterial('shadow', this.scene);
    material.emissiveColor = new BABYLON.Color3(0, 0, 0);
    material.disableLighting = true;
    this._shadowArtworkComponentMeshMaster.material = material;
    this._shadowArtworkComponentMeshMaster.position.y = -100;
  }

  /**
   * * SET ARTWORK TRANSFORM *
   * ANCHOR Set Artwork Transform
   * @description Set transform such as position, scaling and rotation for artwork
   * @param artworkWrapper : BABYLON.TransformNode
   * @param artwork : Artwork
   */
  private _setArtworkTransform(artworkWrapper: any, artwork: IArtwork): void {
    artworkWrapper.position = new BABYLON.Vector3(
        artwork.position.position_x,
        artwork.position.position_y,
        artwork.position.position_z,
    );
    artworkWrapper.rotation = new BABYLON.Vector3(
        artwork.rotation.rotation_x,
        artwork.rotation.rotation_y,
        artwork.rotation.rotation_z,
    );

    if (artwork.file_type === 'figure-object') {
      artworkWrapper.scaling = new BABYLON.Vector3(
          artwork.scaling.scaling_x,
          artwork.scaling.scaling_y,
          artwork.scaling.scaling_z,
      );
    }
  }


  /**
   * * HIDE SHADOW MESH MASTER *
   * ANCHOR Hide Shadow Mesh Master
   * @param scene : BABYLON.Scene
   */
  private _hideShadowMeshMaster(): any {
    if (this.artworksNodes.length == this.artworks.length) {
      this.scene.getMeshByName('shadowsMaster').position.y = -100;
    }
  }

  /**
   * ANCHOR Create Artwork Frame (raw)
   * @description to create artwork frame (raw)
   * @param name : string
   * @param options : any
   * @returns : BABYLON.Mesh
   */
  private _frameMaker(name:any, options:any) {
    const path = options.path;
    const profile = options.profile;

    let originX = Number.MAX_VALUE;

    for (let m = 0; m < profile.length; m++) {
      originX = Math.min(originX, profile[m].x);
    }

    let angle = 0;
    let width = 0;
    const cornerProfile:any = [];

    const nbPoints = path.length;
    let line = BABYLON.Vector3.Zero();
    const nextLine = BABYLON.Vector3.Zero();
    path[1].subtractToRef(path[0], line);
    path[2].subtractToRef(path[1], nextLine);

    for (let p = 0; p < nbPoints; p++) {
      angle = Math.PI - Math.acos(BABYLON.Vector3.Dot(line, nextLine)/(line.length() * nextLine.length()));
      const direction = BABYLON.Vector3.Cross(line, nextLine).normalize().z;
      const lineNormal = new BABYLON.Vector3(line.y, -1 * line.x, 0).normalize();
      line.normalize();
      cornerProfile[(p + 1) % nbPoints] = [];
      //local profile
      for (let m = 0; m < profile.length; m++) {
        width = profile[m].x - originX;
        cornerProfile[(p + 1) % nbPoints].push(path[(p + 1) % nbPoints].subtract(lineNormal.scale(width)).subtract(line.scale(direction * width/Math.tan(angle/2))));
      }

      line = nextLine.clone();
      path[(p + 3) % nbPoints].subtractToRef(path[(p + 2) % nbPoints], nextLine);
    }

    const frame = [];
    let extrusionPaths:any = [];

    for (let p = 0; p < nbPoints; p++) {
      extrusionPaths = [];
      for (let m = 0; m < profile.length; m++) {
        extrusionPaths[m] = [];
        extrusionPaths[m].push(new BABYLON.Vector3(cornerProfile[p][m].x, cornerProfile[p][m].y, profile[m].y));
        extrusionPaths[m].push(new BABYLON.Vector3(cornerProfile[(p + 1) % nbPoints][m].x, cornerProfile[(p + 1) % nbPoints][m].y, profile[m].y));
      }

      frame[p] = BABYLON.MeshBuilder.CreateRibbon('frame', { pathArray: extrusionPaths, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: true, closeArray: true }, this.scene);
    }

    const mergedMesh:any = BABYLON.Mesh.MergeMeshes(frame, true);
    mergedMesh.name = name;
    return mergedMesh.convertToFlatShadedMesh();
  }

  // SECTION Create Artwork Object Functions
  //#region

  /**
   * * LOAD ARTWORK OBJECT *
   * ANCHOR Load Artwork Object
   * @description to load artwork object
   * @param artwork : Artwork
   */
  public loadArtworkObject(artwork: IArtwork): Promise<any> {
    return new Promise((resolve, reject) => {
      BABYLON.SceneLoader.ImportMesh('', '', artwork.model_path, this.scene, (meshes: any) => {
        const wrapArtwork = this._createArtworkContainer(artwork);

        let rootMesh: any;
        meshes.forEach((mesh: any) => {
          if (mesh.name !== '__root__') {
            this._setArtworkMeshLighting(mesh, artwork);
            mesh.computeWorldMatrix(true);
            mesh.setParent(wrapArtwork);
            mesh.isPickable = true;
            mesh.actionManager = this.actionManager;
          } else {
            rootMesh = mesh;
          }
        });
        rootMesh?.dispose();
        this._calculateArtworkObjectrRealDimension(wrapArtwork, artwork);
        this._setArtworkTransform(wrapArtwork, artwork);
        this.artworksNodes.push(wrapArtwork);
        this._hideShadowMeshMaster();
        this.loadedArtworks.push(artwork.id);

        resolve(wrapArtwork);
      }, null, reject);
    });
  }


  /**
   * * CALCULATE ARTWORK OBJECT DIMENSIONS *
   * ANCHOR Calculate Artwork Object Dimension
   * @description to calculate artwork object dimension
   * @param artworkWrapper : BABYLON.TransformNode
   * @param artwork : Artwork
   */
  private _calculateArtworkObjectrRealDimension(artworkWrapper: any, artwork: IArtwork): any {
    const tmpWrap = new BABYLON.TransformNode('tmpWrap', this.scene);
    artworkWrapper.getChildren().forEach((mesh: any) => mesh.setParent(tmpWrap));
    const { width, height, depth } = this.getNodeDimension(tmpWrap);
    artworkWrapper.scaling = new BABYLON.Vector3(width, height, depth);
    tmpWrap.getChildren().forEach((mesh: any) => mesh.setParent(artworkWrapper));
    tmpWrap.dispose();
  }

  //#endregion
  //!SECTION

  // SECTION Create Artwork Image/Video Functions
  //#region


  /**
   * ANCHOR Create Artwork Image/Video (New)
   * @description to create artwork image/video (new)
   * @param artwork : Artwork
   * @returns BABYLON.TransformNode
   */
  public async createArtworkImageVideoNew(artwork: IArtwork): Promise<any> {
    const artworkNode = await this._artworkLoaderService.createArtworkImageVideo({
      artwork,
      scene: this.scene,
      forEditFrame: false,
      artworkNodes: this.artworksNodes,
      loadedArtworks: this.loadedArtworks,
      highlightLayer: null,
      glowEffect: this.glowEffect,
      light: this.mainLightArtwork,
      artworks: this.artworks,
      actionManager: this.actionManager,
    });
    return artworkNode;
  }

  /**
    * ANCHOR Create Artwork Image/Video
    * @description to create artwork image or video
    * @param artwork: Artwork
    * @returns BABYLON.TransformNode
    */
  public createArtworkImageVideo(artwork: IArtwork): any {
    const wrapArtwork: any = this._createArtworkContainer(artwork);
    const artworkMaterials = this._createArtworkImageVideoMaterials(artwork, 'low');
    const { imageVideoMaterialName, passeparoutMaterialName, frameMaterialName } = this._getMaterialNames('low');

    const backframe = this._createArtworkBackFrameMesh(artwork, artworkMaterials);
    const imageVideo = this._createArtworkImageVideoMesh(artwork, artworkMaterials[imageVideoMaterialName]);
    const passepartout = this._createArtworkPasseMesh(artwork, artworkMaterials[passeparoutMaterialName]);
    const frame = this._createArtworkFrameMesh(artwork, artworkMaterials[frameMaterialName]);

    this._calculateArtworkImageVideoContainerDimensions(artwork, wrapArtwork);
    wrapArtwork.position.z += wrapArtwork.scaling.z/2;
    const artworkMeshes = [ backframe, imageVideo, passepartout, frame ].filter((mesh) => mesh);
    artworkMeshes.forEach((mesh: any) => {
      mesh.position.z += artwork.frame.back_frame.back_frame_depth/2;
    });

    const clonedArtworkMeshes = this._cloneArtworkMeshes(artworkMeshes);
    setTimeout(()=> {
      this._changeArtworkImageVideoQuality(artwork, wrapArtwork, clonedArtworkMeshes);
    }, 3000);

    const artworkMerged = this._mergeLowQualityArtworkMeshes(artwork, artworkMeshes, wrapArtwork);
    artworkMerged.actionManager = this.actionManager;
    this._setArtworkMeshLighting(artworkMerged, artwork);

    const artworkShadows = this.createShadowForArtworkComponents(artwork, wrapArtwork);
    artworkShadows.actionManager = this.actionManager;


    this._setArtworkPositionRotation(wrapArtwork, artwork);
    if (!artwork.old_frame?.is_null) this.correctingArtworkPosition(wrapArtwork, artwork.old_frame);
    // this.optimizeArtworkMeshes(artworkMerged, artworkShadows);
    this._removeUnusedArtworkMaterials(artworkMaterials);

    this.artworksNodes.push(wrapArtwork);

    this._hideShadowMeshMaster();

    this._showArtworkWhenReady(wrapArtwork);

    return wrapArtwork;
  };

  /**
   * ANCHOR Show Artwork When Ready
   * @description to show artwork when ready
   * @param artworkNode : BABYLON.TransformNode -> Container of artwork
   */
  private _showArtworkWhenReady(artworkNode: any) {
    const artworkMesh = artworkNode.getChildren().find((mesh: any) => mesh.name === 'artworkMeshLowQuality');
    const artworkShadows = artworkNode.getChildren().find((mesh: any) => mesh.name === 'artworkShadows');
    artworkMesh.setEnabled(false);
    artworkShadows.setEnabled(false);
    artworkMesh.onMeshReadyObservable.add(()=>{
      artworkMesh.setEnabled(true);
      artworkShadows.setEnabled(true);
      artworkMesh.onMeshReadyObservable.clear();
    });
  }

  /**
   * ANCHOR Create Artwork Video Loading
   * @description to create artwork video loading
   * @param artworkNode : BABYLON.TransformNode
   * @param artwork : Artwork
   * @returns : BABYLON.Mesh
   */
  private _createLoading(artworkNode: any, artwork: IArtwork): any {
    const loadingWrapper = BABYLON.MeshBuilder.CreatePlane('loadingWrapper', {
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    }, this.scene);
    loadingWrapper.isPickable = false;
    loadingWrapper.position = artworkNode.position.clone();
    const minSize = Math.min(artwork.real_width, artwork.real_height);
    const size = minSize > 0.6 ? 0.6 : minSize;
    loadingWrapper.scaling = new BABYLON.Vector3(size, size, 1);
    loadingWrapper.rotation = artworkNode.rotation.clone();
    loadingWrapper.translate(
        new BABYLON.Vector3(0, 0, artwork.frame.back_frame.back_frame_depth/2+0.01), 1, BABYLON.Space.LOCAL,
    );
    loadingWrapper.rotation.y += Math.PI;

    const advancedTexture = BABYLON.GUI.AdvancedDynamicTexture.CreateForMesh(loadingWrapper, 514, 514, false);
    const guiText = new BABYLON.GUI.TextBlock('text');
    guiText.color = '#E7E7E7';
    guiText.text = 'Loading';
    guiText.fontSize = '100';
    advancedTexture.addControl(guiText);

    loadingWrapper['intervalFun'] = setInterval(() => {
      if (guiText.text.length >= 10) guiText.text = 'Loading';
      else guiText.text += '.';
    }, 500);

    return loadingWrapper;
  }

  /**
   * ANCHOR Change Artwork Image/Video Quality
   * @description to change artwork image/video quality
   * @param artwork : Artwork
   * @param artworkNode : BABYLON.TransformNode
   * @param artworkMergedMeshes : BABYLON.Mesh[]
   */
  private _changeArtworkImageVideoQuality(artwork: IArtwork, artworkNode: any, artworkMergedMeshes: any): void {
    artworkNode['artworkMergedLowQty'].addLODLevel();
    artworkNode['artworkMergedLowQty'].onLODLevelSelection = (distance: number) => {
      if (distance < 10) {
        artworkNode['artworkMergedLowQty'].removeLODLevel();
        setTimeout(() => {
          const artworkMergedHighQty = this._createHighQualityArtworkNode(artworkMergedMeshes, artwork, artworkNode);
          let loading: any;
          if (artwork.file_type == 'video') loading = this._createLoading(artworkNode, artwork);
          artworkMergedHighQty.onMeshReadyObservable.add(()=>{
            artworkNode['artworkMergedLowQty'].dispose();
            artworkNode['artworkMergedLowQty'] = null;

            artworkMergedHighQty.actionManager = this.actionManager;
            artworkMergedHighQty.setEnabled(true);

            if (artwork.file_type == 'video') {
              clearInterval(loading['intervalFun']);
              loading.dispose();
              this._playbackControler(artworkMergedHighQty);
            }

            artworkMergedHighQty.onMeshReadyObservable.clear();
          });
        }, 100);
      }
    };
  }

  /**
   * ANCHOR Playback Controler
   * @description to control playback of video
   * @param artworkMesh : BABYLON.Mesh
   */
  private _playbackControler(artworkMesh: any) {
    const video = artworkMesh.video;
    artworkMesh.addLODLevel();
    artworkMesh.onLODLevelSelection = (distance: number) => {
      if (distance < 10 && this.camera.isInFrustum(artworkMesh)) {
        if (video.paused) {
          video.play();
        }
      } else {
        if (!video.paused) {
          video.pause();
        }
      }
    };
  }

  /**
   * ANCHOR Merge Low Quality Artwork Meshes
   * @description to merge artwork meshes
   * @param artwork : Artwork
   * @param artworkMeshes : BABYLON.Mesh[]
   * @param artworkNode : BABYLON.TransformNode
   * @returns : BABYLON.Mesh
   */
  private _mergeLowQualityArtworkMeshes(artwork: IArtwork, artworkMeshes: any[], artworkNode: any): any {
    const artworkMerged: any = this.mergedMeshes(artworkMeshes, 'artworkMeshLowQuality');
    artworkMerged.setParent(artworkNode);
    artworkNode['artworkMergedLowQty'] = artworkMerged;
    return artworkMerged;
  }

  /**
   * ANCHOR Clone Artwork Meshes
   * @description to clone artwork meshes
   * @param artworkMeshes : BABYLON.Mesh[]
   * @returns BABYLON.Mesh[]
   */
  private _cloneArtworkMeshes(artworkMeshes: any[]): any[] {
    return artworkMeshes.map((mesh: any) => {
      const clonedMesh = mesh.clone();
      const clonnedMaterial = mesh.material.clone(mesh.material.name);
      clonedMesh.material = clonnedMaterial;
      clonedMesh.setEnabled(false);
      return clonedMesh;
    });
  }

  /**
   * ANCHOR Calculate Artwork Image/Video Container Dimensions
   * @description to calculate artwork image/video container dimensions
   * @param artwork : Artwork
   * @param artworkNode : any
   */
  private _calculateArtworkImageVideoContainerDimensions(artwork: IArtwork, artworkNode: any): void {
    const isUseFrame = artwork.frame.frame.frame;
    const isUsePasse = artwork.frame.passepartout.passepartout;
    const passeWidth = artwork.frame.passepartout.passepartout_width * 2;
    const frameWidth = artwork.frame.frame.frame_width * 2;
    const artworkWidth = artwork.real_width;
    const artworkHeight = artwork.real_height;
    const frameThicknes = isUseFrame ? artwork.frame.frame.frame_depth : 0;
    const passeThicknes = isUsePasse ? artwork.frame.passepartout.passepartout_depth : 0;
    const backFrameThickness = artwork.frame.back_frame.back_frame_depth;

    if (isUseFrame) {
      artworkNode.scaling.y = artworkHeight + (isUsePasse ? passeWidth : 0) + frameWidth;
      artworkNode.scaling.x = artworkWidth + (isUsePasse ? passeWidth : 0) + frameWidth;
    } else if (isUsePasse) {
      artworkNode.scaling.y = artworkHeight + passeWidth;
      artworkNode.scaling.x = artworkWidth + passeWidth;
    } else {
      artworkNode.scaling.y = artworkHeight;
      artworkNode.scaling.x = artworkWidth;
    }

    artworkNode.scaling.z = backFrameThickness + Math.max(passeThicknes, frameThicknes);
  }

  /**
   *  ANCHOR Create Artwok Image/Video Mesh
   * @description to create artwork image/video mesh
   * @param artwork : Artwork
   * @param material : BABYLON.StandardMaterial
   * @returns BABYLON.Mesh
   */
  private _createArtworkImageVideoMesh(artwork: IArtwork, material: any): any {
    const imageVideo:any = BABYLON.MeshBuilder.CreatePlane('imageVideo', {
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    }, this.scene);
    const zPosition = artwork.frame.back_frame.back_frame_depth/2+0.009;
    imageVideo.scaling.x = artwork.real_width;
    imageVideo.scaling.y = artwork.real_height;
    imageVideo.rotation.y = Math.PI;
    imageVideo.position.z = zPosition;
    imageVideo.material = material;
    return imageVideo;
  }

  /**
   * ANCHOR Create Back Frame Mesh
   * @description to create back frame mesh
   * @param artwork : Artwork
   */
  private _createArtworkBackFrameMesh( artwork: IArtwork, materialNodes: any): any {
    const isUsePasse = artwork.frame.passepartout.passepartout;
    const isUseFrame = artwork.frame.frame.frame;
    const passeWidth = artwork.frame.passepartout.passepartout_width * 2;
    const backFrameThickness = artwork.frame.back_frame.back_frame_depth;
    const backFrameWidth = artwork.real_width + (isUsePasse ? passeWidth : 0);
    const backFrameHeight = artwork.real_height + (isUsePasse ? passeWidth : 0);
    const { backFrameMaterialName, passeparoutMaterialName, frameMaterialName } = this._getMaterialNames('low');

    const backFrame = BABYLON.MeshBuilder.CreateBox('backFrame', {}, this.scene);
    backFrame.scaling = new BABYLON.Vector3( backFrameWidth, backFrameHeight, backFrameThickness );

    if (isUseFrame) backFrame.material = materialNodes[frameMaterialName];
    else if (isUsePasse) backFrame.material = materialNodes[passeparoutMaterialName];
    else backFrame.material = materialNodes[backFrameMaterialName];

    return backFrame;
  }

  /**
   * ANCHOR Remove Unused Artwork Materials
   * @description to remove unused artwork materials
   * @param artworkMaterials : any
   */
  private _removeUnusedArtworkMaterials(artworkMaterials: any) {
    for (const key in artworkMaterials) {
      if (Object.prototype.hasOwnProperty.call(artworkMaterials, key)) {
        artworkMaterials[key].dispose();
      }
    }
  }

  /**
  * ANCHOR Create High Quality Artwork Node
  * @description to create high quality artwork node
  * @param artworkMeshes : BABYLON.Mesh[]
  * @param artwork : Artwork
  * @param artworkNode : BABYLON.TransformNode
  */
  private _createHighQualityArtworkNode(artworkMeshes: any[], artwork: IArtwork, artworkNode: any) {
    const artworkMaterials = this._createArtworkImageVideoMaterials(artwork, 'high');
    let video;
    const newArtworkMeshes = artworkMeshes.map((mesh: any)=>{
      mesh.setEnabled(true);
      const materialName = mesh.material.name.replace('LowQuality', '');
      mesh.material = artworkMaterials[materialName];
      if (artworkMaterials['imageVideoMaterial']) {
        const videoTexture = artworkMaterials['imageVideoMaterial'].diffuseTexture;
        if (videoTexture) {
          video = videoTexture.video;
        }
      }
      return mesh;
    });
    const artworkMergedHighQty = this.mergedMeshes(newArtworkMeshes, 'ArtworkMesh');
    artworkMergedHighQty['video'] = video;

    artworkMergedHighQty.parent = artworkNode;
    artworkMergedHighQty.scaling = artworkNode['artworkMergedLowQty'].scaling.clone();
    artworkMergedHighQty.position = artworkNode['artworkMergedLowQty'].position.clone();
    artworkMergedHighQty.setEnabled(false);
    artworkNode['artworkMergedHighQty'] = artworkMergedHighQty;

    this._setArtworkMeshLighting(artworkMergedHighQty, artwork);
    return artworkMergedHighQty;
  }

  /**
   * ANCHOR Create Material Names Data
   * @description to create material names data
   * @param quality : 'low' | 'high'
   * @returns : string[]
   */
  private _createMaterialNamesData(quality: 'low' | 'high') {
    let materialNames: string[] = [];
    if (quality === 'high') {
      materialNames = [
        'imageVideoMaterial',
        'passepartoutMaterial',
        'frameMaterial',
        'backFrameMaterial',
      ];
    } else {
      materialNames = [
        'imageVideoMaterialLowQuality',
        'passepartoutMaterialLowQuality',
        'frameMaterialLowQuality',
        'backFrameMaterialLowQuality',
      ];
    }

    return materialNames;
  }

  /**
   * ANCHOR Create Material Nodes
   * @param materialNames : string[]
   * @returns : BABYLON.StandardMaterial[]
   */
  private _createMaterialNodes(materialNames: string[]) {
    const materialNodes: any = {};
    materialNames.map((name) => {
      materialNodes[name] = new BABYLON.StandardMaterial(name, this.scene);
      materialNodes[name].maxSimultaneousLights = 7;
      materialNodes[name].specularColor = new BABYLON.Color3(0.03, 0.03, 0.03);
    });
    return materialNodes;
  }


  /**
   * ANCHOR Get Material Names
   * @description to get material names
   * @param quality : 'low' | 'high'
   * @returns : { imageVideoMaterialName: string, backFrameMaterialName: string, passeparoutMaterialName: string, frameMaterialName: string }
   */
  private _getMaterialNames(quality: 'low' | 'high') {
    return {
      imageVideoMaterialName: quality === 'high' ? 'imageVideoMaterial' : 'imageVideoMaterialLowQuality',
      backFrameMaterialName: quality === 'high' ? 'backFrameMaterial' : 'backFrameMaterialLowQuality',
      passeparoutMaterialName: quality === 'high' ? 'passepartoutMaterial' : 'passepartoutMaterialLowQuality',
      frameMaterialName: quality === 'high' ? 'frameMaterial' : 'frameMaterialLowQuality',
    };
  }



  /**
   * ANCHOR Set Artwork Position Rotation
   * @description to set artwork position rotation
   * @param wrapArtwork : BABYLON.TransformNode
   * @param artworkData : Artwork
   */
  private _setArtworkPositionRotation(wrapArtwork: any, artworkData: any) {
    wrapArtwork.position = new BABYLON.Vector3(
        artworkData.position.position_x,
        artworkData.position.position_y,
        artworkData.position.position_z,
    );
    wrapArtwork.rotation = new BABYLON.Vector3(
        artworkData.rotation.rotation_x,
        artworkData.rotation.rotation_y,
        artworkData.rotation.rotation_z,
    );
  }

  // SECTION Create Artwork Passpartout Mesh Functions
  //#region

  /**
   * ANCHOR Create Artwork Passepartout Mesh
   * @description to create artwork passepartout mesh
   * @param artwork : Artwork
   * @param material : BABYLON.StandardMaterial
   * @returns BABYLON.Mesh
   */
  private _createArtworkPasseMesh(artwork: IArtwork, material: any): any {
    const isUsePasse = artwork.frame.passepartout.passepartout;
    const zPosition = artwork.frame.back_frame.back_frame_depth/2;

    if (isUsePasse) {
      const passepartoutMesh = this._generatePassepartout(artwork, material);
      passepartoutMesh.position.z = zPosition;

      return passepartoutMesh;
    }

    return null;
  }

  /**
   * ANCHOR Create Artwork Passepartout Mesh (Cusotm Mesh)
   * @description to create artwork passepartout mesh
   * @param artworkData : Artwork
   * @param material : BABYLON.StandardMaterial
   * @returns : BABYLON.Mesh
   */
  private _generatePassepartout(artworkData: IArtwork, material: any): any {
    const passepartoutData = artworkData.frame.passepartout;

    const path  = [
      new BABYLON.Vector3(
          -(artworkData.real_width/2+passepartoutData.passepartout_width),
          -(artworkData.real_height/2+passepartoutData.passepartout_width),
          0,
      ),
      new BABYLON.Vector3(
          artworkData.real_width/2+passepartoutData.passepartout_width,
          -(artworkData.real_height/2+passepartoutData.passepartout_width),
          0,
      ),
      new BABYLON.Vector3(
          artworkData.real_width/2+passepartoutData.passepartout_width,
          (artworkData.real_height/2+passepartoutData.passepartout_width),
          0,
      ),
      new BABYLON.Vector3(
          -(artworkData.real_width/2+passepartoutData.passepartout_width),
          artworkData.real_height/2+passepartoutData.passepartout_width,
          0,
      ),
    ];

    const profilePoints = [
      new BABYLON.Vector3(0, passepartoutData.passepartout_depth, 0),
      new BABYLON.Vector3(0, 0, 0),
      new BABYLON.Vector3(-passepartoutData.passepartout_width, 0, 0),
      new BABYLON.Vector3(-passepartoutData.passepartout_width, passepartoutData.passepartout_depth, 0),
    ];

    const passepartout = this._frameMaker('passepartout', { path: path, profile: profilePoints });
    passepartout.material = material;

    return passepartout;
  }

  //#endregion
  // !SECTION


  // SECTION Craete Artwork Frame Mesh Functions
  //#region

  /**
   * ANCHOR Create Artwork Frame Mesh
   * @description to create artwork frame mesh
   * @param artwork : Artwork
   * @param material : BABYLON.StandardMaterial
   * @returns : BABYLON.Mesh
   */
  private _createArtworkFrameMesh(artwork: IArtwork, material: any): any {
    const isUseFrame = artwork.frame.frame.frame;
    const zPosition = artwork.frame.back_frame.back_frame_depth/2;

    if (isUseFrame) {
      const frame = this._generateFrame(artwork, material);
      frame.position.z = zPosition;
      return frame;
    }

    return null;
  }

  /**
   * ANCHOR Craete Artwork Frame Mesh (Custom Mesh)
   * @description to create artwork frame mesh (custom mesh)
   * @param artwork : Artwork
   * @param material : BABYLON.StandardMaterial
   * @returns : BABYLON.Mesh
   */
  private _generateFrame(artwork: IArtwork, material: any) {
    const passepartoutData = artwork.frame.passepartout;
    const frameData = artwork.frame.frame;

    const path  = [
      new BABYLON.Vector3(
          -(artwork.real_width/2+(passepartoutData.passepartout ? passepartoutData.passepartout_width:0)+frameData.frame_width),
          -(artwork.real_height/2+(passepartoutData.passepartout ? passepartoutData.passepartout_width:0)+frameData.frame_width),
          0),
      new BABYLON.Vector3(
          artwork.real_width/2+(passepartoutData.passepartout ? passepartoutData.passepartout_width:0)+frameData.frame_width,
          -(artwork.real_height/2+(passepartoutData.passepartout ? passepartoutData.passepartout_width:0)+frameData.frame_width),
          0),
      new BABYLON.Vector3(
          artwork.real_width/2+(passepartoutData.passepartout ? passepartoutData.passepartout_width:0)+frameData.frame_width,
          (artwork.real_height/2+(passepartoutData.passepartout ? passepartoutData.passepartout_width:0)+frameData.frame_width),
          0),
      new BABYLON.Vector3(
          -(artwork.real_width/2+(passepartoutData.passepartout ? passepartoutData.passepartout_width:0)+frameData.frame_width),
          artwork.real_height/2+(passepartoutData.passepartout ? passepartoutData.passepartout_width:0)+frameData.frame_width,
          0),
    ];

    const profilePoints = [
      new BABYLON.Vector3(0, frameData.frame_depth, 0),
      new BABYLON.Vector3(0, 0, 0),
      new BABYLON.Vector3(-frameData.frame_width, 0, 0),
      new BABYLON.Vector3(-frameData.frame_width, frameData.frame_depth, 0),
    ];

    const frame = this._frameMaker('frame', { path: path, profile: profilePoints });
    frame.material = material;

    return frame;
  }

  //#endregion
  // !SECTION


  // SECTION Create Artwork Image/Video Materials
  //#region

  /**
   * ANCHOR Create Artwork Image/Video Materials
   * @description to create artwork image/video materials
   * @param artwork : Artwork
   * @param quality : 'low' | 'high'
   * @returns BABYLON.StandardMaterial[]
   */
  private _createArtworkImageVideoMaterials(artwork: IArtwork, quality: 'low' | 'high'): any {
    const materialNames = this._createMaterialNamesData(quality);
    const materialNodes = this._createMaterialNodes(materialNames);
    const {
      imageVideoMaterialName,
      backFrameMaterialName,
      passeparoutMaterialName,
      frameMaterialName,
    } = this._getMaterialNames(quality);

    this._applyTextureToImageVideoMaterial(artwork, materialNodes[imageVideoMaterialName], quality);
    this._applyColorToBackFrameMaterial(artwork, materialNodes[backFrameMaterialName]);
    this._applyColorOrTextureToPasseMaterial(artwork, materialNodes[passeparoutMaterialName], quality);
    this._applyColorOrTextureToFrameMaterial(artwork, materialNodes[frameMaterialName], quality);

    return materialNodes;
  }

  /**
   * ANCHOR Apply Texture To Image/Video Material
   * @description to apply texture to image/video material
   * @param artwork : Artwork
   * @param material : BABYLON.StandardMaterial
   * @param quality : 'low' | 'high'
   */
  private _applyTextureToImageVideoMaterial(
      artwork: IArtwork, material: any, quality: 'low' | 'high',
  ): void {
    let texture;
    switch (artwork.file_type) {
      case 'figure-image':
        const imageTexture = quality === 'high' ? artwork.image : artwork.image_low_quality;
        texture = new BABYLON.Texture(imageTexture, this.scene);
        this._enableMipMap(texture);
        break;

      case 'video':
        if (quality == 'high') {
          texture = this._createTextureVideo(artwork.video_stream as string, artwork.id);
        } else {
          texture = new BABYLON.Texture(artwork.image, this.scene);
        }
        break;
    };
    material.diffuseTexture = texture;
  }

  /**
   * * CREATE TEXTURE VIDEO *
   * ANCHOR Create Texture Video
   * @description Create the texture video for the artwork image/video
   * @param videoStream : string -> the video stream
   * @returns : BABYLON.VideoTexture
   */
  private _createTextureVideo(videoStream: string, artworkId: string): any {
    const videoTexture = new BABYLON.VideoTexture(
        'video-'+ artworkId,
        videoStream,
        this.scene,
        false,
        false,
        BABYLON.Texture.TRILINEAR_SAMPLINGMODE,
        {
          loop: true,
          muted: true,
        },
    );

    return videoTexture;
  }

  /**
   * ANCHOR Apply Color To Back Frame Material
   * @description to apply color to back frame material
   * @param artwork : Artwork
   * @param material : BABYLON.StandardMaterial
   */
  private _applyColorToBackFrameMaterial(artwork: IArtwork, material: any): void {
    const color = artwork.frame.back_frame.back_frame_color;
    material.diffuseColor = BABYLON.Color3.FromHexString(color);
  }

  /**
   * ANCHOR Apply Color Or Texture To Passepartout Material
   * @description to apply color or texture to passepartout material
   */
  private _applyColorOrTextureToPasseMaterial(
      artwork: IArtwork, material: any, quality: 'low' | 'high',
  ): void {
    const passeData = artwork.frame.passepartout;
    const textureSource = this._getPasseTextureSource(artwork, quality);

    const usePasseTexture = passeData.passepartout_material_texture && passeData.passepartout_texture;
    const usePasseColor = passeData.passepartout_material_color;
    if (usePasseTexture) {
      material.diffuseTexture = new BABYLON.Texture(
          textureSource,
          this.scene,
      );
      this._enableMipMap(material.diffuseTexture);
    }
    if (usePasseColor) {
      material.diffuseColor = BABYLON.Color3.FromHexString(
          passeData.passepartout_color,
      );
    }
  }

  /**
   * ANCHOR Get Passe Texture Source
   * @description to get passe texture source
   * @param artwork : Artwork
   * @param quality : 'low' | 'high'
   * @returns : string | null
   */
  private _getPasseTextureSource(artwork: IArtwork, quality: 'low' | 'high'): string | null {
    const passeData = artwork.frame.passepartout;
    let textureSource;
    switch (quality) {
      case 'high': textureSource = passeData.passepartout_texture; break;
      case 'low': textureSource = passeData.passepartout_texture_low_quality; break;
    }
    return textureSource;
  }

  /**
   * ANCHOR Apply Color Or Texture To Frame Material
   * @description to apply color or texture to frame material
   * @param artwork : Artwork
   * @param material : BABYLON.StandardMaterial
   * @param quality : 'low' | 'high'
   */
  private _applyColorOrTextureToFrameMaterial(
      artwork: IArtwork, material: any, quality: 'low' | 'high',
  ): void {
    const frameData = artwork.frame.frame;
    const textureSource = this._getFrameTextureSource(artwork, quality);
    const useFrameTexture = frameData.frame_material_texture && frameData.frame_texture;
    const useFrameColor = frameData.frame_material_color;

    if (useFrameTexture) {
      material.diffuseTexture = new BABYLON.Texture(
          textureSource,
          this.scene,
      );
      this._enableMipMap(material.diffuseTexture);
    }

    if (useFrameColor) {
      material.diffuseColor = BABYLON.Color3.FromHexString(
          frameData.frame_color,
      );
    }
  }

  /**
   * ANCHOR Get Frame Texture Source
   * @description to get frame texture source
   * @param artwork : Artwork
   * @param quality : 'low' | 'high'
   * @returns : string | null
   */
  private _getFrameTextureSource(artwork: IArtwork, quality: 'low' | 'high'): string | null {
    const frameData = artwork.frame.frame;
    let textureSource;
    switch (quality) {
      case 'high': textureSource = frameData.frame_texture; break;
      case 'low': textureSource = frameData.frame_texture_low_quality; break;
    }
    return textureSource;
  }

  //#endregion
  // !SECTION

  //#endregion
  // !SECTION

  //#endregion
  // !SECTION

  /**
   * * ================================================================================================ *
   *   SECTION Load Exhbition
   * * ================================================================================================ *
   */
  //#region

  /**
   * * GET EXHIBITION PATH *
   * Todo: to get exhibition path such as model path or lightmap path
   * @param type : 'model' | 'lightmap' -> type of path
   * @returns : string -> exhibition path
   */
  private _getExhibitionPath(type: 'model' | 'lightmap'): string {
    const extension = this._getExtension(type);
    const path = type === 'model' ? this.exhibition.model_path : this.exhibition.light_map;
    const baseName = path.split('@')[0] + ((type !== 'model' && this.reloadTest) ? '-low-res' : '');
    return `${baseName}@${this.exhibition.model_type}.${extension}`;
  }

  /**
  * * GET EXHIBITION PATH EXTENSION *
  * Todo: to get exhibition path extension such as model path or lightmap path
  * @param type : 'model' | 'lightmap' -> type of path
  * @returns : string -> exhibition path extension
  */
  private _getExtension(type: 'model' | 'lightmap'): string {
    const path = type === 'model' ? this.exhibition.model_path : this.exhibition.light_map;
    return path.split('.').slice(-1)[0];
  }

  /**
   * ANCHOR Set Lightmap Path (use Image Proxy)
   * @description to set lightmap path (use image proxy)
   * @param path
   */
  private _setLightmapPath(path: string, size:number | string) {
    const base64 = Base64.encode(path);
    if (size === 'ori') return `${environment.imgUrl}cb:${this.appVersion}-${this.galleryVersion}/${base64}`;
    return `${environment.imgUrl}rs:fit:${size}:${size}:no:0/cb:${this.appVersion}-${this.galleryVersion}/${base64}`;
  }

  /**
   * ANCHOR Change Lightmap Quality
   * @description to change lightmap quality
   * @param quality : 'low' | 'medium' | 'high'
   */
  public async changeLightmapQuality(quality: 'low' | 'medium' | 'high' | 'ori' | 'very_low') {
    const newLighmapTexture:any = await this.loadTexture(this.exhibition['light_map_'+quality]);
    this.exhibitionMesh.getChildren().forEach((mesh: any) => {
      if (mesh.material.lightmapTexture) {
        if (mesh.material.lightmapTexture.uniqueId == newLighmapTexture.uniqueId) return;
        mesh.material.lightmapTexture?.dispose();
        mesh.material.lightmapTexture = newLighmapTexture;
        mesh.material.lightmapTexture.coordinatesIndex = 1;
      };
    });
  }

  /**
   * * LOAD EXHIBITION TO SCENE *
   * Todo: insert / load exhibition model into scene, the function type is Promise Obejct
   */
  public loadExhibtion(): Promise<any> {
    return new Promise((resolve, reject)=>{
      // Get key room
      this._folderName = this.exhibition.model_path.split('/').slice(-2)[0];
      if (this.exhibitionQuality == 'basic') {
        this.exhibition.model_path = this.exhibition.model_path.replace('-ori.glb', '-embeded-lightmap.glb');
      }

      // Get exhibition size
      const modelFileName = this.exhibition.model_path.split('/').slice(-1)[0].replace('.glb', '');
      this.model_size = this.exhibition.model_size_json ? this.exhibition.model_size_json[modelFileName] : this.exhibition.model_size;

      this.exhibition.model_type = this.exhibition.model_type ? this.exhibition.model_type : 'default';
      if (this.exhibitionQuality != 'basic') {
        this.exhibition.model_path = this._getExhibitionPath('model');
        if (this.exhibition.light_map) {
          const lightMapOri = this._getExhibitionPath('lightmap');
          this.exhibition.light_map_high = this._setLightmapPath(lightMapOri, 'ori');
          this.exhibition.light_map_medium = this._setLightmapPath(lightMapOri, 2000);
          this.exhibition.light_map_low = this._setLightmapPath(lightMapOri, 1000);
          this.exhibition.light_map_very_low = this._setLightmapPath(lightMapOri, 500);
          const deviceType = this.browserData.platform.type;
          switch (deviceType) {
            case 'mobile': this.exhibition.light_map = this.exhibition.light_map_low; break;
            case 'tablet': this.exhibition.light_map = this.exhibition.light_map_medium; break;
            case 'desktop': this.exhibition.light_map = this.exhibition.light_map_high; break;
            default: this.exhibition.light_map = this.exhibition.light_map_low; break;
          }
        }
      }
      // handler function if process load model to scene successfully
      const onSuccess = async (meshes:any) => {
        try {
          // load lightmap texture
          let lightMapTexture:any = null;
          if (this.exhibitionQuality != 'basic') {
            lightMapTexture = await this.loadTexture(this.exhibition.light_map);
          }

          // Create a container(Transform Node) for exhibition objects(meshes)
          const wrapExhibition = new BABYLON.TransformNode('room', this.scene);
          wrapExhibition.id = this.exhibition.id;

          if (this.exhibitionQuality != 'basic') {
            // Setup scene color (clearColor & ambientSceneColor)
            this.setupSceneColor();
            this.setupGlowEffect();

            if (this.exhibition.config?.useEnv) {
              await this.setupSceneEnvironment(this._folderName);
            }
          }


          let rootNode: any;

          if (this._folderName === 'FOUR-WALL') meshes.push(this._setInfinityFloor(meshes));
          meshes.forEach((mesh:any) =>{
            // Note: this condition is work form belfast gallery only
            if (mesh.name === 'Roof_Window_Glass' && this._folderName === 'BALCONY-GALLERY') {
              mesh.dispose();
              return;
            }

            if (mesh.name!='__root__') {
              this.mainLightArtwork.excludedMeshes.push(mesh);
              if (!mesh.name.toLowerCase().includes('environment')) {
                mesh.isPickable = true;
                mesh.id = wrapExhibition.id;

                this.setMeshCollision(mesh);
                this.hideInvisibleMesh(mesh);
                this.groupingMesh(mesh);
                if (this.exhibitionQuality != 'basic') {
                  this.applyLightmapTexture(mesh, lightMapTexture);
                }
                this.setAmbientColorExhibitionMesh(mesh);

                this._setFourWallCollision(this._folderName, mesh);

                mesh.setParent(wrapExhibition);
              } else {
                mesh.material.ambientColor = BABYLON.Color3.White();
                if (this._folderName === 'BALCONY-GALLERY') {
                  mesh.material.ambientColor = BABYLON.Color3.Black();
                  mesh.material.metallic = 1;
                }
                mesh.setParent(null);
              }
            } else {
              rootNode = mesh;
            }
          });

          if (this.exhibitionQuality != 'basic') {
            this.removeLigtmapForSomeMesh();
          }
          this.setCustomColors(this.exhibition, wrapExhibition);

          rootNode.dispose();
          if (this._folderName == 'FOUR-WALL') this._FogEffectFourWall();

          setTimeout(()=>{
            wrapExhibition.getChildren().map((mesh: any) => {
              mesh.cullingStrategy = BABYLON.AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;
              mesh.freezeWorldMatrix();
              mesh.doNotSyncBoundingInfo = true;
            });
          }, 100);

          wrapExhibition['dimensions'] = this.getNodeDimension(wrapExhibition);

          this.loadingProgress = 100;
          this.exhibitionMesh = wrapExhibition;

          if (this._folderName === 'NEW-WOODEN-GALLERY') {
            this.createMeshBlokerForNewHokaido();
          }

          if (this._folderName === 'BALCONY-GALLERY') {
            this.createMeshBlokerForBelfast();
          }

          if (this._folderName === 'FOUR-WALL') {
            const mesh = meshes.find((mesh:any) => mesh.name === 'color_floor_infiniti@Floor Color');
            mesh.material.ambientColor = BABYLON.Color3.FromHexString('#d6d6d6');
            mesh.material.lightmapTexture = null;
            mesh.isPickable = false;
          }

          this._setSceneMetadataRelatedWithExhibion();
          resolve(wrapExhibition);
        } catch (error) {
          reject(error);
        }
      };

      // handler function if process load model to scene still on progress
      const onProgress = (e:any) => {
        // Calculate the percentage so that the final value is 80%
        // The remaining 20% is used to load the lightmap  texture
        let percentage = Math.round(Math.round(e.loaded/this.model_size*100) / 1.25);
        if (percentage < 0) percentage = 0;
        this.loadingProgress = percentage;
      };

      // Load Exhibition
      this.loadingProgress = 0;
      const extention = this.exhibition.model_path.split('.').slice(-1)[0];
      BABYLON.SceneLoader.ImportMesh('', '', this.exhibition.model_path, this.scene, onSuccess, onProgress, reject, `.${extention}`);
    });
  }

  /**
   * ANCHOR Set Scene Metadata Related With Exhibition
   * @description to set scene metadata related with exhibition
   */
  private _setSceneMetadataRelatedWithExhibion() {
    const newMetadata = {
      exhibitions: {
        [this.exhibitionMesh.uniqueId]: {
          dragAreaMeshesUniqueIds: this.dragAreaMeshes.map((mesh: any) => mesh.uniqueId),
        },
      },
      activeExhibtionUniquId: this.exhibitionMesh.uniqueId,
    };
    this.scene.metadata = { ...this.scene.metadata, ...newMetadata };
  }

  private _blockerAnimation: any = [];
  createMeshBlokerForBelfast() {
    const data = [
      {
        position: new BABYLON.Vector3(-2.57, 6, 11.1),
        scaling: new BABYLON.Vector3( 0.095, 4, 20.5),
      },
      {
        position: new BABYLON.Vector3(2.5, 6, 21.4),
        scaling: new BABYLON.Vector3(10, 4, 0.095),
      },
    ];

    data.forEach((x) => {
      const meshBloker = BABYLON.MeshBuilder.CreateBox('meshBlokerAnimation', {}, this.scene);
      meshBloker.position = x.position;
      meshBloker.scaling = x.scaling;
      meshBloker.visibility = 0;
      meshBloker.isPickable = false;
      this._blockerAnimation.push(meshBloker);
    });
  }

  /**
   * * SETUP SCENE COLOR *
   * Todo: to setup scene color
   */
  setupSceneColor() {
    this.scene.clearColor = BABYLON.Color3.White();
    if (this.exhibition.config?.ambientSceneColor) {
      this.scene.ambientColor = new BABYLON.Color3(
          this.exhibition.config?.ambientSceneColor,
          this.exhibition.config?.ambientSceneColor,
          this.exhibition.config?.ambientSceneColor,
      );
    }
  }

  /**
   * * SETUP CEILING EXHIBITION *
   * Todo: to setup ceiling
   */
  private _setupCeilingExhibition(enabled:boolean = true): void {
    if (this.exhibition.config?.ceiling) {
      this.exhibition.config.ceiling.map((name:string) => {
        const node = this.scene.getMeshByName(name);
        if (node) node.setEnabled(enabled);
      });
    }
  }

  /**
   * * ANCHOR EXTEND FLOOR EXHIBITION OF FOUR-WALL *
   */
  private _setInfinityFloor(meshes:any[]=[]) : void {
    if (meshes && meshes.length > 0) {
      const mesh: any = meshes.find((mesh:any) => mesh.name.toLowerCase().includes('floor'));
      if (mesh) {
        const secondFloor = mesh.clone();
        secondFloor.name = 'color_floor_infiniti@Floor Color';
        secondFloor.position.y = -0.002;
        secondFloor.scaling = new BABYLON.Vector3(50, 50, 50);

        const secondFloorMat = mesh.material.clone();
        secondFloor.material = secondFloorMat;
        return secondFloor;
      }
    }
  }

  /*
  * ANCHOR SET COLLISION FOUR WALL
  */
  private _setFourWallCollision(folderName: string, mesh:any) {
    if (folderName === 'FOUR-WALL' && mesh.name.toLowerCase().includes('collision')) {
      mesh.position.y = -0.01;
      mesh.isPickable = false;
      return mesh;
    }
  }

  /**
   * * SETUP GLOW EFFECT *
   * Todo: to setup glow effect
   */
  setupGlowEffect() {
    if (this.exhibition.config?.useGlowEffect) {
      this.glowEffect = new BABYLON.GlowLayer('glow', this.scene);
      this.glowEffect.intensity = this.exhibition.config.glowIntesity;
    }
  }

  /**
   * ANCHOR Set Environment Intensity for Mesh of Exhibition
   * @description to set environment intensity for mesh of exhibition
   * @param mesh : BABYLON.Mesh
   */
  private _setEnvIntensity(mesh: any): void {
    if (this.exhibition.config?.useEnv) {
      mesh.material.environmentIntensity = this.exhibition.config.envIntensity;
    }
  }

  /**
   * * SETUP SCENE ENVIRONTMENT *
   * Todo: to setup scene envronment
   */
  setupSceneEnvironment(keyRoom: any) {
    return new Promise(async (resolve, reject)=>{
      try {
        if (this.exhibition.config?.useEnv) {
          this.scene.environmentTexture = await this.loadTexture(`${environment.exhibitionAssetsUrl}${keyRoom}/environment.env?${this.appVersion}`, true);
          this.scene.environmentIntensity = 1;
          if (this.exhibition.config?.envRotationY) {
            this.scene.environmentTexture.rotationY = this.exhibition.config.envRotationY;
          }
        }
        resolve(null);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * * GROUPING MESHES : DONUT AREA, STAIRS, DRAG AREA *
   * Todo: to grouping drag area, stairs and drag area meshes
   */
  groupingMesh(mesh : any) {
    const name = mesh.name.toLowerCase();
    if (name.includes('donut_area')) this.donutAreaMeshes.push(mesh);
    else if (name.includes('stairs')) this.stairsMeshes.push(mesh);
    else if (name.includes('drag_area')) this.dragAreaMeshes.push(mesh);
    else if (name.includes('collision_invisible')) this.colisionInvisibleMeshes.push(mesh);
  }

  /**
   * * HIDE INVISIBLE MESH (ROOM) *
   * Todo: to hide a mesh whose name contains the word 'invisible'
   */
  hideInvisibleMesh(mesh: any) {
    if (mesh.name.toLowerCase().includes('invisible')) {
      mesh.visibility = 0;
      mesh.isPickable = false;
      mesh.material.lightmapTexture = null;
      if (mesh.name.toLowerCase().includes('donut_area')||mesh.name.toLowerCase().includes('stairs')) {
        mesh.isPickable = true;
      }
    }
  }

  /**
   * * CREATE MESH BLOCKER FOR NEW HOKADIO ROOM [TEMPORARY CODE] *
   */
  createMeshBlokerForNewHokaido() {
    const meshBloker = BABYLON.MeshBuilder.CreateBox('meshBloker', {}, this.scene);
    meshBloker.position.z = -11;
    meshBloker.position.y = 1;
    meshBloker.scaling = new BABYLON.Vector3(10, 3, 0.05);
    meshBloker.visibility = 0;
    meshBloker.checkCollisions = false;
  }

  /**
   * * SET MESH COLLISION *
   * Todo: to setup mesh collision effect
   */
  setMeshCollision(mesh: any) {
    if ([ 'stairs_invisible', 'collision_invisible' ].includes(mesh.name.toLowerCase())) {
      mesh.checkCollisions = true;
    }
  }

  /**
  * * SET AMBIENT COLOR EXHIBITON MESH *
  * Todo: to setup ambient color exhibition mesh
  */
  setAmbientColorExhibitionMesh(mesh: any) {
    if (this.exhibitionQuality == 'basic') {
      mesh.material.ambientColor = new BABYLON.Color3(
          this.exhibition.light_intensity*4,
          this.exhibition.light_intensity*4,
          this.exhibition.light_intensity*4,
      );
    } else {
      mesh.material.ambientColor = new BABYLON.Color3(
          this.exhibition.light_intensity,
          this.exhibition.light_intensity,
          this.exhibition.light_intensity,
      );
      if (this.exhibition.config.folderName === 'FOUR-WALL') {
        mesh.material.environmentIntensity = 0;
      } else {
        mesh.material.environmentIntensity = this.exhibition.light_intensity * (this.exhibition.config.envIntensity / 2);
      }
    }
  }

  /**
  * * REMOVE LIGHTMAP TEXTURE FOR SOME MESH *
  * Todo: to remove lightmap texture for a mesh that belongs to a mesh without a lightmap
  */
  removeLigtmapForSomeMesh() {
    if (this.exhibition.config?.noLightmapMeshes) {
      this.exhibition.config.noLightmapMeshes.map((meshName:any)=>{
        const mesh = this.scene.getMeshByName(meshName);
        if (mesh) mesh.material.lightmapTexture = null;
      });
    }
  }

  /**
  * * APPLY LIGHTMAP TEXTURE TO EXHIBITION MODEL *
  * Todo: to apply lightmap texture to exhibition
  */
  applyLightmapTexture(mesh: any, lightMapTexture: any) {
    if (this.exhibition.light_map) {
      mesh.material.lightmapTexture = lightMapTexture;
      mesh.material.lightmapTexture.coordinatesIndex = this.exhibition.config.lightmapCoordinatesIndex;
      mesh.material.useLightmapAsShadowmap = true;
    }
  }

  /**
  * * SET COLOR OF EXHIBITION *
  * Todo: get the color changeable mesh on the exhibition model
  */
  setCustomColors(exhibitionData:any, exhibitionMesh:any) {
    // Get color meshes from model
    const colorMeshes = exhibitionMesh.getChildren().filter((child:any)=>child.name.toLowerCase().includes('color'));
    // Get color data from database and covert it to an array
    let colorsData = exhibitionData.color_config || '[]';
    if (typeof colorsData == 'string') colorsData = JSON.parse(colorsData.replace(/'/g, '\''));

    // Create Wrapper for colors (with meshes and without meshes)
    const colorsWithMeshes:any = [];
    const colors:any = [];

    colorMeshes.map((x:any)=>{
      // Get label name from model name
      const label = this.toUpperFirstLetter(
          x.name.toLowerCase().split('@').slice(-1)[0].split('_')[0].split('.')[0],
      );

      x.material = x.material.clone();
      x['original_color'] = x.material.albedoColor.clone();

      const colorData = colorsData.find((x:any)=>x.label==label);
      const i = colorsWithMeshes.findIndex((x:any) => x.label == label);

      if (i >= 0) {
        colorsWithMeshes[i].meshes.push(x);
      } else {
        colorsWithMeshes.push({
          label: label,
          color: (colorData) ? colorData.color : '#ffffff',
          meshes: [ x ],
          used_original: (colorData)? colorData.used_original : true,
        });
      }
    });

    colorsWithMeshes.map((color:any)=>{
      colors.push({
        label: color.label,
        used_original: color.used_original,
        color: color.color,
      });

      if (!color.used_original) {
        color.meshes.map((mesh:any)=>mesh.material.albedoColor = BABYLON.Color3.FromHexString(color.color));
      }
    });
    return {
      colorsWithMeshes,
      colors,
    };
  }

  //#endregion
  //!SECTION

  /**
   * * ================================================================================================ *
   *   SECTION Implement Audio Player
   * * ================================================================================================ *
   */
  //#region
  /**
   * ANCHOR Init Audio Player with URL
   * @description : init audio player with URL of audio file
   * @param src: audio file URL
   */
  private _initAudioPlayer(src:string): void {
    this.audioSupport = new Audio(src);

    this.audioSupport.addEventListener('loadeddata', () => {
      this.loadedAudio = true;
      this.durationAudio = `${this._getDurationAudio(this.audioSupport.duration)}`;
    },
    false,
    );

    //click on timeline to skip around
    setTimeout(() => {
      const timeline = document.querySelector('.timer-bar') as HTMLElement;
      timeline?.addEventListener('click', (e) => {
        const timelineWidth = window.getComputedStyle(timeline).width;
        const timeToSeek = e.offsetX / parseInt(timelineWidth) * this.audioSupport.duration;
        this.audioSupport.currentTime = timeToSeek;
      }, false);
    }, 500);
  }

  /**
   * ANCHOR Play Audio
   * @description : play audio file
   */
  public playAudio(): void {
    if (this.audioSupport.paused) {
      this.audioSupport.play();

      // set interval to update the time on the audio player
      this._timelineInterval = setInterval(() => {
        this.timerAudio = `${this._getCurrentTimeAudio(this.audioSupport.currentTime)}`;
      }, 1000);

      // update progress bar
      this._progressBarInterval = setInterval(() => {
        const progressBar = document.querySelector('.progress') as HTMLElement;
        if (progressBar) {
          progressBar.style.left = this.audioSupport.currentTime / this.audioSupport.duration * 100 + '%';
        }
      });
    } else {
      this.audioSupport.pause();
      clearInterval(this._timelineInterval);
      clearInterval(this._progressBarInterval);
    }
  }

  private _stopAudio(): void {
    if (this.audioSupport) this.audioSupport.pause();
    clearInterval(this._timelineInterval);
    clearInterval(this._progressBarInterval);
    this.timerAudio = '0:00';
    this.showAudioDetail = false;
    this.audioSupport = null;
    this.loadedAudio = false;
  }

  /**
   * ANCHOR Get Duration Audio
   * @description : get duration time of audio
   * @param duration : audio duration
   */
  private _getDurationAudio(duration:number): string {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.round(duration) % 60;

    return `${minutes < 0 ? '0':minutes.toString()}:${seconds.toString().padStart(2, '0')}`;
  }

  /**
   * ANCHOR Get current time of playing audio
   * @description : get current time of playing audio
   * @param currentTime : audio current time
   */
  private _getCurrentTimeAudio(currentTime:number):string {
    const minutes = Math.floor(currentTime / 60);
    const seconds = Math.round(currentTime) % 60;

    return `${minutes < 0 ? '0':minutes.toString()}:${seconds.toString().padStart(2, '0')}`;
  }

  /**
   * ANCHOR shift the artwork slightly forward
   * @description : shift the artwork slightly forward
   * @param camera : camera mode
   */
  private _handleArtworkBlinkingBehindWall(floatingCamera:boolean): void {
    const positionZ = floatingCamera ? 0.008 : -0.008;
    this.artworksNodes.map((artwork:any) => {
      artwork.translate(
          new BABYLON.Vector3(0, 0, positionZ), 1/artwork.scaling.z, BABYLON.Space.LOCAL,
      );
    });
  }

  //#endregion
  // !SECTION

  /**
   * * ================================================================================================ *
   *   SECTION Zoom in/out Artwork on focus functions
   * * ================================================================================================ *
   */
  //#region

  /**
   * ANCHOR Setup Camera Zoom Artwork
   * @description : enable zoom camera on artwork focus
   * @param: Artwork
   */
  public async setupCameraZoomArtwork(artwork: any): Promise<void> {
    const artworkContainer = this._cloneArtworkContainer(artwork);
    const zoomCamera = this._createZoomCamera(artworkContainer);

    this.camera.detachControl();
    this.scene.activeCamera = zoomCamera;

    zoomCamera.minZ = 0.01;
    zoomCamera.lowerRadiusLimit = await this._getMinimunRadius(artworkContainer, zoomCamera);
    zoomCamera.upperRadiusLimit = await this._getMaximunRadius(artworkContainer, zoomCamera);
    zoomCamera.lowerAlphaLimit = zoomCamera.upperAlphaLimit = this.scene.activeCamera.alpha;
    zoomCamera.lowerBetaLimit = zoomCamera.upperBetaLimit = this.scene.activeCamera.beta;

    this.scene.activeCamera.attachControl(this.canvas, true);
    artworkContainer.dispose();
  }

  /**
   * ANCHOR Create Zoom Camera
   * @description : create zoom camera
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @returns : BABYLON.ArcRotateCamera
   */
  private _createZoomCamera(artworkContainer: any): any {
    let zoomCamera = this.scene.getCameraByName('zoomCamera');
    if (zoomCamera) zoomCamera.dispose();

    zoomCamera = new BABYLON.ArcRotateCamera('zoomCamera', 0, 0, 0, new BABYLON.Vector3(0, 0, 0), this.scene);
    zoomCamera.wheelPrecision = 200;
    zoomCamera.panningSensibility = 0;
    zoomCamera.pinchPrecision = 200;
    if (this.isMobile) {
      zoomCamera.inputs.add(new BABYLON.ArcRotateCameraPointersInput);
    }


    zoomCamera.position = this.camera.position.clone();
    zoomCamera.setTarget(artworkContainer.position.clone());

    return zoomCamera;
  }

  /**
   * ANCHOR Reset Camera
   * @description : reset camera to main camera
   */
  public resetCameraZoomArtwork() {
    if (this.scene.activeCamera.name == 'zoomCamera') {
      const position = this.scene.activeCamera['_position'].clone();
      this.camera.position = position;
      this.scene.activeCamera.detachControl();
      this.scene.activeCamera = this.camera;
      this.scene.activeCamera.attachControl(this.canvas, true);
    }
  }

  // SECTION Calculate Minimum Radius
  //#region

  /**
   * ANCHOR Get Distance Camera To Artwork
   * @description : get distance camera to artwork
   * @param artworkContainer: BABYLON.Mesh -> Clone of artwork container
   * @param zoomCamera : BABYLON.ArRotateCamera -> Zoom camera
   * @returns : Promise<number>
   */
  private _getDistanceCameraToArtwork(artworkContainer: any, zoomCamera: any): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        setTimeout(() => {
          const ray = this.createRaycast({ node: zoomCamera, direction: artworkContainer.position });
          const distance = this.scene.pickWithRay(ray).distance;
          resolve(distance);
        }, 100);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * ANCHOR Get Minimum Radius
   * @description : get minimum radius
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @param zoomCamera : BABYLON.ArRotateCamera -> Zoom camera
   * @returns : Promise<number>
   */
  private async _getMinimunRadius(artworkContainer: any, zoomCamera: any): Promise<number> {
    let distance = await this._getDistanceCameraToArtwork(artworkContainer, zoomCamera);
    distance += 0.1;

    if (distance < zoomCamera.radius) return zoomCamera.radius * 0.18;
    else return zoomCamera.radius * 0.1;
  }

  //#endregion
  //!SECTION


  // SECTION Calculate Maximun Radius
  //#region

  /**
   * ANCHOR Get Maximun Radius
   * @description : get maximun radius
   * @param artworkContainer : BABYLON.Mesh -> Clone of artwork container
   * @param zoomCamera : BABYLON.ArRotateCamera -> Zoom camera
   * @returns : Promise<number>
   */
  private _getMaximunRadius(artworkContainer: any, zoomCamera: any): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        const directionMarker = BABYLON.MeshBuilder.CreateBox('box', { size: 1 }, this.scene);
        directionMarker.position = zoomCamera.position.clone();
        directionMarker.lookAt(artworkContainer.position);
        directionMarker.translate(new BABYLON.Vector3(0, 0, -3), 1, BABYLON.Space.LOCAL);

        const defaultDistance = BABYLON.Vector3.Distance(directionMarker.position, artworkContainer.position);

        setTimeout(() => {
          directionMarker.translate(new BABYLON.Vector3(0, 0, -100), 1, BABYLON.Space.LOCAL);

          const raycast = this.createRaycast({
            node: zoomCamera,
            direction: directionMarker.position,
          });

          directionMarker.dispose();

          const pickedPoint = this.scene.pickWithRay(raycast).pickedPoint;
          const distanceToObjectBehindCamera = BABYLON.Vector3.Distance(pickedPoint, artworkContainer.position);

          const distance = Math.min(defaultDistance, distanceToObjectBehindCamera);
          resolve(distance);
        }, 100);
      } catch (error) {
        reject(error);
      }
    });
  }

  //#endregion
  //!SECTION


  //#endregion
  //!SECTION

  /**
   * * ================================================================================================ *
   *   SECTION Utilites Functions
   * * ================================================================================================ *
   */
  // #region

  /**
   * ANCHOR Get Camera 'Y'  Position Based on the Footing Position
   * @description : to get camera 'Y' position based on the footing position
   * @param position : BABYLON.Vector3
   * @returns : Promise<number | null>
   */
  private async _getCameraYPosition(position: any): Promise<number | null> {
    const footingPosition = await this.getFootingPosition(position);
    if (!footingPosition) return null;
    const realHeight = this.exhibition.camera.height_camera / (49.75124378109452/100);
    return footingPosition.y + realHeight;
  }

  /**
   * ANCHOR Set Ease Mode for Animation
   * @description : to set ease mode for animation
   * @returns : BABYLON.CubicEase
   */
  private _setEaseMode(): any {
    const ease = new BABYLON.CubicEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    return ease;
  }

  /**
   * ANCHOR Get Distance To Front Object
   * @description to get distance to front object
   * @param artworkContainer: BABYLON.Mesh -> Clone of artwork container
   * @param watchPosition: BABYLON.Vector3 -> Watch position like a visitor in real life
   * @returns Number -> Distance
   */
  private _getDistanceToFrontObject(artworkContainer: any, watchPosition: any = null): Promise<number> {
    return new Promise((resolve, reject) => {
      try {
        const enablePickble = (enable: boolean) => {
          artworkContainer.isPickable = enable;
          artworkContainer['artworkNode'].getChildren().forEach((mesh: any) => mesh.isPickable = enable);
        };

        setTimeout(() => {
          const ray = this.createRaycast({
            node: artworkContainer,
            direction: watchPosition,
          });

          enablePickble(false);
          const distance = this.scene.pickWithRay(ray).distance;
          enablePickble(true);

          resolve(distance);
        }, 10);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * ANCHOR Clone Artwork Wrapper
   * @description to clone artwork wrapper based on artwork node transform values
   * @param artworkNode : BABYLON.TransformNode -> Container of artwork
   * @returns : BABYLON.Mesh
   */
  private _cloneArtworkContainer(artworkNode: any) {
    const meshParent = new BABYLON.Mesh('parent', this.scene);
    const currentTransform = {
      position: artworkNode.position.clone(),
      rotation: artworkNode.rotation.clone(),
    };

    artworkNode.position = BABYLON.Vector3.Zero();
    artworkNode.rotation = BABYLON.Vector3.Zero();

    artworkNode.getChildMeshes().map((mesh: any) => {
      const clonedMesh = mesh.clone();
      clonedMesh.setParent(meshParent);
    });

    const childMeshes = meshParent.getChildMeshes();
    let min = childMeshes[0].getBoundingInfo().boundingBox.minimumWorld;
    let max = childMeshes[0].getBoundingInfo().boundingBox.maximumWorld;

    for (let i=0; i<childMeshes.length; i++) {
      const meshMin = childMeshes[i].getBoundingInfo().boundingBox.minimumWorld;
      const meshMax = childMeshes[i].getBoundingInfo().boundingBox.maximumWorld;

      min = BABYLON.Vector3.Minimize(min, meshMin);
      max = BABYLON.Vector3.Maximize(max, meshMax);
    }

    meshParent.setBoundingInfo(new BABYLON.BoundingInfo(min, max));
    meshParent.showBoundingBox = true;

    const parentDimensions = (meshParent.getBoundingInfo().boundingBox.extendSizeWorld).scale(2);
    const boxWrapper = BABYLON.MeshBuilder.CreateBox(
        'boxWrapper',
        {},
        this.scene,
    );

    boxWrapper.position.copyFrom(meshParent.getBoundingInfo().boundingBox.centerWorld);
    boxWrapper.visibility = 0;
    boxWrapper.scaling = new BABYLON.Vector3(
        parentDimensions.x,
        parentDimensions.y,
        parentDimensions.z,
    );
    meshParent.dispose();

    const getRotationValue = (axis: 'x' | 'z') => {
      const rotateValue = artworkNode.rotation[axis];
      if (rotateValue >= -Math.PI/2 && rotateValue <= Math.PI/2) {
        return 0;
      } else {
        return -Math.PI;
      }
    };

    boxWrapper.setParent(artworkNode);
    artworkNode.position = currentTransform.position;
    artworkNode.rotation = currentTransform.rotation;
    boxWrapper.setParent(null);
    boxWrapper.rotation.y = artworkNode.rotation.y;
    boxWrapper.rotation.x = getRotationValue('x');
    boxWrapper.rotation.z = getRotationValue('z');
    boxWrapper['artworkNode'] = artworkNode;


    return boxWrapper;
  }



  // #endregion
  // !SECTION

  /**
   * * ================================================================================================ *
   *   SECTION Artwork Shadow Cast Functions
   * * ================================================================================================ *
   */
  //#region

  /**
   * ANCHOR Create Shadow Cast of Artwork
   * @description Create Shadow Cast of Artwork
   * @param artworkNode : BABYLON.TransformNode
   * @param dontSetPosition : boolean
   */
  public createShadowCast(artworkNode: any) {
    this._artworkShadowsService.setShadowCastMetadata(artworkNode);
    this._artworkShadowsService.addShadowCaster(artworkNode);
    this._artworkShadowsService.setShadowCastPosition(artworkNode);
  }

  //#endregion
  //!SECTION

  /**
   * * ================================================================================================ *
   *   SECTION Artwork Shadow Component Functions
   * * ================================================================================================ *
   */
  //#region

  /**
   * ANCHOR Create Shadow Components
   * @description Create Shadow Components
   * @param artworkNode : BABYLON.TransformNode
   */
  public createShadowComponents(artworkNode: any): void {
    const lights = this._artworkShadowsService.createShadowComponentLights(artworkNode);
    artworkNode.metadata = {
      ...artworkNode.metadata,
      shadowComponent: {
        lightIds: lights.map((light: any) => light.id),
      },
    };
    this._artworkShadowsService.setShadowComponentLightsPosition(artworkNode);
  }


  //#endregion
  //!SECTION

  /**
   * * ================================================================================================ *
   *  SECTION Text Wall Functions
   * * ================================================================================================ *
   */
  //#region

  /**
   * ANCHOR Load Text Image
   * @description : to load text image
   */
  public async loadTextImage(text: TextWall): Promise<any> {
    const wrapText = await this._textLoaderService.loadTextAsImage(text, this.scene);
    this.textNodes.push(wrapText);
    return wrapText;
  }

  /**
   * ANCHOR Change Text Wall Quality
   * @description : to change text wall quality
   * @param quality : 'very_low' | 'low' | 'medium' | 'high'
   */
  public async changeTextWallQuality(quality: 'very_low' | 'low' | 'medium' | 'high'): Promise<void> {
    for (let i=0; i<this.texts.length; i++) {
      await this._textLoaderService.changeQuality(this.texts[i], this.scene, quality);
    }
  }
  //#endregion
  //!SECTION

  /**
   * * ================================================================================================ *
   *   SECTION Uncategorized Functions
   * * ================================================================================================ *
   */
  //#region

  /**
   * * GET VIDEO DATA *
   * ANCHOR : to get video data
   * @description : to get video data
   * @param url : string -> url
   * @param type : type -> 'youtube' | 'vimeo'
   * @returns : Observable<any> -> response
   */
  public getVideoData(url: string, type: 'youtube' | 'vimeo') : Observable<any> {
    return this._http.get(`${environment.video_path}/${type}?url=${url}&audio=false`);
  }

  // ANCHOR : Tooltip for Artwork material detail
  public detailTooltip() {
    setTimeout(() => {
      const tooltip = document.querySelector('.tooltip') as HTMLElement;
      if (tooltip && tooltip.offsetHeight > 58) tooltip.style.display = 'block';
      else if (tooltip) tooltip.style.display = 'none';
    }, 100);
  }

  // ANCHOR : hidden sideContent on focus
  public hiddenSideContentOnFocus(): void {
    setTimeout(() => {
      const arrowSide = document.querySelector('.btn-side-content') as HTMLElement;
      const detailArtwork = document.querySelector('.wrap-detail') as HTMLElement;
      if (arrowSide && detailArtwork) {
        const positionArrow = arrowSide.getBoundingClientRect();
        if (detailArtwork.offsetHeight > positionArrow.top-2) this.hiddenSideContent = true;
        else this.hiddenSideContent = false;
      }
    }, 100);
  }

  /**
   * ANCHOR Validate Accessibility Gallery
   * @description : to validate accessibylity gallery
   */
  public validateAccessibilityGallery(): Observable<any> {
    let token = this._cookieService.get('villume_token_gallery');
    if (!token) token = '';

    const headers = new HttpHeaders({
      'Authorization': token,
    });
    return this._http.get(environment.baseURL + '/viewer-validation/' + this.exhibitionString, { headers });
  }

  /**
   * ANCHOR Validate White Label Gallery
   * @description : to validate white label gallery
   */
  public validateWhiteLabelGallery(domain:string, sid:string): Observable<any> {
    const params = {
      domain: domain,
      sid: sid,
    };
    return this._http.get(environment.baseURL + '/white-label/gallery', { params: params });
  }

  /**
   * ANCHOR Clear Index DB
   * @description : to clear index db
   */
  public clearIndexBD() {
    const req = indexedDB.deleteDatabase('babylonjs');
    req.onsuccess = function() {
      console.log('Deleted database successfully');
    };
    req.onerror = function() {
      console.log(`Couldn't delete database`);
    };
    req.onblocked = function() {
      console.log(`Couldn't delete database due to the operation being blocked`);
    };
  }

  //#endregion
  //!SECTION


  /**
   * ANCHOR Downgrade Quality of Exhibition
   * @description : to downgrade quality of exhibition
   */
  private _hasDowngradeQuality: boolean = false;
  public async downgradeQuality() {
    await this.changeLightmapQuality('very_low');
    await this.changeTextWallQuality('very_low');
  }

  /**
   * ANCHOR Detecting Performance Drop
   * @description : to detect performance drop (it will read FPS for 5 seconds,
   *                if fps is below 20 then it will be marked as 'drop quality' on mobile),
   *                Note: this function is will execute if tab browser is active
   */
  private _onDetectingPerformanceDrop: boolean = false;
  private _detectPerformanceDrop() {
    if (
      !this._hasDowngradeQuality &&
      !this._onDetectingPerformanceDrop &&
      this._startDetectingFPS &&
      this.allAssetsHasLoaded
    ) {
      const deviceType = this.browserData?.platform.type;
      if (deviceType !== 'mobile') return;
      if (this.engine.getFps() < 20) {
        this._onDetectingPerformanceDrop = true;
        setTimeout(async () => {
          if (this.engine.getFps() < 20 && this._startDetectingFPS) {
            await this.downgradeQuality();
            this._hasDowngradeQuality = true;
            this._onDetectingPerformanceDrop = false;
          } else {
            this._onDetectingPerformanceDrop = false;
          }
        }, 5000);
      }
    }
  }

  /**
   * ANCHOR Start Detecting FPS
   * @description : to update startDetectingFPS state based on tab browser state
   */
  private _startDetectingFPSTimeout: any = null;
  private _startDetectingFPS: boolean = false;
  private _registerDetectingFPSDrop() {
    this._startDetectingFPS = true;
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        clearTimeout(this._startDetectingFPSTimeout);
        this._startDetectingFPSTimeout = setTimeout(() => {
          this._startDetectingFPS = true;
        }, 5000);
      } else {
        this._startDetectingFPS = false;
      }
    });
  }

  /**
   * ANCHOR Check Support Webp
   * @description : to check support webp
   * @returns : boolean
   */
  public checkSupportWebp(): boolean {
    if (this.isBrowser) {
      const canvas = document.createElement('canvas');
      if (canvas.getContext && canvas.getContext('2d')) {
        return canvas.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * ANCHOR Get Latest Version
   * @description : to get latest version
   * @returns : Observable<any>
   */
  public getLatestVersion(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._http.get(`${environment.baseURL}/additional/latest-version`).subscribe({
        next: (response: any) => {
          this.appVersion = response.data.version.version_name;
          resolve();
        },
        error: reject,
      });
    });
  }

  /**
   * ANCHOR set Fog Effect four wall gallery
   * @description : to set fog effect
   */
  private _FogEffectFourWall() {
    const nodes = this.scene.getTransformNodeByName('room');
    const child = nodes.getChildren();

    for (let i=0; i<child.length; i++) {
      const mat = child[i].material;
      if (mat?.name === 'ALPHA') continue;

      const customPBRMat = new BABYLON.PBRCustomMaterial('customMat'+i, this.scene);
      customPBRMat.roughness = 1;
      customPBRMat.metallic = 0;
      customPBRMat.AddUniform('cameraPosition','vec3');

      for (var key in mat) {
        if (key !== 'name' && key !== 'decalMap' && mat.hasOwnProperty(key)) {
          customPBRMat[key] = mat[key];
          if (mat?.normalTexture) customPBRMat.normalTexture = mat.normalTexture.clone();
        }
      }

      const shaderConfig = `
        float dist = clamp(length(vPositionW) / 45.0, 0.0, 1.0);
        finalColor.rgb = mix(finalColor.rgb, vec3(1, 1, 1), dist);
      `

      customPBRMat.Fragment_Before_FragColor(shaderConfig);
      child[i].material = customPBRMat;
    };
  }

  //#endregion
  //!SECTION
}
