export const environment = {
  production: false,
  port: 4903,

  // Apps Urls
  baseHost: 'https://dev-gallery.villume.com',
  baseHostGET: 'http://127.0.0.1:4903',
  villumeAdminUrl: 'https://dev.villume.com',
  baseURL: 'https://app-dev.villume.com',

  // Villume Assets Urls
  imgUrl: 'https://img2.villume.com/v/',
  exhibitionAssetsUrl: 'https://assets.villume.com/DEV/',
  assetsUrl: 'https://app-dev.villume.com/asset/',
  video_path: 'https://node.villume.com',
  staticAssets: 'https://assets.villume.com/DEV/assets/',
  tts_host: 'https://tts.gothru.co',

  // Cors Config
  enableCors: true,
  allowedOrigins: [
    'dev-gallery.villume.com',
    '127.0.0.1:4903',
    '192.168.1.93:4903',
    'https://dev-gallery.villume.com',
    'http://127.0.0.1:4903',
  ],
};
