<div id="viewer" class="wrap-viewer">

	<!------------------ START MENU HEADER ----------------->
	<div class="menu-button">
		<ul>
			<li class="widget-position top-left" *ngIf="mainService.showDetailArtwork&&isActiveExhibition">
				<button [class.hidden]="mainService.showDetail && isActiveExhibition "
					class="btn btn-circle btn-outline-primary info-animated" (click)="onDetail()">
					<span class="vi vi-icon-info"></span>
				</button>
			</li>
			<!-- BOTTOM LEFT MENU -->
			<li class="widget-position bottom-left">

				<!--* BUTTON HELP --->
				<button class="btn btn-circle btn-help" (click)="handleShowHelp()">
					<div class="tips-reference help-tips" data-tips-feature="Help Center"></div>
					<i class="vi vi-help"></i>
				</button>

				<!--* BUTTON FULL SCREEN --->
				<button *ngIf="!mainService.isIOS" class="btn btn-circle btn-fullscreen" (click)="fullscreenMode()">
					<div class="tips-reference fullscreen-tips" data-tips-feature="Fullscreen"></div>
					<i *ngIf="!fullscreen" class="vi vi-fullscreen"></i>
					<i *ngIf="fullscreen" class="vi vi-close-fullscreen"></i>
				</button>

				<!--* BUTTON DOLLHOUSE --->
				<div class="tips-reference birds-eye-tips" data-tips-feature="Bird's Eye View"></div>
				<button *ngIf="!mainService.onFloatingCamera"
					[disabled]="mainService.animationIsRun || mainService.unselectAnimationIsRunning"
					class="btn btn-circle btn-camera" (click)="handleChangeCameraView()">
					<i class="vi vi-cube"></i>
				</button>

				<button *ngIf="mainService.onFloatingCamera"
					[disabled]="mainService.animationIsRun || mainService.unselectAnimationIsRunning"
					class="btn btn-circle btn-avatar" (click)="handleChangeCameraView()">
					<i class="vi vi-avatar"></i>
				</button>
			</li>
			<!-- BOTTOM CENTER MENU -->
			<li class="widget-position bottom-center" *ngIf="mainService.artworks.length > 1">

				<!--* MENU PLAY --->
				<ul class="menu-play" data-tips-feature="Room Tour">
					<li class="item-menu">
						<button [disabled]="mainService.animationIsRun||mainService.runRoomTour" (click)="prevNextArtwork('prev')">
							<i class="vi vi-prev-play"></i>
						</button>
					</li>
					<li class="item-menu">
						<button [disabled]="mainService.animationIsRun" *ngIf="!mainService.runRoomTour" (click)="roomTour('play')">
							<i class="vi vi-play"></i>
						</button>
						<button *ngIf="mainService.runRoomTour" (click)="roomTour('pause')">
							<i class="vi vi-pause"></i>
						</button>
					</li>
					<li class="item-menu">
						<button [disabled]="mainService.animationIsRun||mainService.runRoomTour" (click)="prevNextArtwork('next')">
							<i class="vi vi-next-play"></i>
						</button>
					</li>
				</ul>

			</li>
			<!-- TOP RIGHT MENU -->
			<li class="widget-position top-right">
				<!--* BUTTON UNDO --->
				<button *ngIf="!mainService.showDetailArtwork" data-tips-feature="Undo Action"
					class="btn btn-circle btn-undo-action" (click)="resetCamera()"><i class="vi vi-refresh-left"></i></button>
				<button *ngIf="mainService.showDetailArtwork && !mainService.showDetail" (click)="mainService.unselectArtwork()"
					class="btn btn-circle"><i class="vi vi-x"></i></button>
			</li>
			<!-- BOTTOM RIGHT MENU -->
			<ng-container *ngIf="!mainService.whiteLabel">
				<li class="widget-position bottom-right">

					<!--* SUB MENU LIST --->
					<!--*  BRAND -->
					<ul class="sub-menu">
						<li class="item-sub-menu show-help">
							<!-- <a href="javascript:;" (click)="showHelp=true">Help</a> -->
						</li>

						<li class="item-sub-menu">
							<!-- {{mainService.engine?.getFps().toFixed()}} FPS -->
							<a [href]="villumeAdminUrl" target="_BLANK">
								<img [src]="env.staticAssets + 'images/other/yellow-box.png?t=' + mainService.appVersion" alt=""
									class="box">
								<img [src]="env.staticAssets + 'images/other/logo-light.svg?t=' + mainService.appVersion" alt=""
									[ngStyle]="{'display': mainService.lightMode ? 'inline-flex' : 'none'}">
								<img [src]="env.staticAssets + 'images/other/logo.svg?t=' + mainService.appVersion" alt=""
									[ngStyle]="{'display': !mainService.lightMode ? 'inline-flex' : 'none'}">
							</a>
						</li>
					</ul>

				</li>
			</ng-container>
		</ul>
	</div>
	<!--============== END OF MENU HEADER =============-->

	<!--====== BUTTON SHOW SIDE CONTENT ======-->
	<div *ngIf="isActiveExhibition" class="btn-side-content" [class.hidden]="mainService.hiddenSideContent"
		[ngClass]="mainService.openSideContent ? 'active-btn' : 'no-active'">
		<button data-tips-feature="About Exhibition" class="btn btn-side-arrow" (click)="toggleSidebar();">
			<i class="vi vi-arrow-left" *ngIf="!mainService.openSideContent"></i>
			<i class="vi vi-arrow-right" *ngIf="mainService.openSideContent"></i>
		</button>
	</div>

	<!--================= SIDE CONTENT ================-->
	<div *ngIf="mainService.isBrowser" class="wrap-side-content"
		[ngClass]="mainService.openSideContent ? 'active-content' : 'no-active' ">
		<div [class.hidden]="mainService.sideContent !== 'about-exhibition'">
			<!-- CONTENT ABOUT EXHIBITION -->
			<app-about-exhibition></app-about-exhibition>
		</div>
		<div [class.hidden]="mainService.sideContent !== 'list-artwork'">
			<!-- CONTENT LIST ARTWOK -->
			<app-artwork-list></app-artwork-list>
		</div>
		<div [class.hidden]="mainService.sideContent !== 'more-from-creator'">
			<!-- CONTENT MORE FROM CREATOR -->
			<app-more-from-author></app-more-from-author>
		</div>
	</div>

	<!--====== DETAIL EXHIBITION ======-->
	<div @easeInOut [@.disabled]="!mainService.isMobile" class="wrap-detail" id="detail-artwork"
		[class.video]="mainService.showVideoDetail"
		[class.audio]="mainService.showAudioDetail"
		[class.no-price]="!mainService.activeArtwork.pricing_amount"
		*ngIf="
			mainService.showDetail&&
			isActiveExhibition&&
			mainService.activeArtwork.artwork_info&&
			mainService.activeArtwork.file_type!=='video'">
		<div class="header">
			<h4 class="title">{{mainService.activeArtwork.name}}{{mainService.activeArtwork.year? ", " +
				mainService.activeArtwork.year : ""}}</h4>
			<span class="dimension">{{mainService.activeArtwork.dimension}}</span>

			<div class="wrap-tooltip">
				<span class="material">
					{{mainService.activeArtwork.material}}
				</span>
				<div class="tooltip">{{mainService.activeArtwork.material}}</div>
			</div>

			<div class="author">
				<div *ngIf="!ttsService.ttsExisted"></div>
				<button *ngIf="ttsService.ttsExisted" class="speech btn" (click)="ttsService.toggleTextToSpeech()">
					<i class="vi vi-speech" [class.off]="!ttsService.ttsSpeaking"></i>
				</button>
				<span class="painter">by {{mainService.userInfo.first_name+" "+ mainService.userInfo.last_name}}</span>
			</div>

			<!--* BUTTON CLOSE --->
			<button *ngIf="mainService.isMobile" (click)="mainService.showDetail = false;mainService.audioSupport?.pause();"
				class="btn btn-close">
				<i class="vi vi-x"></i>
			</button>
			<button *ngIf="!mainService.isMobile" (click)="mainService.unselectArtwork()" class="btn btn-close">
				<i class="vi vi-x"></i>
			</button>
		</div>

		<div class="content-detail">
			<div class="wrap-shadow">
				<div class="wrap-desc" [class.video]="mainService.showVideoDetail" [class.audio]="mainService.showAudioDetail" id="on-detail">
					<p class="desc" id="detail">{{mainService.activeArtwork.description}}</p>
				</div>

				<!-- overlay -->
				<div
					id="overlay-details"
					[class.video]="mainService.showVideoDetail"
					[class.audio]="mainService.showAudioDetail"
					[class.no-price]="!mainService.activeArtwork.pricing_amount"></div>
			</div>

			<span class="price" *ngIf="mainService.activeArtwork.pricing_amount">
				{{mainService.activeArtwork.pricing_amount}}
			</span>

			<div class="preview">
				<!-- video player -->
				<div *ngIf="mainService.showVideoDetail" class="wrap-video">
					<video controls>
						<source [src]="mainService.videoSupport" type="video/mp4">
						Your browser does not support HTML video.
					</video>
				</div>

				<!-- audio player -->
				<div class="audio-player" *ngIf="mainService.showAudioDetail">
					<!-- button play or pause -->
					<div class="button">
						<button *ngIf="mainService.audioSupport?.paused" class="button__play" (click)="mainService.playAudio()"
							[disabled]="!mainService.loadedAudio">
							<i class="vi vi-play"></i>
						</button>
						<button *ngIf="!mainService.audioSupport?.paused" class="button__pause"
							(click)="mainService.playAudio()" [disabled]="!mainService.loadedAudio">
							<i class="vi vi-pause"></i>
						</button>
					</div>

					<!-- Progress audio -->
					<div class="timer-bar">
						<div class="progress"></div>
					</div>

					<!-- duration time -->
					<div class="duration">
						<div class="text-right">
							<span class="timer">{{ mainService.timerAudio }}</span>
							<!-- <span class="timer"> / {{ durationAudio }}</span> -->
						</div>
					</div>
				</div>
			</div>

			<button *ngIf="mainService.showRequestButton" (click)="mainService.requestArtwork()"
				class="btn btn-outline-primary">
				{{mainService.activeArtwork.request_artwork.request_text_button}}
			</button>
		</div>
	</div>
	<!--=== END OF DETAIL EXHIBITION ===-->

	<!--================= WRAPPER CANVAR ================-->
	<div class="wrap-canvas">
		<canvas touch-action="none" id="viewerScene"></canvas>
	</div>

	<div class="overlay-tooltip" data-tips-feature="Camera Controls" id="camera-controls-tips"></div>
	<div class="overlay-tooltip" data-tips-feature="Hover Info" id="hover-info-tips"></div>
	<div class="overlay-tooltip" data-tips-feature="Focus on Artwork" id="focus-on-artwork-tips"></div>

	<!-- HELP DIALOG -->
	<ng-container *ngIf="showHelp">
		<section class="overlay-dialog">
			<button *ngIf="mainService.isMobile" class="btn-close" (click)="closeHelp()"><i class="vi vi-x"></i></button>
			<div class="wrap-dialog">
				<div class="header">
					<h2 class="title">Navigation</h2>
					<button *ngIf="!mainService.isMobile" class="btn-close" (click)="closeHelp()"><i class="vi vi-x"></i></button>
				</div>
				<div class="body">
					<app-help (isMobile)="showMobile"></app-help>
				</div>
				<div class="footer">
					<button *ngIf="!mainService?.isMobile" class="btn-gray" (click)="closeHelp()">
						Close
					</button>
					<button *ngIf="mainService?.isMobile" class="btn-gray" (click)="closeHelp()">
						<i class="vi vi-arrow-left"></i> Back to the Gallery
					</button>
				</div>
			</div>
		</section>
	</ng-container>

	<!-- MESSAGE DIALOG -->
	<ng-container *ngIf="mainService.fpsDown">
		<section class="overlay-dialog">
			<div class="wrap-dialog alert">
				<div class="header">
					<h4 class="title">Warning</h4>
				</div>
				<div class="body">
					<p class="desc">
						FPS has been identified to be dropping. To maintain performance, we advise using simple mode.
					</p>
				</div>
				<div class="footer">
					<button class="btn" [disabled]="loading" (click)="mainService.fpsDown=false">
						No
					</button>
					<button class="btn" [class.loading-button]="loading" [disabled]="loading" (click)="toBaseModel()">
						Yes
					</button>
				</div>
			</div>
		</section>
	</ng-container>

	<div *ngIf="!mainService.allAssetsHasLoaded" class="place-spinner">
		<div class="ring-spinner">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>

	<div *ngIf="mainService.engine" class="wrap-fps">
		{{ getFPS() }} FPS
	</div>

	<section *ngIf="chooseQuality" class="overlay-dialog" style="z-index: 1002;">
		<div class="wrap-dialog alert">
			<div class="header"></div>
			<div class="body">
				<p class="desc">
					Please choose quality
				</p>
				<button class="btn" style="margin-right: 10px;" (click)="selectQuality('low')">
					Low
				</button>
				<button class="btn" style="margin-right: 10px;" (click)="selectQuality('medium')">
					Medium
				</button>
				<button class="btn" (click)="selectQuality('ori')">
					Original/High
				</button>
			</div>
			<div class="footer"></div>
		</div>
	</section>
</div>
<div *ngIf="showSplashScreen" class="wrap-loading">
	<app-splash-screen-preview 
		[loading]="mainService.onLoadingData"
		[options]="splashScreens"
		[preview]="previewCreate"
		[lightTheme]="mainService.lightMode"
		(onHide)="showSplashScreen=false;showTips();">
	</app-splash-screen-preview>
</div>

<app-not-subs-dialog [showNotSubs]="mainService.showNotSubsDialog" (hide)="mainService.showNotSubsDialog=$event">
</app-not-subs-dialog>

<!-- MESSAGE -->
<app-message></app-message>