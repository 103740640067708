import { ɵɵdefineInjectable, ɵɵinject, Injectable, Inject, EventEmitter, Component, ElementRef, Input, Output, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { take, shareReplay } from 'rxjs/operators';
import { DOCUMENT, CommonModule } from '@angular/common';

class ScriptService {
    constructor(_document) {
        this._document = _document;
        this.scriptsLoaders = {};
    }
    _loadScript(url, attributes, targetEl = 'head') {
        return new Observable((observer) => {
            const script = this._document.createElement('script');
            if (attributes) {
                for (const key in attributes) {
                    if (attributes.hasOwnProperty(key)) {
                        script.setAttribute(key, attributes[key]);
                    }
                }
            }
            script.onload = (event) => {
                observer.next(event);
                observer.complete();
            };
            script.onerror = err => {
                observer.error(err);
            };
            script.src = url;
            const targetElement = typeof targetEl === 'string' ? this._document.querySelector(targetEl) : targetEl;
            targetElement.appendChild(script);
        });
    }
    /**
     * Injects script from given url to target place in DOM
     * This method loads script from same url once.
     *
     * @param url Url of the external script to be loaded
     * @param attributes Attribute list to be added to the script element
     * @param targetEl Target element for the placing script tag. It can be a selector or a element reference
     */
    loadScript(url, attributes, targetEl = 'head') {
        return this.scriptsLoaders[url] = this.scriptsLoaders[url] || this._loadScript(url, attributes, targetEl)
            .pipe(take(1), shareReplay(1));
    }
    /**
     * Injects script from given url to target place in DOM
     * If you call this method for same url multiple times, it will inject same code to document multiple times.
     *
     * @param url Url of the external script to be loaded
     * @param attributes Attribute list to be added to the script element
     * @param targetEl Target element for the placing script tag. It can be a selector or a element reference
     */
    runScript(url, attributes, targetEl = 'head') {
        return this._loadScript(url, attributes, targetEl);
    }
}
ScriptService.ɵprov = ɵɵdefineInjectable({ factory: function ScriptService_Factory() { return new ScriptService(ɵɵinject(DOCUMENT)); }, token: ScriptService, providedIn: "root" });
ScriptService.decorators = [
    { type: Injectable, args: [{
                providedIn: 'root'
            },] }
];
ScriptService.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: [DOCUMENT,] }] }
];

class ScriptComponent {
    constructor(scriptService, element) {
        this.scriptService = scriptService;
        this.element = element;
        this.attributes = {};
        this.load = new EventEmitter();
        this.error = new EventEmitter();
    }
    ngOnInit() {
        this.scriptService.loadScript(this.src, this.attributes, this.element.nativeElement)
            .subscribe((event) => {
            this.load.emit(event);
        }, (error) => {
            this.error.emit(error);
        });
    }
}
ScriptComponent.decorators = [
    { type: Component, args: [{
                selector: 'ngx-script',
                template: ''
            },] }
];
ScriptComponent.ctorParameters = () => [
    { type: ScriptService },
    { type: ElementRef }
];
ScriptComponent.propDecorators = {
    src: [{ type: Input }],
    attributes: [{ type: Input }],
    load: [{ type: Output }],
    error: [{ type: Output }]
};

class ScriptLoaderModule {
}
ScriptLoaderModule.decorators = [
    { type: NgModule, args: [{
                declarations: [
                    ScriptComponent
                ],
                imports: [
                    CommonModule
                ],
                exports: [
                    ScriptComponent
                ]
            },] }
];

/*
 * Public API Surface of ngx-script-loader
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ScriptComponent, ScriptLoaderModule, ScriptService };

