import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { environment } from '@environments';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-not-subs-dialog',
    templateUrl: './not-subs-dialog.component.html',
    styleUrls: ['./not-subs-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf],
})
export class NotSubsDialogComponent {
  @Input() showNotSubs: boolean = false;
  @Output() hide = new EventEmitter();

  closeDialog() {
    this.showNotSubs = false;
    this.hide.emit(false);
  }

  subscribe() {
    window.open(`${environment.villumeAdminUrl}/pricing`, '_blank');
  }
}
