import { Component } from '@angular/core';
import { MainService } from '@services/main.service';
import { NgFor, NgStyle, NgIf } from '@angular/common';

@Component({
    selector: 'app-artwork-list',
    templateUrl: './artwork-list.component.html',
    styleUrls: ['./artwork-list.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        NgStyle,
        NgIf,
    ],
})
export class ArtworkListComponent {
  constructor( public mainService: MainService ) {}

  public back() {
    this.mainService.sideContent = 'about-exhibition';
    this.mainService.unselectArtwork();
  }
}
