import { Injectable, afterNextRender } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private cookieStore: any;

  constructor(
    private cookieService: SsrCookieService,
  ) {
    this.cookieStore = this.cookieService.get('villume_token');
    afterNextRender(() => {
      this.parseCookies(document.cookie);
    });
  }

  get(key: string) {
    return this.cookieStore[key];
  }

  public parseCookies(cookies:string) {
    this.cookieStore = {};
    if (!!cookies === false) {
      return;
    }
    const cookiesArr = cookies.replace(/ /g, '').split(';');
    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=');
      this.cookieStore[cookieArr[0]] = cookieArr[1];
    }
  }

  /**
   * ANCHOR Clear All Cookies
   * @description clear all cookies
   * @returns : void
   */
  public clearAllCookies() {
    const cookies = document.cookie.split("; ");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}
