import { Component } from '@angular/core';
import { environment } from '@environments';
import { MainService } from '@services/main.service';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {

  constructor(private mainService: MainService) { }
  ngOnInit() {
    if (this.mainService.isBrowser)
      window.location.href = `${environment.villumeAdminUrl}/404`;
  }
}
