<!-- Mobile -->
<ng-container *ngIf="mainService.isMobile">
	<div #bgMobile class="wrapper" [ngClass]="colorTone">
		<div class="content-device">
			<div
				class="overlay"  [ngClass]="colorTone">
				<h1
					*ngIf="options.header && options.active"
					#elHeaderMobile
					class="header splash-text" 
					[class]="'text-'+options.header_align">
					{{options.header}}
				</h1>
				<p 
					*ngIf="options.active"
					class="body splash-text"
					[class]="'text-'+options.body_align">
					{{options.body}}
				</p>

				<div class="loading">
					<div class="cubic">
							<img *ngIf="!mainService.whiteLabel" [src]="env.staticAssets+'images/other/yellow-box.png?t='+mainService.appVersion" alt="">
							<img *ngIf="mainService.whiteLabel" [src]="env.staticAssets+'images/other/grey-box.png?t='+mainService.appVersion" alt="">
					</div>
				</div>

				<button class="btn" class="btn" (click)="onHide.emit(true)" [ngClass]="colorTone">
					{{options.active ? options.button_text : 'Enter Gallery'}}
				</button>
			</div>
		</div>
	</div>
</ng-container>

<!-- Desktop -->
<ng-container *ngIf="!mainService.isMobile">
	<div #bgDesktop class="wrapper" [ngClass]="colorTone">
		<div *ngIf="options.image"
			class="overlay" 
			[class]="'place-' + options.text_placement + ' ' + colorTone">
		</div>
		<div class="container-preview" [class.center]="options.text_placement===3">

			<div
				#desc
				class="item-desc"
				[ngClass]="'position-'+options.text_placement"
				[class.center]="options.text_placement===3">
        <div #containerHeader *ngIf="options.header && options.active" class="container-header">
          <h1 
            #elHeaderDesktop
            class="header splash-text" 
            [ngClass]="colorTone"
						[class]="'text-'+options.header_align">
            {{options.header}}
          </h1>
        </div>
        <div *ngIf="options.body && options.active" class="container-body">
          <p 
            class="body splash-text"
            [ngClass]="colorTone"
						[class]="'text-'+options.body_align">
            {{options.body}}
          </p>
        </div>
				<button
					class="btn hidden center"
					(click)="onHide.emit(true)"
					[ngClass]="colorTone"
					[hidden]="options.text_placement!==3">
					{{options.active ? options.button_text : 'Enter Gallery'}}
				</button>

				<div class="loading center" [hidden]="options.text_placement!==3">
					<div class="cubic">
							<img *ngIf="!mainService.whiteLabel" [src]="env.staticAssets+'images/other/yellow-box.png?t='+mainService.appVersion" alt="">
							<img *ngIf="mainService.whiteLabel" [src]="env.staticAssets+'images/other/grey-box.png?t='+mainService.appVersion" alt="">
					</div>
				</div>
			</div>

			<button
				#buttonEnter
				class="btn hidden"
				(click)="onHide.emit(true)"
				[ngClass]="colorTone"
				[hidden]="options.text_placement===3">
				{{options.active ? options.button_text : 'Enter Gallery'}}
			</button>

			<div #loadingAnimation class="loading" [hidden]="options.text_placement===3">
				<div class="cubic">
						<img *ngIf="!mainService.whiteLabel" [src]="env.staticAssets+'images/other/yellow-box.png?t='+mainService.appVersion" alt="">
						<img *ngIf="mainService.whiteLabel" [src]="env.staticAssets+'images/other/grey-box.png?t='+mainService.appVersion" alt="">
				</div>
			</div>
		</div>
	</div>
</ng-container>