<div class="wrap-list-artwork">
    <button class="btn btn-goback" (click)="back()">
        <i class="vi vi-arrow-left"></i>
        Back
    </button>

    <div class="wrap-user">
        <img 
            [src]="mainService.userInfo.avatar" 
            alt="" 
            class="img-avatar">
        <a 
            target="_BLANK"
            [href]="mainService.villumeAdminUrl + '/' + mainService.userInfo.username">
            {{mainService.userInfo.first_name+" "+ mainService.userInfo.last_name}}
        </a> 
    </div>

    <div class="content-list-artwork">
        <div class="item-artwork" *ngFor="let item of mainService.artworks">
            <div class="display-flex">
                <img [src]="item.image" loading="lazy" alt="" class="thumbnail">
                <div class="wrap-figure-name">
                    <h5 class="title">{{item.name}}</h5>
                    <span class="sub-title">by {{mainService.userInfo.first_name+" "+ mainService.userInfo.last_name}}</span>
                    <span class="year">{{item.year}}</span>
                    <span class="show-more" [ngStyle]="{'display': item.id === mainService.activeArtwork?.id ? 'none' : 'block'}">...</span>
                </div>
            </div>
            <div 
                *ngIf="item.id===mainService.activeArtwork?.id&&mainService.activeArtwork?.file_type!=='video'" 
                class="content-more" 
                [ngStyle]="{'display': 'block'}">
                <span class="sub-text">{{mainService.activeArtwork.dimension}}</span>
                <p class="sub-text">{{mainService.activeArtwork.material}}</p>
                <div class="desc">
                    {{item.description}}
                </div>
                <span *ngIf="mainService.activeArtwork.pricing_amount" class="sub-text price">
                    {{item.pricing_amount}}
                </span>
                <div *ngIf="mainService.activeArtwork.request_artwork.request_button" class="wrap-btn">
                    <button                        
                        (click)="mainService.requestArtwork()"
                        class="btn btn-outline-primary">
                        {{mainService.activeArtwork.request_artwork.request_text_button}}
                    </button>
                </div>
            </div>

            <a 
                href="javascript:;" 
                class="link-artwork" 
                (click)="mainService.selectArtwork(item.id)" 
                [ngStyle]="{'display': item.id===mainService.activeArtwork?.id ? 'none' : 'block'}">
            </a>
        </div>
    </div>
</div>