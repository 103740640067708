// Angular built-in components/methods
import { Injectable } from '@angular/core';

// User-defined services
import {
  ISetCameraDetectorPositionOptions,
  ISetShadowRevicerPosition,
} from '@interfaces/artwork-shadows';

// User-defined services
import { UtilsService } from './utils.service';

// Third party plugins (CDN)
declare const BABYLON: any;

@Injectable({
  providedIn: 'root',
})
export class ArtworkShadowsService {
  constructor(
    private _utilsService: UtilsService,
  ) { }

  /**
   * * ============================================================================================== *
   *   SECTION Global Functions
   * * ============================================================================================== *
   **/
  //#region

	/**
	 * ANCHOR Create Spot Light
	 * @description to create spot light master
	 * @param scene : BABYLON.Scene
	 * @returns : BABYLON.SpotLight
	 */
  private _createSpotLight(scene: any): void {
    const light = new BABYLON.SpotLight('spotLightMaster', new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, 0), 1.3962634, 1, scene);
    light.intensity = 1;
    light.setEnabled(false);
    return light;
  }

  /**
	 * ANCHOR Get Spot Light Master
	 * @description to get spot light master
	 * @param scene : BABYLON.Scene
	 * @returns : BABYLON.SpotLight
	 */
  private _getSpotLightMaster(scene: any): any {
    const light = scene.getLightByName('spotLightMaster');
    if (light) return light;
    return this._createSpotLight(scene);
  }

  //#endregion
  //!SECTION

  /**
	 * * ============================================================================================== *
	 *   SECTION Shadow Cast Functions
	 * * ============================================================================================== *
   **/
  //#region


  /**
	 * ANCHOR Set Shadow Cast Metadata
	 * @description to set shadow cast metadata
	 * @param artworkNode : BABYLON.TransformNode
	 */
  public setShadowCastMetadata(artworkNode: any): void {
    const scene = artworkNode.getScene();
    const spotLightMaster = this._getSpotLightMaster(scene);
    const artworkId = artworkNode.metadata.artworkData.id;
    const defaultMetadata = { artworkId };

    const spotLight = spotLightMaster.clone('shadowCastLight');
    spotLight.id = 'shadowCastLight-' + artworkId;
    spotLight.metadata = { ...defaultMetadata };
    spotLight.setEnabled(true);
    spotLight.intensity = 0;

    const shadowReciver = this._createShadowReciver(scene);
    shadowReciver.id = 'shadowReciver-' + artworkId;
    shadowReciver.metadata = { ...defaultMetadata };
    spotLight.includedOnlyMeshes.push(shadowReciver);

    const shadowGenerator = this._createShadowGenerator(spotLight);
    shadowGenerator.id = 'shadowGenerator-' + artworkId;
    shadowGenerator.metadata = { ...defaultMetadata };

    artworkNode.metadata = {
      ...artworkNode.metadata,
      shadowCast: {
        spotLightId: spotLight.id,
        shadowReciverId: shadowReciver.id,
      },
    };
  }

  /**
	 * ANCHOR Set Shadow Cast Position
	 * @description to set shadow cast position
	 * @param artworkNode : BABYLON.TransformNode
	 */
  public setShadowCastPosition(artworkNode: any): void {
    this.frezeShadowCast(artworkNode, false);
    const scene = artworkNode.getScene();
    const dragAreaMeshes = this._utilsService.getDragAreaMeshes(scene);
    const options = { artworkNode, dragAreaMeshes };
    const isCloseToWall = this._setShadowRevicerPosition(options);
    const isUpright = this._isUpright(artworkNode);
    if (isCloseToWall && isUpright) {
      this.setEnableShadowCast(artworkNode, true);
      this._setSpotLightPosition(artworkNode);
    } else {
      this.setEnableShadowCast(artworkNode, false);
    }
    this.frezeShadowCast(artworkNode, true);
  }

  /**
	 * ANCHOR Hide Shadow Cast
	 * @description to hide shadow cast
	 * @param artworkNode : BABYLON.TransformNode
	 */
  public setEnableShadowCast(artworkNode: any, enable: boolean): void {
    const scene = artworkNode.getScene();
    const { spotLightId, shadowReciverId } = artworkNode.metadata.shadowCast;
    const spotLight = scene.getLightByID(spotLightId);
    const shadowReciver = scene.getMeshByID(shadowReciverId);

    spotLight.setEnabled(enable);
    shadowReciver.setEnabled(enable);
  }

  /**
	 * ANCHOR Freze Shadow Cast
	 * @description to freze shadow cast
	 * @param artworkNode : BABYLON.TransformNode
	 * @param freze : boolean -> true to freze shadow cast, false to unfreze shadow cast
	 */
  public frezeShadowCast(artworkNode: any, freze: boolean): void {
    const { spotLightId, shadowReciverId } = artworkNode.metadata.shadowCast;
    const shadowReciver = artworkNode.getScene().getMeshByID(shadowReciverId);
    const spotLight = artworkNode.getScene().getLightByID(spotLightId);
    const shadowGenerator = spotLight.getShadowGenerator();

    if (freze) {
      shadowReciver.freezeWorldMatrix();
      shadowReciver.material.freeze();
      shadowGenerator.getShadowMap().refreshRate = BABYLON.RenderTargetTexture.REFRESHRATE_RENDER_ONCE;
      spotLight.autoUpdateExtends = false;
    } else {
      shadowReciver.unfreezeWorldMatrix();
      shadowReciver.material.unfreeze();
      shadowGenerator.getShadowMap().refreshRate = BABYLON.RenderTargetTexture.REFRESHRATE_RENDER_ONEVERYFRAME;
      spotLight.autoUpdateExtends = true;
    }
  }

  /**
	 * ANCHOR Add Shadow Caster
	 * @description to add shadow caster
	 * @param artworkNode : BABYLON.TransformNode
	 */
  public addShadowCaster(artworkNode: any): void {
    const scene = artworkNode.getScene();
    const light = scene.getLightByID(artworkNode.metadata.shadowCast.spotLightId);
    const shadowGenerator = light.getShadowGenerator();
    artworkNode.getChildren().forEach((child: any) => {
      light.includedOnlyMeshes.push(child);
      shadowGenerator.getShadowMap().renderList.push(child);
    });
  }

  /**
	 * ANCHOR Is Upright
	 * @description to check if artwork is upright
	 * @param artworkNode : BABYLON.TransformNode
	 * @returns : boolean
	 */
  private _isUpright(artworkNode: any): boolean {
    const { shadowReciverId } = artworkNode.metadata.shadowCast;
    const shadowReciver = artworkNode.getScene().getMeshByID(shadowReciverId);
    const getRotate = (mesh: any, axis: any) => Number(mesh.rotation[axis].toFixed(3));
    return (
      getRotate(shadowReciver, 'x') == getRotate(artworkNode, 'x') &&
			getRotate(shadowReciver, 'y') == getRotate(artworkNode, 'y') &&
			getRotate(shadowReciver, 'z') == getRotate(artworkNode, 'z')
    );
  }

  /**
	 * ANCHOR Create Shadow Generator
	 * @description to create shadow generator
	 * @param scene : BABYLON.Scene
	 * @param light : BABYLON.DirectionalLight
	 * @returns : BABYLON.ShadowGenerator
	 */
  private _createShadowGenerator(light: any): any {
    const shadowGenerator = new BABYLON.ShadowGenerator(128, light);
    shadowGenerator.useBlurExponentialShadowMap = true;
    shadowGenerator.useKernelBlur = true;
    shadowGenerator.blurKernel = 7;
    shadowGenerator.bias = 0.02;
    return shadowGenerator;
  }

  /**
	 * ANCHOR Create Shadow Reciver
	 * @description to create shadow reciver (plane)
	 * @param scene : BABYLON.Scene
	 * @returns : BABYLON.Mesh
	 */
  private _createShadowReciver(scene: any): any {
    let shadowReciver = scene.getMeshByName('shadowReciver');
    if (shadowReciver) return shadowReciver.clone('shadowReciver');
    shadowReciver = BABYLON.MeshBuilder.CreatePlane('shadowReciver', {
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    }, scene);
    shadowReciver.material = new BABYLON.ShadowOnlyMaterial('shadowReciverMat', scene);
    shadowReciver.receiveShadows = true;
    return shadowReciver;
  }

  /**
	 * ANCHOR Set Shadow Revicer Position
	 * @description to set shadow revicer position
	 * @param options : ISetShadowRevicerPosition
	 * @returns : boolean -> true if shadow reciver position is set, false if not set (artwork is not close to wall)
	 */
  private _setShadowRevicerPosition(options: ISetShadowRevicerPosition): boolean {
    const { artworkNode, dragAreaMeshes } = options;
    const { shadowReciverId } = artworkNode.metadata.shadowCast;
    const shadowReciver = artworkNode.getScene().getMeshByID(shadowReciverId);
    const ray = this._getRaycastShadowCast(artworkNode);
    const hit = ray.intersectsMeshes(dragAreaMeshes)[0];
    if (!hit) {
      shadowReciver.setParent(null);
      return false;
    };
    shadowReciver.position = hit.pickedPoint.clone();
    shadowReciver.position.y -= shadowReciver.scaling.y*0.005;
    shadowReciver.setDirection(hit.getNormal(true, true));
    shadowReciver.rotation.x = this._utilsService.recalulateRotationNode(shadowReciver.rotation.x);
    shadowReciver.rotation.y = this._utilsService.recalulateRotationNode(shadowReciver.rotation.y);
    shadowReciver.rotation.z = this._utilsService.recalulateRotationNode(shadowReciver.rotation.z);
    shadowReciver.translate(new BABYLON.Vector3(0, 0, 0.005), 1, BABYLON.Space.LOCAL);

    shadowReciver.scaling.x = artworkNode.scaling.x*2;
    shadowReciver.scaling.y = artworkNode.scaling.y*2;
    shadowReciver.isPickable = false;

    return true;
  }

  /**
	 * ANCHOR Set Artwork Spot Light Position
	 * @description to set artwork spot light position
	 * @param artworkNode : BABYLON.TransformNode
	 * @param scene : BABYLON.Scene
	 */
  private _setSpotLightPosition(artworkNode: any): void {
    const { spotLightId, shadowReciverId } = artworkNode.metadata.shadowCast;
    const shadowReciver = artworkNode.getScene().getMeshByID(shadowReciverId);
    const spotLight = artworkNode.getScene().getLightByID(spotLightId);
    const sphere = BABYLON.MeshBuilder.CreateSphere('sphere', { diameter: 0.1 }, artworkNode.getScene());
    sphere.position = shadowReciver.position.clone();
    sphere.rotation = shadowReciver.rotation.clone();

    sphere.translate(new BABYLON.Vector3(0, 0, 5), 1, BABYLON.Space.LOCAL);
    spotLight.position = sphere.position.clone();
    spotLight.setDirectionToTarget(shadowReciver.position.clone());
    sphere.dispose();
  }

  /**
   * ANCHOR Get Raycast Shadow Cast
   * @description to get raycast shadow cast
   * @param artworkNode : BABYLON.TransformNode
   * @param scene : BABYLON.Scene
   * @returns : BABYLON.Ray
   */
  private _getRaycastShadowCast(artworkNode: any): any {
    const scene = artworkNode.getScene();
    const cameraDetector = scene.getCameraByID('cameraDetector');
    this._setCameraDetectorPosition({ cameraDetector, artworkNode, scene });
    cameraDetector.rotation = new BABYLON.Vector3(
        artworkNode.rotation.x,
        artworkNode.rotation.y + Math.PI,
        artworkNode.rotation.z,
    );
    const cameraRay = cameraDetector.getForwardRay(0.5);
    return cameraRay;
  }

  /**
   * ANCHOR Set Camera Detector Position
   * @description to set camera detector position same as image object(mesh) in artwork node
   * @param options : ISetCameraDetectorPositionOptions
   */
  private _setCameraDetectorPosition(options: ISetCameraDetectorPositionOptions): void {
    const { artworkNode, cameraDetector, scene } = options;
    const marker = BABYLON.MeshBuilder.CreateSphere('sphere', { diameter: 0.2 }, scene);
    marker.position = artworkNode.position.clone();
    marker.rotation = artworkNode.rotation.clone();
    marker.translate(
        new BABYLON.Vector3(0, 0, -(artworkNode.scaling.z/2-0.01)),
        1/marker.scaling.z,
        BABYLON.Space.LOCAL,
    );
    cameraDetector.position = marker.position;
    marker.dispose();
  }

  //#endregion
  //!SECTION

  /**
	 * * ============================================================================================== *
	 *   SECTION Shadow Componets Functions
	 * * ============================================================================================== *
	 */
  //#region

  /**
	 * ANCHOR Create Shadow Component Lights
	 * @description to create shadow component lights
	 * @param lightMaster : BABYLON.SpotLight
	 * @param artworkNode : BABYLON.TransformNode
	 * @returns : BABYLON.SpotLight[]
	 */
  public createShadowComponentLights(artworkNode: any) : any[] {
    const lightMaster = this._getSpotLightMaster(artworkNode.getScene());
    const positions = [ 'top-left' ];
    const lights: any[] = [];
    const artworkData = artworkNode.metadata.artworkData;
    positions.forEach((position) => {
      const light = lightMaster.clone('shadowComponentLight');
      light.metadata = { artworkData, position };
      light.includedOnlyMeshes = artworkNode.getChildren();
      light.id = `shadowComponentLight-${position}-${artworkData.id}`;
      light.intensity = 2;
      light.setEnabled(true);
      lights.push(light);
    });
    return lights;
  }

  /**
	 * ANCHOR Set Shadow Component Lights Position
	 * @description to set shadow component lights position based on artwork node position
	 * @param artworkNode : BABYLON.TransformNode
	 */
  public setShadowComponentLightsPosition(artworkNode: any): void {
    const scene = artworkNode.getScene();
    const { shadowComponent } = artworkNode.metadata;
    const marker = BABYLON.MeshBuilder.CreateBox('marker', { size: 0.1 }, scene);
    const markerParent = BABYLON.MeshBuilder.CreateBox('markerParent', { size: 0.1 }, scene);
    marker.setParent(markerParent);
    markerParent.position = artworkNode.position.clone();
    markerParent.rotation = artworkNode.rotation.clone();

    shadowComponent.lightIds.forEach((lightId: any) => {
      const light = scene.getLightByID(lightId);
      const position = light.metadata.position;
      marker.position.z = artworkNode.scaling.z * 3;
      if (position.includes('top')) marker.position.y = artworkNode.scaling.y;
      if (position.includes('bottom')) marker.position.y = -artworkNode.scaling.y;
      if (position.includes('left')) marker.position.x = artworkNode.scaling.x;
      if (position.includes('right')) marker.position.x = -artworkNode.scaling.x;
      if (position.includes('middleV')) marker.position.y = 0;
      if (position.includes('middleH')) marker.position.x = 0;

      marker.setParent(null);
      light.position = marker.position.clone();
      marker.setParent(markerParent);
      light.setEnabled(true);
      light.includedOnlyMeshes = artworkNode.getChildren();
      light.setDirectionToTarget(markerParent.position.clone());
    });

    markerParent.dispose();
  }

  /**
	 * ANCHOR Set Visibility of Shadow Components
	 * @description to set visibility of shadow components
	 */
  public setEnableShadowComponents(artworkNode: any, enable: boolean): void {
    const { lightIds } = artworkNode.metadata.shadowComponent;
    lightIds.forEach((lightId: any) => {
      const light = artworkNode.getScene().getLightByID(lightId);
      light.setEnabled(enable);
    });
  }

  //#endregion
  //!SECTION
}
