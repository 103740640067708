// Angular Imports
import { Injectable } from '@angular/core';

// User Defined Imports
import { TextWall } from '@interfaces/text-wall';
import { UtilsService } from './utils.service';

// Third Party Plugins (CDN)
declare const BABYLON: any;

@Injectable({
  providedIn: 'root',
})
export class TextLoaderService {
  constructor(private _utilsService: UtilsService) { }

  /**
   * ANCHOR Load Text As Image
   * @param text : TextWall
   * @param scene : BABYLON.Scene
   * @returns : Promise<BABYLON.TransformNode>
   */
  public async loadTextAsImage(text: TextWall, scene: any): Promise<any> {
    const material = await this._createTextMaterial(text.picture_initial_quality, scene);
    const textObject = this._createTextContentWrapper(text, scene);
    textObject.material = material;
    const wrapText = this._createTextObjectWrapper(text, textObject);

    setTimeout(async () => {
      this.changeQuality(text, scene);
    }, 3000)
    return wrapText;
  }

  /**
   * ANCHOR Change Quality
   * @description Change quality of the text
   * @param text : TextWall
   * @param scene : BABYLON.Scene
   * @param quality : 'very_low' | 'low' | 'medium' | 'high'
   */
  public async changeQuality(text: TextWall, scene: any, quality?: 'very_low' | 'low' | 'medium' | 'high'): Promise<any> {
    const textNode = scene.getTransformNodeById('textWall-'+text.id);
    const textObject = textNode.getChildMeshes()[0];
    let textureSource;
    switch (quality) {
      case 'very_low': textureSource = text.picture_very_low_quality; break;
      case 'low': textureSource = text.picture_low_quality; break;
      case 'medium': textureSource = text.picture_medium_quality; break;
      case 'high': textureSource = text.picture_high_quality; break;
      default: textureSource = text.picture; break;
    }
    const textTexture = await this._utilsService.loadTexture(textureSource, scene);
    textObject.material.emissiveTexture.dispose();
    textObject.material.emissiveTexture = textTexture;
    textObject.material.opacityTexture = textTexture;
  }

  /**
   * ANCHOR Replace When Camera Is Close
   * @description Replace when camera is close
   * @param text : TextWall
   * @param textObject : BABYLON.TransformNode
   */
  private _replaceWhenCameraIsClose(text: TextWall, textObject: any): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        textObject.addLODLevel();
        textObject.onLODLevelSelection = async (distance: number) => {
          if(distance < 10) {
            textObject.removeLODLevel();
            await this.changeQuality(text, textObject.getScene());
            resolve();
          }
        }
      } catch (error) {
        reject(error);
      }
    })
  }

  /**
   * ANCHOR Create Text Material
   * @description Create text material
   * @param url : string -> url of the text image
   * @param scene : BABYLON.Scene
   * @returns : BABYLON.StandardMaterial
   */
  private async _createTextMaterial(url: any, scene: any): Promise<any> {
    const textMaterial = new BABYLON.StandardMaterial('textMat', scene);
    const textTexture = await this._utilsService.loadTexture(url, scene);
    textMaterial.emissiveTexture = textTexture;
    textMaterial.opacityTexture = textTexture;
    textMaterial.emissiveTexture.hasAlpha = true;
    textMaterial.useAlphaFromDiffuseTexture = true;
    textMaterial.disableLighting = true;
    textMaterial.backFaceCulling = false;
    return textMaterial;
  }

  /**
   * ANCHOR Create Text Object Wrapper
   * @description Create text object wrapper
   * @param text : TextWall
   * @param textObject : BABYLON.TransformNode
   * @returns : BABYLON.TransformNode
   */
  private _createTextContentWrapper(text: any, scene: any) {
    const textObject = BABYLON.MeshBuilder.CreatePlane('textWall', {}, scene);
    textObject.id = text.id;
    textObject.isPickable = false;
    textObject.rotation.y = Math.PI;
    textObject.scaling = new BABYLON.Vector3(text.scaling.scaling_x, text.scaling.scaling_y, 1);
    textObject.position.z = 0.001;
    return textObject;
  }

  /**
   * ANCHOR Create text object wrapper
   * @description Create text object wrapper
   * @param text : TextWall
   * @param textObject : BABYLON.Mesh
   * @returns : BABYLON.TransformNode
   */
  private _createTextObjectWrapper(text: any, textObject: any) {
    const wrapText:any = new BABYLON.TransformNode('textWall', textObject.getScene());
    wrapText.id = 'textWall-'+text.id;
    wrapText.scaling = new BABYLON.Vector3(text.scaling.scaling_x, text.scaling.scaling_y, 1);
    textObject.setParent(wrapText);
    wrapText.position = new BABYLON.Vector3(text.position.position_x, text.position.position_y, text.position.position_z);
    wrapText.rotation = new BABYLON.Vector3(text.rotation.rotation_x, text.rotation.rotation_y, text.rotation.rotation_z);
    return wrapText;
  }
  
}
