import { mergeApplicationConfig, ApplicationConfig } from '@angular/core';
import { provideServerRendering } from '@angular/platform-server';
import { appConfig } from './app.config';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { DataApiInterceptor } from './shared/http-interceptor/data-api.interceptor';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';

const serverConfig: ApplicationConfig = {
  providers: [
    provideServerRendering(),
    provideClientHydration(withHttpTransferCacheOptions({
      includeRequestsWithAuthHeaders: true
    })),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    {
			provide: 'req',
			useValue: null,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DataApiInterceptor,
			multi: true,
		}
  ]
};

export const config = mergeApplicationConfig(appConfig, serverConfig);
