import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { IMessageConfig } from '@interfaces/message-config';
import { MessageService } from './message.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    animations: [
        trigger('fadeAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(200px)',
                }),
                animate(200, style({
                    opacity: 1,
                    transform: 'translateY(0)',
                })),
            ]),
            transition(':leave', [
                style({
                    opacity: 1,
                    transform: 'translateY(0)',
                }),
                animate(200, style({
                    opacity: 0,
                    transform: 'translateY(200px)',
                })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [NgIf],
})
export class MessageComponent {
  public showMessage: boolean = false;
  public title: string = '';
  public type: string = '';
  public message: string = '';

  constructor(private messageService: MessageService) {
    this.messageService.componentMethodCalled$.subscribe((config: IMessageConfig) => {
      this.title = config.title;
      this.message = config.detailMessage;
      this.type = config.type;

      if (this.showMessage) {
        this.showMessage = false;
      }
      setTimeout(() => {
        this.showMessage = true;
      }, 100);
      setTimeout(() => {
        this.showMessage = false;
      }, 3000);
    });
  }
}
