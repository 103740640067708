// Angular modules/components
import { Component } from '@angular/core';
// Environment
import { environment } from '@environments';
// User-defined services
import { MainService } from '@services/main.service';
import { TtsService } from '@services/tts.service';
import { debounce } from 'lodash';
// Third party plugins NPM
import moment from 'moment';
import { MessageService } from 'src/app/components/message/message.service';
import { ElasticsearchService } from 'src/app/shared/services/elasticsearch.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-about-exhibition',
    templateUrl: './about-exhibition.component.html',
    styleUrls: ['./about-exhibition.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class AboutExhibitionComponent {
  public moment = moment;
  public shareExhibition = false;
  public baseHost = environment.baseHost;
  public env = environment;

  constructor(
    public mainService: MainService,
    private messageService: MessageService,
    private elasticsearchService: ElasticsearchService,
    public ttsService: TtsService,
  ) {}

  /**
   * * COPY SHAREABLE LINK OF EXHIBITION *
   * Todo: to copy shareable link of exhibition
   */
  copyText() {
    const copyText:any = document.getElementById('shareableLink');
    if (this.mainService.isIOS) {
      const oldContentEditable = copyText.contentEditable;
      const oldReadOnly = copyText.readOnly;
      const range = document.createRange();

      copyText.contentEditable = true;
      copyText.readOnly = false;
      range.selectNodeContents(copyText);

      const s:any = window.getSelection();
      s.removeAllRanges();
      s.addRange(range);

      copyText.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

      copyText.contentEditable = oldContentEditable;
      copyText.readOnly = oldReadOnly;

      document.execCommand('copy');
    } else {
      copyText.removeAttribute('readonly');
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.value);
      copyText.blur();
      copyText.setAttribute('readonly', true);
    }

    this.sendLog();
    this.messageService.add({
      type: 'success',
      title: 'Success',
      detailMessage: 'Share link has been copied!',
    });
  }

  public sendLog = debounce(() => {
    if (this.mainService.isBrowser) {
      this.elasticsearchService.sendToElasticsearch('share_exhibition');
    }
  }, 2000);

  public shareGallery():void {
    this.shareExhibition=!this.shareExhibition;
    const element = document.querySelector('.wrap-about-exhibition') as HTMLElement;

    setTimeout(() => {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  }

  public shareLink():string {
    if (this.mainService.whiteLabel) {
      const domain = window.location.origin;
      return `${domain}/v/${this.mainService.exhibitionString}`;
    } else {
      return `${this.baseHost}/${this.mainService.exhibitionString}`;
    }
  }
}
