import { Routes } from '@angular/router';
import { VillumeGalleryComponent } from './villume-gallery/villume-gallery.component';
import { NotFoundComponent } from './components/not-found/not-found.component';


export const routes: Routes = [
  { path: '', component: VillumeGalleryComponent },
  { path: '404', component: NotFoundComponent },
  { path: ':share_string', component: VillumeGalleryComponent },
];
