export const darkTheme = {
  'bg-1': 'rgba(23, 23, 23, 0.7)',
  'bg-2': 'rgba(37, 35, 34, 0.95)',
  'bg-3': '#313131',
  'bg-4': '#393939',
  'bg-5': '#171717',
  'text-1': '#fFFFFF',
  'text-2': '#979797',
  'text-3': '#E7E7E7',
  'text-4': '#E7E7E7',
  'text-5': '#979797',
  'text-alternate-1': '#FFDA16',
  'text-alternate-2': '#FFDA16',
  'text-alternate-3': '#E7E7E7',
  'bg-alternate-1': '#979797',
  'bg-alternate-2': '#FFDA16',
  'bg-alternate-3': '#171717',
  'bg-alternate-4': '#979797',
  'icon-color-1': '#FFFFFF',
  'icon-color-2': '#FFDA16',
  'icon-color-3': '#e7e7e7',
  'border-color-1': '#FFDA16',
};

export const lightTheme = {
  'bg-1': 'rgba(251, 251, 251, 0.7)',
  'bg-2': 'rgba(251, 251, 251, 0.95)',
  'bg-3': '#D3D3D3',
  'bg-4': '#E7E7E7',
  'bg-5': '#A8A8A8',
  'text-1': '#000000',
  'text-2': '#565656',
  'text-3': '#292929',
  'text-4': '#171717',
  'text-5': '#171717',
  'text-alternate-1': '#171717',
  'text-alternate-2': '#000000',
  'text-alternate-3': '#313131',
  'bg-alternate-1': '#565656',
  'bg-alternate-2': '#979797',
  'bg-alternate-3': '#9F9F9F',
  'bg-alternate-4': '#D3D3D3',
  'icon-color-1': '#000000',
  'icon-color-2': '#565656',
  'icon-color-3': '#292929',
  'border-color-1': '#292929',
};
