<div class="wrap-about-exhibition">
    <button 
        (click)="mainService.openSideContent=false" 
        class="btn btn-close">
        <i class="vi vi-x"></i>
    </button>
    <div class="wrap-header">
        <h5 class="title">{{mainService.exhibition.name}}</h5>

        <div class="wrap-user">
            <span>by</span>
            <img 
                [src]="mainService.userInfo.avatar" 
                alt="" 
                class="img-avatar">
            <a 
                target="_BLANK"
                [href]="mainService.villumeAdminUrl + '/' + mainService.userInfo.username">
                {{mainService.userInfo.first_name+" "+ mainService.userInfo.last_name}}
            </a> 
        </div>
    </div>

    <div class="content">
        <div *ngIf="ttsService.ttsExisted">
            <button class="speech btn" (click)="ttsService.toggleTextToSpeech()">
                <i class="vi vi-speech" [class.off]="!ttsService.ttsSpeaking"></i>
            </button>
        </div>
        <div class="desc">
            {{mainService.exhibition.description}}
        </div>

        <div class="wrap-date" *ngIf="!mainService.exhibition.unlimited_time">
            <span>{{moment(mainService.exhibition.started).format("DD.MM.YYYY")}} </span>
            <img src="{{env.staticAssets}}images/other/arrow-lenght.svg?t={{mainService.appVersion}}" alt="" class="arrow-lenght">
            <span>{{moment(mainService.exhibition.ended).format("DD.MM.YYYY")}}</span>
        </div>

        <div class="wrap-share">
            <ul>
                <li class="item-share" *ngIf="mainService.exhibition.exhibition_link?.website_url">
                    <a 
                        [href]="mainService.exhibition.exhibition_link?.website_url"
                        target="_blank"
                        class="link-share p-0">
                        <i class="vi vi-global"></i>
                    </a>
                </li>
                <li class="item-share" *ngIf="mainService.exhibition.exhibition_link?.instagram_url">
                    <a 
                        [href]="'https://www.instagram.com/' + mainService.exhibition.exhibition_link?.instagram_url"
                        target="_blank"
                        class="link-share">
                        <i class="vi vi-instagram"></i>
                    </a>
                </li>
                <li class="item-share" *ngIf="mainService.exhibition.exhibition_link?.facebook_url">
                    <a 
                        [href]="'https://www.facebook.com/' + mainService.exhibition.exhibition_link?.facebook_url"
                        target="_blank"
                        class="link-share">
                        <i class="vi vi-facebook"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="widget-menu">
        <ul>
            <li class="item-menu" *ngIf="mainService.artworks.length">
                <button class="action-menu" (click)="mainService.sideContent = 'list-artwork'">
                    List of Artworks 
                </button>
            </li>
            <ng-container *ngIf="!mainService.whiteLabel">
                <li class="item-menu" *ngIf="mainService.otherExhibitions.length">
                    <button class="action-menu" (click)="mainService.sideContent = 'more-from-creator'">
                        More From This Creator
                    </button>
                </li>
            </ng-container>
            <li class="item-menu">
                <button class="action-menu" (click)="shareGallery()">
                    Share the Exhibition
                    <i class="vi vi-share-to"></i>
                </button>
            </li>
        </ul>
    </div>
    <div class="form-share" *ngIf="shareExhibition">
        <label for="link share">Shareable Link:</label>
        <div class="wrap-input-link">
            <input id="shareableLink" readonly (copy)="sendLog()" [value]="shareLink()" type="text" class="form-control">
            <button (click)="copyText()" class="btn-action">Copy</button>
        </div>
    </div>
</div>