import { Component, OnInit } from '@angular/core';
import { environment } from '@environments';
import { MainService } from '@services/main.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class HelpComponent implements OnInit {
  public isMobile: boolean = false;
  public env = environment;

  constructor(public mainService: MainService) {}

  ngOnInit(): void {
    if (this.mainService.isBrowser) {
      if (window.innerWidth < 600) this.isMobile = true;
      else this.isMobile = false;
      window.addEventListener('resize', () => {
        if (window.innerWidth < 600) this.isMobile = true;
        else this.isMobile = false;
      });
    }
  }
}
